import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import AsyncButton from 'components/AsyncButton';

const initialValues = {
  discount: '',
};
const CouponForm = ({ handleCheck, handleSubmit }) => {
  const promoCode = useSelector(({ form }) => form.CouponForm.values.discount);

  return (
    <form>
      <div>
        <InputField
          label="Discount Coupon"
          name="discount"
          placeholder="Enter Your Discount Coupon"
          className=""
        />
        <AsyncButton
          className="btn btn-primary"
          onClick={handleCheck(promoCode)}
          type="button"
          text="Confirm Coupon"
        />
      </div>
    </form>
  );
};

CouponForm.propTypes = {
  handleSubmit: PropTypes.func,
  handleCheck: PropTypes.func,
};

export default reduxForm({
  form: 'CouponForm',
  destroyOnUnmount: false,
  enableReinitialize: true,
  initialValues,
})(CouponForm);
