import AsyncButton from 'components/AsyncButton';
import DisplayData from 'components/DisplayData/DisplayData';
import PropTypes from 'prop-types';
import React from 'react';
import ReactStars from 'react-rating-stars-component';

const MealCard = ({ meal, isMenuCard, onClick }) => {
  return (
    <div className="card card-frame mb-3">
      <div
        className="img-fluid "
        style={{
          backgroundImage: `url(${meal.image})`,
          minHeight: '250px',
          backgroundSize: 'cover',
        }}
        alt={`${meal.title.en}`}
      />
      <div className="card-body">
        <h5 className="card-title">{meal.title.en}</h5>
        {meal.ratings ? (
          <ReactStars
            count={5}
            value={meal.ratings}
            size={30}
            color2="#fbaf02"
            edit={false}
          />
        ) : (
          <h6 className="card-title text-primary">Not Yet Rated</h6>
        )}
        <div className="card-text">
          <div className="row">
            <div className="col-6">
              <DisplayData title="Carbs" value={meal.carbs} />
            </div>
            <div className="col-6">
              <DisplayData title="Fat" value={meal.fat} />
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <DisplayData title="Calories" value={meal.cal} />
            </div>
            <div className="col-6">
              <DisplayData title="Proteins" value={meal.proteins} />
            </div>
          </div>
          {isMenuCard && (
            <AsyncButton onClick={onClick} type="button" text={isMenuCard} />
          )}
        </div>
      </div>
    </div>
  );
};

MealCard.propTypes = {
  meal: PropTypes.shape({
    id: PropTypes.string,
    cal: PropTypes.number,
    carbs: PropTypes.number,
    fat: PropTypes.number,
    proteins: PropTypes.number,
    defaultIngredients: PropTypes.arrayOf(
      PropTypes.shape({
        ar: PropTypes.string,
        en: PropTypes.string,
      }),
    ),
    image: PropTypes.string,
    title: PropTypes.shape({
      en: PropTypes.string,
      ar: PropTypes.string,
    }),
    type: PropTypes.string,
    suppliment: PropTypes.arrayOf(
      PropTypes.shape({
        en: PropTypes.string,
        ar: PropTypes.string,
        price: PropTypes.number,
      }),
    ),
    ratings: PropTypes.number,
  }),
  isMenuCard: PropTypes.string,
  onClick: PropTypes.func,
};

export default MealCard;
