/* eslint-disable max-lines */
import { strings } from 'constants/strings';

export const rice = [
  {
    label: strings.en.buildYourMeal.carbohydrtes.rice.kabsa,
    value: { type: 'kabsa_rice', price: 3 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.rice.sticky,
    value: { type: 'sticky_rice', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.rice.brown,
    value: { type: 'brown_rice', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.rice.majoobs,
    value: { type: 'majoobs_rice', price: 3 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.rice.birivani,
    value: { type: 'birivani_rice', price: 3 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.rice.frikeh,
    value: { type: 'frikeh_rice', price: 3 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.rice.quinoa,
    value: { type: 'quinoa_rice', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.rice.couscous,
    value: { type: 'couscous_rice', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.rice.plain,
    value: { type: 'plain_rice', price: 2 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.rice.lemon,
    value: { type: 'lemon_rice', price: 5 },
  },
];

export const potato = [
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.mash,
    value: { type: 'mash_potato', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.cream,
    value: { type: 'cream_potato', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.sweetCorn,
    value: { type: 'sweetCorn_potato', price: 7 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.grill,
    value: { type: 'grill_potato', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.gratin,
    value: { type: 'gratin_potato', price: 7 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.pineNut,
    value: { type: 'pineNut_potato', price: 7 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.wholeGrain,
    value: { type: 'wholeGrain_potato', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.roast,
    value: { type: 'roast_potato', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.baby,
    value: { type: 'baby_potato', price: 7 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.lemon,
    value: { type: 'lemon_potato', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.grilSweet,
    value: { type: 'grilSweet_potato', price: 7 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.sweet,
    value: { type: 'sweet_potato', price: 7 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.tikka,
    value: { type: 'tikka_potato', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.potato.new,
    value: { type: 'new_potato', price: 7 },
  },
];

export const pasta = [
  {
    label: strings.en.buildYourMeal.carbohydrtes.pasta.wholePenne,
    value: { type: 'wholePenne_pasta', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pasta.wholeSpag,
    value: { type: 'wholeSpag_pasta', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pasta.penne,
    value: { type: 'penne_pasta', price: 4 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pasta.spag,
    value: { type: 'spag_pasta', price: 4 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pasta.farfalle,
    value: { type: 'farfalle_pasta', price: 4 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pasta.fettuccine,
    value: { type: 'fettuccine_pasta', price: 4 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pasta.Fusilli,
    value: { type: 'fusilli_pasta', price: 4 },
  },
];

export const sauce = [
  {
    label: strings.en.buildYourMeal.carbohydrtes.pastaSauce.tomato,
    value: { type: 'tomato_sauce', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pastaSauce.creamMushroom,
    value: { type: 'creamMushroom_sauce', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pastaSauce.pink,
    value: { type: 'pink_sauce', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pastaSauce.pesto,
    value: { type: 'pesto_sauce', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pastaSauce.creamCheddar,
    value: { type: 'creamCheddar_sauce', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pastaSauce.bechamel,
    value: { type: 'bechamel_sauce', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pastaSauce.bolognaise,
    value: { type: 'bolognaise_sauce', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pastaSauce.carbonara,
    value: { type: 'carbonara_sauce', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.carbohydrtes.pastaSauce.creamChlantro,
    value: { type: 'creamChlantro_sauce', price: 5 },
  },
];

export const chicken = [
  {
    label: strings.en.buildYourMeal.protein.chicken.jerk,
    value: { type: 'jerk_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.tandoori,
    value: { type: 'tandoori_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.limeOrLemon,
    value: { type: 'limeOrLemon_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.almond,
    value: { type: 'almond_chicken', price: 7 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.orange,
    value: { type: 'orange_chicken', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.curry,
    value: { type: 'curry_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.tikka,
    value: { type: 'tikka_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.honey,
    value: { type: 'honey_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.lemonOregano,
    value: { type: 'lemonOregano_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.kabab,
    value: { type: 'kabab_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.buffalo,
    value: { type: 'buffalo_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.butter,
    value: { type: 'butter_chicken', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.spinach,
    value: { type: 'spinach_chicken', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.biriyani,
    value: { type: 'biriyani_chicken', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.lemon,
    value: { type: 'lemon_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.stew,
    value: { type: 'stew_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.teriyaki,
    value: { type: 'teriyaki_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.mushroom,
    value: { type: 'mushroom_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.marinara,
    value: { type: 'marinara_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.kabasa,
    value: { type: 'kabasa_chicken', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.chicken.majoran,
    value: { type: 'majoran_chicken', price: 5 },
  },
];

export const fish = [
  {
    label: strings.en.buildYourMeal.protein.whiteFish.spicy,
    value: { type: 'spicy_fish', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.whiteFish.kabasa,
    value: { type: 'kabasa_fish', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.whiteFish.rosemary,
    value: { type: 'rosemary_fish', price: 5 },
  },
];

export const salmon = [
  {
    label: strings.en.buildYourMeal.protein.salmon.lemon,
    value: { type: 'lemon_salmon', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.protein.salmon.honey,
    value: { type: 'honey_salmon', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.protein.salmon.rosemary,
    value: { type: 'rosemary_salmon', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.protein.salmon.pesto,
    value: { type: 'pesto_salmon', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.protein.salmon.nuts,
    value: { type: 'nuts_salmon', price: 8 },
  },
];

export const beef = [
  {
    label: strings.en.buildYourMeal.protein.beef.meatBowl,
    value: { type: 'meatBowl_beef', price: 10 },
  },
  {
    label: strings.en.buildYourMeal.protein.beef.mangolion,
    value: { type: 'mangolion_beef', price: 10 },
  },
  {
    label: strings.en.buildYourMeal.protein.beef.bolognaise,
    value: { type: 'bolognaise_beef', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.protein.beef.korean,
    value: { type: 'korean_beef', price: 10 },
  },
  {
    label: strings.en.buildYourMeal.protein.beef.stew,
    value: { type: 'stew_beef', price: 10 },
  },
  {
    label: strings.en.buildYourMeal.protein.beef.steak,
    value: { type: 'steak_beef', price: 10 },
  },
];

export const salad = [
  {
    label: strings.en.buildYourMeal.sides.salad.mix,
    value: { type: 'mix_salad', price: 4 },
  },
  {
    label: strings.en.buildYourMeal.sides.salad.turkey,
    value: { type: 'turkey_salad', price: 6 },
  },
  {
    label: strings.en.buildYourMeal.sides.salad.walnut,
    value: { type: 'walnut_salad', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.sides.salad.wild,
    value: { type: 'wild_salad', price: 8 },
  },
  {
    label: strings.en.buildYourMeal.sides.salad.yoghurt,
    value: { type: 'yoghurt_salad', price: 4 },
  },
  {
    label: strings.en.buildYourMeal.sides.salad.asparagus,
    value: { type: 'asparagus_salad', price: 6 },
  },
];

export const vegetable = [
  {
    label: strings.en.buildYourMeal.sides.vegetable.steam,
    value: { type: 'steam_vegetable', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.sides.vegetable.grill,
    value: { type: 'grill_vegetable', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.sides.vegetable.fresh,
    value: { type: 'fresh_vegetable', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.sides.vegetable.stew,
    value: { type: 'stew_vegetable', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.sides.vegetable.sautVeg,
    value: { type: 'sautVeg_vegetable', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.sides.vegetable.sautMush,
    value: { type: 'sautMush_vegetable', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.sides.vegetable.sautSpin,
    value: { type: 'sautSpin_vegetable', price: 5 },
  },
  {
    label: strings.en.buildYourMeal.sides.vegetable.sautKale,
    value: { type: 'sautKale_vegetable', price: 5 },
  },
];

export const BfVegetables = [
  {
    label: strings.BreakfastVegetables?.spinach,
    value: {
      type: 'spinach',
      price: '4',
    },
  },
  {
    label: strings.BreakfastVegetables?.mushroom,
    value: {
      type: 'mushroom',
      price: '4',
    },
  },
  {
    label: strings.BreakfastVegetables?.broccoli,
    value: {
      type: 'broccoli',
      price: '4',
    },
  },
];

export const none = [
  {
    label: strings.none,
    value: {
      type: 'None',
      price: 0,
    },
  },
];

export const eggs = [
  {
    label: strings.buildYourBreakFast?.protein.eggs.whiteEggs,
    value: { type: 'white_eggs', price: 2 },
  },
  {
    label: strings.buildYourBreakFast?.protein.eggs.fullEggs,
    value: { type: 'full_eggs', price: 2 },
  },
];

export const breakFastProteins = [
  {
    label: strings.none,
    value: none,
  },
  {
    label: strings.buildYourBreakFastSectionTitles?.eggs,
    value: eggs,
  },
  {
    label: strings.buildYourMealSectionTitles.beef,
    value: beef,
  },
  {
    label: strings.buildYourBreakFastSectionTitles?.turkeyLite,
    value: [
      {
        label: strings.buildYourBreakFastSectionTitles?.turkeyLite,
        value: { type: 'turkey_lite', price: 2 },
      },
    ],
  },
];
export const breakFastcarbs = [
  {
    label: strings.none,
    value: none,
  },
  {
    label: strings.buildYourBreakFastSectionTitles?.Oats,
    value: [
      {
        label: strings.buildYourBreakFastSectionTitles?.Oats,
        value: {
          type: 'Oats',
          price: 5,
        },
      },
    ],
  },
  {
    label: strings.buildYourMealSectionTitles.potato,
    value: [
      {
        label: strings.buildYourMealSectionTitles.potato,
        value: {
          type: 'potato',
          price: 5,
        },
      },
    ],
  },
  {
    label: strings.buildYourBreakFastSectionTitles?.sweet_potato,
    value: [
      {
        label: strings.buildYourBreakFastSectionTitles?.sweet_potato,
        value: {
          type: 'sweet-potato',
          price: 5,
        },
      },
    ],
  },
  {
    label: strings.buildYourBreakFastSectionTitles?.sliteBread,
    value: [
      {
        label: strings.buildYourBreakFastSectionTitles?.sliteBread,
        value: {
          type: 'slite-bread',
          price: 5,
        },
      },
    ],
  },
];

export const breakFastVegetables = [
  {
    label: strings.none,
    value: none,
  },
  {
    label: strings.buildYourBreakFastSectionTitles?.vegetable,
    value: BfVegetables,
  },
];

export const carbs = [
  {
    label: strings.en.buildYourMealSectionTitles.rice,
    value: rice,
  },
  {
    label: strings.en.buildYourMealSectionTitles.potato,
    value: potato,
  },
  {
    label: strings.en.buildYourMealSectionTitles.pasta,
    value: pasta,
  },
  {
    label: strings.en.buildYourMealSectionTitles.sauce,
    value: sauce,
  },
];
export const proteins = [
  {
    label: strings.en.buildYourMealSectionTitles.chicken,
    value: chicken,
  },
  {
    label: strings.en.buildYourMealSectionTitles.fish,
    value: fish,
  },
  {
    label: strings.en.buildYourMealSectionTitles.salmon,
    value: salmon,
  },
  {
    label: strings.en.buildYourMealSectionTitles.beef,
    value: beef,
  },
];

export const sides = [
  {
    label: strings.en.buildYourMealSectionTitles.salad,
    value: salad,
  },
  {
    label: strings.en.buildYourMealSectionTitles.vegetable,
    value: vegetable,
  },
];

export const breadPcsNumber: SelectorOption[] = [
  {
    label: `1 ${strings.pcs}`,
    value: 1,
  },
  {
    label: `2 ${strings.pcs}`,
    value: 2,
  },
  {
    label: `3 ${strings.pcs}`,
    value: 3,
  },
  {
    label: `4 ${strings.pcs}`,
    value: 4,
  },
  {
    label: `5 ${strings.pcs}`,
    value: 5,
  },
  {
    label: `6 ${strings.pcs}`,
    value: 6,
  },
  {
    label: `7 ${strings.pcs}`,
    value: 7,
  },
  {
    label: `8 ${strings.pcs}`,
    value: 8,
  },
  {
    label: `9 ${strings.pcs}`,
    value: 9,
  },
  {
    label: `10 ${strings.pcs}`,
    value: 10,
  },
];
export const eggsPieceNumberExtra: SelectorOption[] = [
  {
    label: `0 ${strings.pcs}`,
    value: 0,
  },

  {
    label: `1 ${strings.pcs}`,
    value: 1,
  },
  {
    label: `2 ${strings.pcs}`,
    value: 2,
  },
  {
    label: `3 ${strings.pcs}`,
    value: 3,
  },
  {
    label: `4 ${strings.pcs}`,
    value: 4,
  },
  {
    label: `5 ${strings.pcs}`,
    value: 5,
  },
  {
    label: `6 ${strings.pcs}`,
    value: 6,
  },
  {
    label: `7 ${strings.pcs}`,
    value: 7,
  },
  {
    label: `8 ${strings.pcs}`,
    value: 8,
  },
  {
    label: `9 ${strings.pcs}`,
    value: 9,
  },
  {
    label: `10 ${strings.pcs}`,
    value: 10,
  },
  {
    label: `11 ${strings.pcs}`,
    value: 11,
  },
  {
    label: `12 ${strings.pcs}`,
    value: 12,
  },
  {
    label: `13 ${strings.pcs}`,
    value: 13,
  },
  {
    label: `14 ${strings.pcs}`,
    value: 14,
  },
  {
    label: `15 ${strings.pcs}`,
    value: 15,
  },
];

export const eggsPieceNumber: SelectorOption[] = [
  {
    label: `1 ${strings.pcs}`,
    value: 1,
  },
  {
    label: `2 ${strings.pcs}`,
    value: 2,
  },
  {
    label: `3 ${strings.pcs}`,
    value: 3,
  },
  {
    label: `4 ${strings.pcs}`,
    value: 4,
  },
  {
    label: `5 ${strings.pcs}`,
    value: 5,
  },
  {
    label: `6 ${strings.pcs}`,
    value: 6,
  },
  {
    label: `7 ${strings.pcs}`,
    value: 7,
  },
  {
    label: `8 ${strings.pcs}`,
    value: 8,
  },
  {
    label: `9 ${strings.pcs}`,
    value: 9,
  },
  {
    label: `10 ${strings.pcs}`,
    value: 10,
  },
  {
    label: `11 ${strings.pcs}`,
    value: 11,
  },
  {
    label: `12 ${strings.pcs}`,
    value: 12,
  },
  {
    label: `13 ${strings.pcs}`,
    value: 13,
  },
  {
    label: `14 ${strings.pcs}`,
    value: 14,
  },
  {
    label: `15 ${strings.pcs}`,
    value: 15,
  },
];

export const turkeyPieceNumber = [
  {
    label: `1 ${strings.pcs}`,
    value: 1,
  },
  {
    label: `2 ${strings.pcs}`,
    value: 2,
  },
  {
    label: `3 ${strings.pcs}`,
    value: 3,
  },
  {
    label: `4 ${strings.pcs}`,
    value: 4,
  },
  {
    label: `5 ${strings.pcs}`,
    value: 5,
  },
];

export const quantity = [
  {
    label: `10 ${strings.en.grams}`,
    value: 10,
  },
  {
    label: `20 ${strings.en.grams}`,
    value: 20,
  },
  {
    label: `30 ${strings.en.grams}`,
    value: 30,
  },
  {
    label: `40 ${strings.en.grams}`,
    value: 40,
  },
  {
    label: `50 ${strings.en.grams}`,
    value: 50,
  },
  {
    label: `60 ${strings.en.grams}`,
    value: 60,
  },
  {
    label: `70 ${strings.en.grams}`,
    value: 70,
  },
  {
    label: `80 ${strings.en.grams}`,
    value: 80,
  },
  {
    label: `90 ${strings.en.grams}`,
    value: 90,
  },
  {
    label: `100 ${strings.en.grams}`,
    value: 100,
  },
  {
    label: `110 ${strings.en.grams}`,
    value: 110,
  },
  {
    label: `120 ${strings.en.grams}`,
    value: 120,
  },
  {
    label: `130 ${strings.en.grams}`,
    value: 130,
  },
  {
    label: `140 ${strings.en.grams}`,
    value: 140,
  },
  {
    label: `150 ${strings.en.grams}`,
    value: 150,
  },
  {
    label: `160 ${strings.en.grams}`,
    value: 160,
  },
  {
    label: `170 ${strings.en.grams}`,
    value: 170,
  },
  {
    label: `180 ${strings.en.grams}`,
    value: 180,
  },
  {
    label: `190 ${strings.en.grams}`,
    value: 190,
  },
  {
    label: `200 ${strings.en.grams}`,
    value: 200,
  },
  {
    label: `210 ${strings.en.grams}`,
    value: 210,
  },
  {
    label: `220 ${strings.en.grams}`,
    value: 220,
  },
  {
    label: `230 ${strings.en.grams}`,
    value: 230,
  },
  {
    label: `240 ${strings.en.grams}`,
    value: 240,
  },
  {
    label: `250 ${strings.en.grams}`,
    value: 250,
  },
];

export const freeIngredientsPerPackage = {
  WL: {
    carbs: 100,
    prots: 100,
    sides: 50,
  },
  WW: {
    carbs: 150,
    prots: 150,
    sides: 50,
  },
  WG: {
    carbs: 200,
    prots: 200,
    sides: 50,
  },
};

export const freeIngredientsBreakfastPerPackage = {
  WL: {
    carbs: 100,
    prots: {
      None: 0,
      Beef: 100,
      Eggs: 5,
      'Turkey(Lite)': 2,
    },
    vegetables: 50,
  },
  WW: {
    carbs: 150,
    prots: {
      None: 0,
      Beef: 150,
      Eggs: 5,
      'Turkey(Lite)': 2,
    },
    vegetables: 50,
  },
  WG: {
    carbs: 200,
    prots: {
      None: 0,
      Beef: 150,
      Eggs: 7,
      'Turkey(Lite)': 2,
    },
    vegetables: 50,
  },
};
