import Header from 'components/Header';
import RaisedView from 'components/RaisedView';
import React from 'react';
import VerticalLinearStepper from 'components/Stepper';

const Packages = () => {
  return (
    <div>
      <Header />
      <RaisedView className="main main-raised mb-3 mt-9">
        <div
          className="container"
          style={{
            paddingTop: 200,
            paddingBottom: 200,
            backgroundColor: '#fff',
          }}
        >
          <VerticalLinearStepper />
        </div>
      </RaisedView>
    </div>
  );
};

export default Packages;
