import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import Validators from 'helpers/formValidator';
import { reduxForm } from 'redux-form';
import AsyncButton from 'components/AsyncButton';

const ForgotPasswordForm = ({ handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <InputField
        name="email"
        label="EMAIL"
        validate={[Validators.email, Validators.required]}
      />

      <div className="d-flex justify-content-center">
        <AsyncButton
          className="btn btn-primary transition-3d-hover"
          type="submit"
          text="Submit"
        />
      </div>
    </form>
  );
};

ForgotPasswordForm.propTypes = {
  handleSubmit: PropTypes.func,
};
export default reduxForm({ form: 'ForgotPasswordForm' })(ForgotPasswordForm);
