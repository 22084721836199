/* eslint-disable no-bitwise */
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMealPlans } from 'helpers/formatMealPlans';
import prices from 'constants/prices';
import { strings } from 'constants/strings';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});
const RenderModal = ({ dietType, isOpen, closeModal }) => {
  const formattedMealPlans = formatMealPlans(prices[dietType]);
  const extractSubArray = category =>
    formattedMealPlans[category].sort((a, b) => a - b);
  const classes = useStyles();

  const caloriesArray = extractSubArray('cal');
  const _5 = extractSubArray('5');
  const _20 = extractSubArray('20');
  const _26 = extractSubArray('26');
  const _30 = extractSubArray('30');
  const diteName = {
    WL: 'Weight Loss',
    WW: 'Weight Watchers',
    WG: 'Weight Gainers',
  };

  let j = 3;

  if (dietType === 'WG') {
    j = 4;
  }

  const caloriesArraySorted = [];

  for (let i = 0; i < caloriesArray.length; i++) {
    if (i % 2 === 0) {
      caloriesArraySorted.push(caloriesArray[i / 2]);
    } else {
      caloriesArraySorted.push(caloriesArray[i + j]);
      j -= 1;
    }
  }
  
return (
  <Modal
    rotated
    title={diteName[dietType]}
    isOpen={isOpen}
    closeModal={closeModal}
    className="days-off-dialog"
  >
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{`30 ${strings.en.day}`}</TableCell>
            <TableCell align="left">{`26 ${strings.en.day}`}</TableCell>
            <TableCell align="left">{`20 ${strings.en.day}`}</TableCell>
            <TableCell align="left">{`5 ${strings.en.day}`}</TableCell>
            <TableCell align="left">{strings.en.snacks}</TableCell>
            <TableCell align="left">{strings.en.meals}</TableCell>
            <TableCell align="left">{strings.en.calories}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {caloriesArraySorted.map((row, i) => (
            <TableRow key={row.toString()}>
              <TableCell align="left">{`${_30[i]} AED`}</TableCell>
              <TableCell align="left">{`${_26[i]} AED`}</TableCell>
              <TableCell align="left">{`${_20[i]} AED`}</TableCell>
              <TableCell align="left">{`${_5[i]} AED`}</TableCell>
              <TableCell align="left">{i & 1 ? '1' : '-'}</TableCell>
              <TableCell align="left">{Math.round((i + 1) / 2)}</TableCell>
              <TableCell align="left">{caloriesArraySorted[i]}</TableCell>
            </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  </Modal>
  );
};

RenderModal.propTypes = {
  dietType: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.bool,
};
export default RenderModal;
