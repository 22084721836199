import PropTypes from 'prop-types';
import React from 'react';
import AsyncButton from 'components/AsyncButton';

const ModalFooter = (handleSubmit, Cancel) => {
  return (
    <>
      <button className="btn btn-primary" onClick={Cancel} type="button">
        Cancel
      </button>

      <AsyncButton
        className="btn btn-primary"
        onClick={handleSubmit}
        type="button"
        text="Confirm"
      />
    </>
  );
};

ModalFooter.prototypes = {
  handleSubmit: PropTypes.func,
  Cancel: PropTypes.func,
};
export default ModalFooter;
