import { strings } from './strings';

export const mealPlans = [
  { label: 'Weight Loss', value: 'WL' },
  { label: 'Weight Watchers', value: 'WW' },
  { label: 'Weight Gainers', value: 'WG' },
];

export const daysPerMonth = [
  {
    label: `5 ${strings.day}`,
    value: 5,
  },
  {
    label: `20 ${strings.day}`,
    value: 20,
  },
  {
    label: `26 ${strings.day}`,
    value: 26,
  },
  {
    label: `30 ${strings.day}`,
    value: 30,
  },
];

export const maxNumberOfMeals = {
  WL: 4,
  WG: 5,
  WW: 4,
};

export const daysOff = 2;
export const maxNumberOfSnacks = 1;
