/* eslint-disable max-lines */
import {
  ALL_SELECTED_MEALS,
  CUSTOM_MEALS,
  FETCH_ALL_MEALS,
  FETCH_HOMEPAGE_MEALS,
  SELECTED_SNACKS,
  SELECTED_BREAKFASTS,
  CUSTOM_BREAKFASTS,
  SELECTED_JUICES,
} from 'constants/types/Meals';
import { Meals, Orders } from 'config/firebase';
import { startLoading, stopLoading } from 'actions/Loading';
import { api } from 'api';
import { errorHandler } from 'helpers/errorHandler';
import { toast } from 'react-toastify';

export const fetchAllMeals = () => async dispatch => {
  dispatch(startLoading());
  const snapshots = await Meals.get();
  const { docs } = snapshots;
  const meals = docs.map(doc => ({ ...doc.data(), id: doc.id }));

  dispatch({
    type: FETCH_ALL_MEALS,
    payload: meals,
  });
  dispatch(stopLoading());
};

export const selectMealsSnacks = (
  snacks,
  meals,
  juices,
  date,
  history,
) => async (dispatch, getState) => {
  dispatch(startLoading());
  const {
    userReducer: { user },
  } = getState();

  const {
    couponReducer: {
      response: { id },
    },
  } = getState();

  const extraCharges =
    snacks.reduce((a, b) => a + (isNaN(b.price) ? 0 : b.price), 0) +
    meals.reduce((a, b) => a + (isNaN(b.totalPrice) ? 0 : b.totalPrice), 0) +
    juices.length * 10;

  if (extraCharges > 0) {
    const paymentObject = {
      items: { [date]: { meals, snacks } },
      amount: extraCharges,
      currency: 'AED',
      description: 'New Order',
      userId: user.id,
      promotionId: id,
    };

    try {
      const res = await api.post('paidOrder', { paymentObject });

      window.location.replace(res.data.order.url);
    } catch (error) {
      errorHandler(error);
    }
  } else {
    try {
      await Orders.doc(user.id).update({ [date]: { meals, snacks, juices } });
      history.push('/meal-calendar');
      toast('your meals has been successfully selected !', {
        className: 'toast-info',
      });
    } catch (error) {
      try {
        await Orders.doc(user.id).set({ [date]: { meals, snacks, juices } });
        history.push('/meal-calendar');
        toast('your meals has been successfully selected !', {
          className: 'toast-info',
        });
      } catch (newError) {
        errorHandler(newError);
      }
    }
  }
  dispatch(stopLoading());
};
export const setCustomMeals = (customMeal, isAdd = true, history, date) => (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  let {
    meals: { customMeals },
  } = getState();

  if (!customMeals || !customMeals[date]) {
    const newCustomMeals = {};

    newCustomMeals[date] = [];
    customMeals = newCustomMeals;
  }
  if (isAdd) {
    customMeals[date].push(customMeal);
    dispatch({ type: CUSTOM_MEALS, payload: customMeals });
    history.goBack();
  } else {
    customMeals[date] = customMeals[date].filter(obj => obj !== customMeal);
    dispatch({ type: CUSTOM_MEALS, payload: customMeals });
  }
  dispatch(stopLoading());
};

export const setCustomBreakfasts = (
  customBreakfast,
  isAdd = true,
  history,
  date,
) => (dispatch, getState) => {
  dispatch(startLoading());

  let {
    meals: { customBreakfasts },
  } = getState();

  if (!customBreakfasts || !customBreakfasts[date]) {
    const newCustomMeals = {};

    newCustomMeals[date] = [];
    customBreakfasts = newCustomMeals;
  }

  if (isAdd) {
    customBreakfasts[date].push(customBreakfast);
    dispatch({ type: CUSTOM_BREAKFASTS, payload: customBreakfasts });
    history.goBack();
  } else {
    customBreakfasts[date] = customBreakfasts[date].filter(
      obj => obj !== customBreakfast,
    );
    dispatch({ type: CUSTOM_BREAKFASTS, payload: customBreakfasts });
  }
  dispatch(stopLoading());
};

export const setMeals = (meal, isAdd = true, date) => (dispatch, getState) => {
  dispatch(startLoading());

  let {
    meals: { allMeals },
  } = getState();

  if (!allMeals || !allMeals[date]) {
    const allMealsNew = {};

    allMealsNew[date] = [];
    allMeals = allMealsNew;
  }
  if (isAdd) {
    allMeals[date].push(meal);
  } else {
    allMeals[date] = allMeals[date].filter(obj => obj.id !== meal.id);
  }
  dispatch({ type: ALL_SELECTED_MEALS, payload: allMeals });
  dispatch(stopLoading());
};

export const setBreakfasts = (breakfast, isAdd = true, date) => (
  dispatch,
  getState,
) => {
  dispatch(startLoading());

  let {
    meals: { breakfasts },
  } = getState();

  if (!breakfasts || !breakfasts[date]) {
    const newBreakfasts = {};

    newBreakfasts[date] = [];
    breakfasts = newBreakfasts;
  }
  if (isAdd) {
    breakfasts[date].push(breakfast);
  } else {
    breakfasts[date] = breakfasts[date].filter(obj => obj.id !== breakfasts.id);
  }
  dispatch({ type: SELECTED_BREAKFASTS, payload: breakfasts });
  dispatch(stopLoading());
};
export const setSnacks = (snack, isAdd = true, date) => (
  dispatch,
  getState,
) => {
  dispatch(startLoading);

  let {
    meals: { snacks },
  } = getState();

  if (!snacks || !snacks[date]) {
    const snacksNew = {};

    snacksNew[date] = [];
    snacks = snacksNew;
  }
  if (isAdd) {
    snacks[date].push(snack);
  } else {
    snacks[date] = snacks[date].filter(obj => obj.id !== snack.id);
  }
  dispatch({ type: SELECTED_SNACKS, payload: snacks });
  dispatch(stopLoading);
};

export const setJuices = (juice, isAdd = true, date) => (
  dispatch,
  getState,
) => {
  dispatch(startLoading);

  let {
    meals: { juices },
  } = getState();

  if (!juices || !juices[date]) {
    const juicesNew = {};

    juicesNew[date] = [];
    juices = juicesNew;
  }
  if (isAdd) {
    juices[date].push(juice);
  } else {
    juices[date] = juices[date].filter(obj => obj.id !== juice.id);
  }

  dispatch({ type: SELECTED_JUICES, payload: juices });
  dispatch(stopLoading);
};

export const fetchHomePageMeals = () => async dispatch => {
  dispatch(startLoading());
  const snapshots = await Meals.limit(9).get();
  const { docs } = snapshots;
  const homePageMeals = docs.map(doc => ({ ...doc.data(), id: doc.id }));

  dispatch({
    type: FETCH_HOMEPAGE_MEALS,
    payload: homePageMeals,
  });
  dispatch(stopLoading());
};
