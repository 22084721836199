/* eslint-disable max-lines */
import {
  MuiThemeProvider,
  createMuiTheme,
  makeStyles,
} from '@material-ui/core/styles';
import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { CheckCoupon } from 'actions/CheckCoupon';
import FirstStep from 'components/FirstStep';
import Invoice from 'components/Invoice';
import Paper from '@material-ui/core/Paper';
import SecondStep from 'components/SecondStep';
import Step from '@material-ui/core/Step';
import StepContent from '@material-ui/core/StepContent';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import ThirdStep from 'components/ThirdStep';
import Typography from 'components/Typography';
import { useDispatch } from 'react-redux';
import FourthStep from 'components/FourthStep';
import { SELECTED_START_DATE } from 'constants/types/Plan';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));
const muiTheme = createMuiTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#080040',
    },
    secondary: {
      main: '#fbaf02',
    },
  },
});
const VerticalLinearStepper = () => {
  const dispatch = useDispatch();
  const handleCheck = promoId => () => {
    dispatch(CheckCoupon(promoId));
  };
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const getSteps = () => {
    return [
      'Select your meal plan',
      'Choose one of our delivery packages',
      'Add discount coupon',
      'Choose your starting date',
      'View Summary',
    ];
  };
  const steps = getSteps();

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <FirstStep
            handleBack={handleBack}
            onSubmit={handleNext}
            activeStep={activeStep}
          />
        );
      case 1:
        return (
          <SecondStep
            handleBack={handleBack}
            onSubmit={handleNext}
            activeStep={activeStep}
          />
        );
      case 2:
        return (
          <ThirdStep
            handleBack={handleBack}
            onSubmit={handleNext}
            activeStep={activeStep}
            handleCheck={handleCheck}
          />
        );
      case 3:
        return (
          <FourthStep
            handleBack={handleBack}
            onSubmit={handleNextFourth}
            activeStep={activeStep}
          />
        );
      case 4:
        return <Invoice handleBack={handleBack} activeStep={activeStep} />;
      default:
        return 'Unknown step';
    }
  };

  const handleNext = vals => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleNextFourth = vals => {
    dispatch({ type: SELECTED_START_DATE, payload: vals.startDate });
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className={classes.root}>
      <MuiThemeProvider theme={muiTheme}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
              <StepContent>
                {getStepContent(index)}
                <div className={classes.actionsContainer} />
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length - 1 && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
          </Paper>
        )}
      </MuiThemeProvider>
    </div>
  );
};

export default VerticalLinearStepper;
