import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import SwitchBox from 'components/SwitchBox';

const SwitchBoxField = ({
  label,
  name,
  placeholder,
  validate,
  className,
  ...props
}) => {
  return (
    <Field
      name={name}
      id={name}
      label={label}
      component={SwitchBox}
      placeholder={placeholder}
      aria-label="name"
      className={className}
      validate={validate}
      {...props}
    />
  );
};

SwitchBoxField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validate: PropTypes.array,
  className: PropTypes.string,
};

export default SwitchBoxField;
