import PropTypes from 'prop-types';
import React from 'react';

const SwitchBox = ({
  id,
  label,
  input: { onChange, ...input },
  meta: { submitFailed, error, warning },
  onChecked,
  ...props
}) => {
  const handleInputChange = event => {
    onChecked && onChecked(event.target.checked, input.name);
    onChange(event.target.checked);
  };

  return (
    <div
      className={`form-group ${
        submitFailed && (error || warning) ? 'u-has-error' : ''
      }`}
    >
      <div className="custom-control custom-switch">
        <input
          {...input}
          {...props}
          onChange={handleInputChange}
          id={input.name}
          checked={input.value}
          type="checkbox"
          className="custom-control-input"
        />
        <label className="custom-control-label pt-1" htmlFor={input.name}>
          <span className="d-flex justify-content-between align-items-center">
            {label}
          </span>
        </label>
      </div>
      {submitFailed &&
        ((error && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {error}
          </div>
        )) ||
          (warning && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {warning}
            </div>
          )))}
    </div>
  );
};

SwitchBox.propTypes = {
  toggleShowPassword: PropTypes.func,
  props: PropTypes.object,
  input: PropTypes.object,
  id: PropTypes.string,
  label: PropTypes.string,
  meta: PropTypes.object,
  onChecked: PropTypes.func,
};

export default SwitchBox;
