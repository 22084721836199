import React, { useState } from 'react';
import InputField from 'components/InputField';
import MapField from 'components/MapField';
import PropTypes from 'prop-types';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import { ZONES } from 'constants/zones';
import formatOptions from 'helpers/formatOptions';
import { reduxForm } from 'redux-form';
import AsyncButton from 'components/AsyncButton';
import 'react-phone-input-2/lib/style.css';
import InputPhoneField from 'components/InputPhoneField';
import SwitchBoxField from 'components/SwitchBoxField';
import { deliveryTimeOptions } from 'constants/deliveryTimeOptions';

const SignUpForm = ({ handleSubmit }) => {
  const [regionOptions, setReguionOptions] = useState([]);
  const updateRegionOptions = selectedZone => {
    setReguionOptions(formatOptions(selectedZone.value));
  };
  const [address, setAddress] = useState();
  const [value, setValue] = useState();
  const handleAddressChange = NewAddress => {
    setAddress(NewAddress);
  };

  return (
    <form className="js-validate mt-5" onSubmit={handleSubmit}>
      <InputField label="NAME" name="name" validate={[Validators.required]} />
      <InputField
        name="email"
        label="EMAIL"
        validate={[Validators.email, Validators.required]}
      />
      <InputField
        name="password"
        label="PASSWORD"
        type="password"
        validate={[Validators.required]}
      />

      <SelectField
        name="delivery"
        label="DELIVERY TIME"
        options={deliveryTimeOptions}
        validate={[Validators.required]}
      />

      <SelectField
        name="zone"
        label="ZONE"
        options={ZONES}
        validate={[Validators.required]}
        getSelectedValue={updateRegionOptions}
      />
      {regionOptions && regionOptions.length > 0 && (
        <SelectField
          name="region"
          label="REGION"
          options={regionOptions}
          validate={[Validators.required]}
        />
      )}
      <InputField
        name="addressDescription"
        label="ADDRESS DESCRIPTION"
        type="text"
      />
      <div className="MapField">
        <MapField
          label="Please select your gps coordinates"
          name="gps"
          getAddress={handleAddressChange}
        />
      </div>
      <InputField name="address" className="d-none" value={address} />

      <InputPhoneField
        name="phone"
        country="ae"
        value={value}
        onChange={setValue}
        onlyCountries={['ae']}
        placeholder="022 222 2222"
        validate={[Validators.required]}
      />

      <SwitchBoxField
        label="To continue please confirm you are at least 18 years of age"
        name="age"
        validate={[Validators.required]}
      />
      <InputField name="note" label="NOTE" type="text" />
      <AsyncButton
        className="btn btn-sm btn-primary transition-3d-hover mt-3 col-3"
        type="submit"
        text="Submit"
      />
    </form>
  );
};

SignUpForm.propTypes = {
  handleSubmit: PropTypes.func,
};

export default reduxForm({ form: 'SignUpForm' })(SignUpForm);
