const homePageStrings = {
  homePage: 'Home Page',
  welcomeTo: 'Welcome to',
  dietlicious: 'Dietlicious',
  firstHead: {
    conviction: 'our conviction',
    tasteLikeHeaven: 'Dietlicious is where diets taste like heaven',
  },
  list: [
    {
      title: 'Pick your meal plan',
      body:
        'Our meal plans are designed to help you achiev your desired idea weight',
    },
    {
      title: 'Select & adjust your Orders',
      body: 'A great tool to plan meals for up to 10 days ahead.',
    },
    {
      title: 'Enjoy your Diet',
      body:
        'Each Dietlicious delivery brings about the taste of our fresh and nutritious ingredients. A true culinary experience.',
    },
  ],
  weightLose: 'Weight Lose',
  weightGain: 'Weight Gain',
  weightWatchers: 'Weight Watchers',
  weightLoseBody:
    "With the right ingredients If you think your body can't gain more pounds,think again. Our gain plan is designed for the ambitious, losing weight can be a delightful experience.",
  weightGainBody:
    "If you think your body can't gain more pounds, think again. Our gain plan is designed for the ambitious.",
  weightWatchersBody:
    "Looking at the Watchers plan means that you have reached your weight goal. Now it's time to safeguard the equilibrium.",
  whatMakesDietlicousBodacious: 'What makes Dietlicous Bodacious',
  ourLiciousWays: 'Our licious ways',
  parallaxBody:
    'We take pride in the artistry involved in the concoction of our delicacies. We believe that going on a diet should never compromise the joys of a tasty meal',
  ourMealPlans: 'Our meal plans',
};

export default homePageStrings;
