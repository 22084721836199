/* eslint-disable newline-after-var */
// /* eslint-disable react-hooks/exhaustive-deps */
// import { useEffect, useState } from 'react';
// import {
//   freeIngredientsPerPackage,
//   freeIngredientsBreakfastPerPackage,
//   breakFastProteins,
// } from 'constants/customMeal';
// import { useSelector } from 'react-redux';

// export const useCreateCustomBreakfast = change => {
//   const currentPlan = useSelector(({ userReducer }) => userReducer.currentPlan);
//   const { packageType } = currentPlan;

//   const [carbsOptions, setCarbsOptions] = useState([]);
//   const [proteinsOptions, setProteinsOptions] = useState([]);
//   const [sidesOptions, setSidesOptions] = useState([]);

//   const [carbsPrice, setCarbsPrice] = useState(0);
//   const [protPrice, setProtPrice] = useState(0);
//   const [sidePrice, setSidePrice] = useState(0);

//   const [carbsQuantity, setCarbsQuantity] = useState(0);
//   const [proteinQuantity, setProteinsQuantity] = useState(0);
//   const [sideQuantity, setSidesQuantity] = useState(0);

//   const [selectedSubProt, setSelectedSubProt] = useState(0);
//   const [selectedSubSide, setSelectedSubSide] = useState(0);

//   const [selectedSubProteins, setSelectedSubProteins] = useState(
//     breakFastProteins[0].value[0].value,
//   );
//   const [selectedCarbs, setSelectedCarb] = useState(BreakFastcarbs[0].value);
//   const [selectedSubCarbs, setSelectedSubCarb] = useState(
//     BreakFastcarbs[0].value[0].value,
//   );

//   const [selectedVegetables, setSelectedVegetables] = useState(
//     breakFastVegetables[0].value,
//   );
//   const [selectedSubVegetables, setSelectedSubVegetables] = useState(
//     breakFastVegetables[0].value[0].value,
//   );

//   const updateCarbsOptions = selected => {
//     setCarbsOptions(selected.value);
//   };

//   const updateProteinsOptions = selected => {
//     setProteinsOptions(selected.value);
//   };

//   const updateSidesOptions = selected => {
//     setSidesOptions(selected.value);
//   };

//   const updateSubCarbs = selected => {
//     setSelectedSubCarb(selected.value);
//   };

//   const updateSubProteins = selected => {
//     setSelectedSubProt(selected.value);
//   };

//   const updateSubSides = selected => {
//     setSelectedSubSide(selected.value);
//   };

//   const updateCarbsQuantity = selected => {
//     setCarbsQuantity(selected.value);
//   };

//   const updateProteinsQuantity = selected => {
//     setProteinsQuantity(selected.value);
//   };

//   const updateSidesQuantity = selected => {
//     setSidesQuantity(selected.value);
//   };

//   // useEffect(() => {
//   //   const quantity =
//   //     carbsQuantity - freeIngredientsPerPackage[packageType].carbs <= 0
//   //       ? 0
//   //       : carbsQuantity - freeIngredientsPerPackage[packageType].carbs;

//   //   setCarbsPrice(Math.ceil(quantity / 50) * selectedSubCarb.price);
//   // }, [carbsQuantity, selectedSubCarb]);
//   // useEffect(() => {
//   //   const quantity =
//   //     proteinQuantity - freeIngredientsPerPackage[packageType].prots <= 0
//   //       ? 0
//   //       : proteinQuantity - freeIngredientsPerPackage[packageType].prots;

//   //   setProtPrice(Math.ceil(quantity / 50) * selectedSubProt.price);
//   // }, [proteinQuantity, selectedSubProt]);
//   // useEffect(() => {
//   //   const quantity =
//   //     sideQuantity - freeIngredientsPerPackage[packageType].sides <= 0
//   //       ? 0
//   //       : sideQuantity - freeIngredientsPerPackage[packageType].sides;

//   //   setSidePrice(Math.ceil(quantity / 50) * selectedSubSide.price);
//   // }, [sideQuantity, selectedSubSide]);
//   // useEffect(() => {
//   //   const totalPrice = (carbsPrice || 0) + (protPrice || 0) + (sidePrice || 0);

//   //   change('price', totalPrice);
//   // }, [carbsPrice, protPrice, sidePrice]);

//   const getProteinLabel = () => {
//     const selectedProt = breakFastProteins.filter(item => {
//       if (item.value[0].label === selectedProtein[0].label) {
//         return item;
//       }
//     });

//     return selectedProt[0].label;
//   };

//   useEffect(() => {
//     if (selectedSubCarbs.type !== 'Slite-bread') {
//       const quantity =
//         carbsQuantity -
//           freeIngredientsBreakfastPerPackage[packageType]['carbs'] <=
//         0
//           ? 0
//           : carbsQuantity -
//             freeIngredientsBreakfastPerPackage[packageType]['carbs'];

//       setCarbsPrice(Math.ceil(quantity / 50) * selectedSubCarbs.price);
//     } else setCarbsPrice(0);
//   }, [carbsQuantity, selectedCarbs]);
//   useEffect(() => {
//     const quantity =
//       proteinQuantity -
//         freeIngredientsBreakfastPerPackage[packageType]['prots'][
//           getProteinLabel()
//         ] <=
//       0
//         ? 0
//         : proteinQuantity -
//           freeIngredientsBreakfastPerPackage[packageType]['prots'][
//             getProteinLabel()
//           ];

//     const proteinPrice = () => {
//       if (getProteinLabel() === 'Beef') {
//         return Math.ceil(quantity / 50) * selectedSubProteins.price;
//       } else return quantity * selectedSubProteins.price;
//     };

//     setProtPrice(proteinPrice());
//   }, [proteinQuantity, selectedSubProteins]);

//   useEffect(() => {
//     const quantity =
//       vegetableQuantity -
//         freeIngredientsBreakfastPerPackage[packageType]['vegetables'] <=
//       0
//         ? 0
//         : vegetableQuantity -
//           freeIngredientsBreakfastPerPackage[packageType]['vegetables'];
//     setVegetablePrice(Math.ceil(quantity / 50) * selectedSubVegetables.price);
//   }, [vegetableQuantity, selectedSubVegetables]);

//   useEffect(() => {
//     setProteinQuantity(proteinQuantity1 + extraEggsQuantity);
//   }, [proteinQuantity1, extraEggsQuantity]);

//   return {
//     carbsOptions,
//     proteinsOptions,
//     sidesOptions,
//     updateCarbsOptions,
//     updateProteinsOptions,
//     updateSidesOptions,
//     updateSubCarbs,
//     updateSubProteins,
//     updateSubSides,
//     updateCarbsQuantity,
//     updateProteinsQuantity,
//     updateSidesQuantity,
//     carbsPrice,
//     protPrice,
//     sidePrice,
//   };
// };
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  freeIngredientsPerPackage,
  freeIngredientsBreakfastPerPackage,
  breakFastProteins,
} from 'constants/customMeal';
import { useSelector } from 'react-redux';

export const useCreateCustomBreakfast = change => {
  const currentPlan = useSelector(({ userReducer }) => userReducer.currentPlan);
  const { packageType } = currentPlan;

  const [carbsOptions, setCarbsOptions] = useState([]);
  const [proteinsOptions, setProteinsOptions] = useState([]);
  const [sidesOptions, setSidesOptions] = useState([]);

  const [carbsPrice, setCarbsPrice] = useState(0);
  const [protPrice, setProtPrice] = useState(0);
  const [sidePrice, setSidePrice] = useState(0);

  const [carbsQuantity, setCarbsQuantity] = useState(0);
  const [proteinQuantity, setProteinsQuantity] = useState(0);
  const [sideQuantity, setSidesQuantity] = useState(0);

  const [selectedSubCarb, setSelectedSubCarb] = useState(0);
  const [selectedSubProt, setSelectedSubProt] = useState(0);
  const [selectedSubSide, setSelectedSubSide] = useState(0);

  const updateCarbsOptions = selected => {
    setCarbsOptions(selected.value);
  };

  const updateProteinsOptions = selected => {
    setProteinsOptions(selected.value);
  };

  const updateSidesOptions = selected => {
    setSidesOptions(selected.value);
  };

  const updateSubCarbs = selected => {
    setSelectedSubCarb(selected.value);
  };

  const updateSubProteins = selected => {
    setSelectedSubProt(selected.value);
  };

  const updateSubSides = selected => {
    setSelectedSubSide(selected.value);
  };

  const updateCarbsQuantity = selected => {
    setCarbsQuantity(selected.value);
  };

  const updateProteinsQuantity = selected => {
    setProteinsQuantity(selected.value);
  };

  const updateSidesQuantity = selected => {
    setSidesQuantity(selected.value);
  };

  // useEffect(() => {
  //   const quantity =
  //     carbsQuantity - freeIngredientsPerPackage[packageType].carbs <= 0
  //       ? 0
  //       : carbsQuantity - freeIngredientsPerPackage[packageType].carbs;

  //   setCarbsPrice(Math.ceil(quantity / 50) * selectedSubCarb.price);
  // }, [carbsQuantity, selectedSubCarb]);
  // useEffect(() => {
  //   const quantity =
  //     proteinQuantity - freeIngredientsPerPackage[packageType].prots <= 0
  //       ? 0
  //       : proteinQuantity - freeIngredientsPerPackage[packageType].prots;

  //   setProtPrice(Math.ceil(quantity / 50) * selectedSubProt.price);
  // }, [proteinQuantity, selectedSubProt]);
  // useEffect(() => {
  //   const quantity =
  //     sideQuantity - freeIngredientsPerPackage[packageType].sides <= 0
  //       ? 0
  //       : sideQuantity - freeIngredientsPerPackage[packageType].sides;

  //   setSidePrice(Math.ceil(quantity / 50) * selectedSubSide.price);
  // }, [sideQuantity, selectedSubSide]);

  const getProteinLabel = () => {
    const selectedProt = breakFastProteins.filter(item => {
      if (item.value[0].label === proteinsOptions[0].label) {
        return item;
      }
    });

    return selectedProt[0].label;
  };

  useEffect(() => {
    if (selectedSubCarb.type !== 'Slite-bread') {
      const quantity =
        carbsQuantity -
          freeIngredientsBreakfastPerPackage[packageType]['carbs'] <=
        0
          ? 0
          : carbsQuantity -
            freeIngredientsBreakfastPerPackage[packageType]['carbs'];

      setCarbsPrice(Math.ceil(quantity / 50) * selectedSubCarb.price);
    } else setCarbsPrice(0);
  }, [carbsQuantity, selectedSubCarb]);
  useEffect(() => {
    if (proteinsOptions.length > 0) {
      const quantity =
        proteinQuantity -
          freeIngredientsBreakfastPerPackage[packageType]['prots'][
            getProteinLabel()
          ] <=
        0
          ? 0
          : proteinQuantity -
            freeIngredientsBreakfastPerPackage[packageType]['prots'][
              getProteinLabel()
            ];

      const proteinPrice = () => {
        if (getProteinLabel() === 'Beef') {
          return Math.ceil(quantity / 50) * selectedSubProt.price;
        } else return quantity * selectedSubProt.price;
      };
      setProtPrice(proteinPrice());
    }
  }, [proteinQuantity, selectedSubProt, proteinsOptions]);

  useEffect(() => {
    const quantity =
      sideQuantity -
        freeIngredientsBreakfastPerPackage[packageType]['vegetables'] <=
      0
        ? 0
        : sideQuantity -
          freeIngredientsBreakfastPerPackage[packageType]['vegetables'];
    setSidePrice(Math.ceil(quantity / 50) * selectedSubSide.price);
  }, [sideQuantity, selectedSubSide]);

  useEffect(() => {
    const totalPrice = (carbsPrice || 0) + (protPrice || 0) + (sidePrice || 0);

    change('price', totalPrice);
    change('carbsPrice', totalPrice);
    change('sidePrice', sidePrice);
    change('protPrice', protPrice);
  }, [carbsPrice, protPrice, sidePrice]);

  return {
    carbsOptions,
    proteinsOptions,
    sidesOptions,
    updateCarbsOptions,
    updateProteinsOptions,
    updateSidesOptions,
    updateSubCarbs,
    updateSubProteins,
    updateSubSides,
    updateCarbsQuantity,
    updateProteinsQuantity,
    updateSidesQuantity,
    carbsPrice,
    protPrice,
    sidePrice,
    selectedSubCarb,
  };
};
