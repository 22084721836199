/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from 'components/Typography';
import { fetchHomePageMeals } from 'actions/Meal';
import useStyles from 'components/ThirdRaisedView/useStyle';
import widths from 'constants/widths';

const ThirdRaisedView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchHomePageMeals());
  }, []);

  const homePageMeals = useSelector(({ meals }) => meals.homePageMeals);

  return (
    <div className="container space-top-2 space-bottom-2">
      <div className={classes.root} component="section">
        <h2 className="font-weight-medium text-center text-secondary">
          Feast your eyes upon our selection of fine meals
        </h2>
        <div className={classes.images}>
          {homePageMeals &&
            homePageMeals.map(({ id, image, title }, index) => (
              <ButtonBase
                key={id}
                className={`${classes.imageWrapper} default-cursor scale-image`}
                style={{
                  width: widths[index],
                }}
              >
                <div
                  className={classes.imageSrc}
                  style={{
                    backgroundImage: `url(${image})`,
                  }}
                />
                <div className={classes.imageBackdrop} />
                <div className={classes.imageButton}>
                  <Typography
                    component="h3"
                    variant="h6"
                    color="inherit"
                    className={classes.imageTitle}
                  >
                    {title.en}
                    <div className={classes.imageMarked} />
                  </Typography>
                </div>
              </ButtonBase>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ThirdRaisedView;
