export const formatMealPlans = (mealPlan, category, payload = {}) => {
  if (!mealPlan) return payload;

  if (typeof mealPlan === 'number' || typeof mealPlan === 'string') {
    if (payload[category]) {
      payload = { ...payload, [category]: [...payload[category], mealPlan] };
    } else {
      payload = { ...payload, [category]: [mealPlan] };
    }

    return payload;
  }

  Object.keys(mealPlan).forEach(element => {
    payload = { ...formatMealPlans(mealPlan[element], element, payload) };
  });

  return payload;
};
