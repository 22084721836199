import React from 'react';

const AboutUs = () => {
  return (
    <section className="space-top-4 g-py-50">
      <div className="container">
        {/* License Content */}
        <div className="u-shadow-v22 g-bg-white g-pa-20 g-pa-40--lg g-mt-minus-100 rounded g-mb-40">
          <h3>
            <span style={{ fontWeight: 400 }}>About Us</span>
          </h3>
          <p>
            <span style={{ fontWeight: 400 }}>
              Passion, purpose and hardwork – these are the ingredients of what
              drove the success of Dietlicious today. From its humble beginnings
              of cooking healthy food in a typical home kitchen, the restaurant
              has continuously increased its solid client base across the UAE
              through referrals and repeated orders. Since its inception in
              2017, our first – ever branch was launched in Dalma street, Abu
              Dhabi providing unique alternatives to the usual fried, oily, and
              unhealthy food that are often offered by numerous restaurants
              nowadays.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Dietlicious has derived its name from two combined words – Diet
              and Delicious, which means that eating healthy food doesn’t have
              to be boring. We strongly believe that “you are what you eat” and
              the food you consume can create a powerful effect on your health -
              it can be your best medicine or your worst poison. With this is
              mind, our ultimate goal is to focus on creating nourishing,
              wholesome food while following a well-balanced diet using good
              quality unprocessed ingredients freshly prepared from scratch
              daily in our kitchen.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              At Dietlicious, our chefs and nutritionists work in collaboration
              in order to create nutrition solutions that cater to diverse needs
              of our customers. We offer flavorsome and nutritious build – your
              - own meats to create a new, healthy and exciting food experience
              without compromising its taste and nutrients. Our strong expertise
              lies on balancing protein, carbs and fat to make sure that you are
              getting all the nutrients you need.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Our services include dine – in, take – away, home delivery and
              monthly meal package subscriptions. Whether your goal is to gain
              muscle mass, maintain diet or lose excessive bodyweight.
              Dietlicious will serve as your trusted partner in health and
              wellness that takes your goals and objectives seriously just as
              you do.
            </span>
          </p>
        </div>
        {/* End License Content */}
        <div className="text-center">
          <p>
            Have questions or suggestions?
            {' '}
            <a className="text-nowrap" href="https://dietlicious.ae">
              Contact Us
            </a>
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
