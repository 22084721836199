/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { change, reduxForm } from 'redux-form';
import {
  maxNumberOfMeals,
  maxNumberOfSnacks,
  mealPlans,
} from 'constants/mealPlans';
import { useDispatch, useSelector } from 'react-redux';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import RenderModal from 'containers/RenderModal';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';

const FirstStepForm = ({ activeStep, handleBack, handleSubmit }) => {
  const [maxNbOfSnacks, setMaxNbOfSnacks] = useState(maxNumberOfSnacks);
  const [maxMeals, setMaxMeals] = useState(maxNumberOfMeals.WL);
  const [isOpen, setIsOpen] = useState(false);
  const selectedDiet = useSelector(
    ({ form }) => form.FirstStepForm.values.dietType,
  );
  const nbOfMeals = useSelector(
    ({ form }) => form.FirstStepForm.values.nbrOfMeals,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedDiet === 'WG' && Number(nbOfMeals) === 5) {
      setMaxNbOfSnacks(0);
      dispatch(change('FirstStepForm', 'nbrOfSnacks', 0));
    } else {
      setMaxNbOfSnacks(maxNumberOfSnacks);
    }
  }, [selectedDiet, nbOfMeals]);

  useEffect(() => {
    setMaxMeals(maxNumberOfMeals[selectedDiet]);
  }, [selectedDiet]);

  const handleSelectChange = () => {
    if (selectedDiet === 'WL' && nbOfMeals > maxMeals) {
      dispatch(change('FirstStepForm', 'nbrOfMeals', 3));
    }
    if (selectedDiet === 'WW' && nbOfMeals > maxMeals) {
      dispatch(change('FirstStepForm', 'nbrOfMeals', 4));
    }
  };

  useEffect(() => {
    handleSelectChange();
  }, [selectedDiet, maxMeals, nbOfMeals]);
  const closeModal = () => {
    setIsOpen(false);
  };
  const showDietModal = dietType => {
    setIsOpen(true);
  };

  return (
    <>
      <RenderModal
        isOpen={isOpen}
        closeModal={closeModal}
        dietType={selectedDiet}
      />
      <form onSubmit={handleSubmit}>
        <div className="Shop-grid-wrapper">
          <div className="shop-head row">
            <div className="col-sm-12 col-md-6 col-xl-6 mb-10">
              <SelectField
                label="Diet Type"
                name="dietType"
                type="text"
                validate={[Validators.required]}
                options={mealPlans}
                getSelectedValue={showDietModal}
              />

              <InputField
                label="Number Of Meals"
                name="nbrOfMeals"
                type="number"
                max={maxMeals}
                min={1}
                placeholder="Number Of Meals"
                validate={[Validators.required]}
              />
              <InputField
                label="Number Of Snacks"
                name="nbrOfSnacks"
                type="number"
                min={0}
                max={maxNbOfSnacks}
                placeholder="Number of Snacks"
                validate={[Validators.required]}
              />
              <button
                disabled={activeStep === 0}
                onClick={handleBack}
                type="button"
                className="btn btn-secondary"
              >
                Back
              </button>
              <button
                variant="contained"
                color="primary"
                type="submit"
                className="btn btn-primary"
              >
                {activeStep === 3 ? 'Finish' : 'Next'}
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

FirstStepForm.propTypes = {
  activeStep: PropTypes.number,
  handleBack: PropTypes.func,
  handleSubmit: PropTypes.func,
};
export default reduxForm({
  form: 'FirstStepForm',
  destroyOnUnmount: false,
  enableReinitialize: true,
})(FirstStepForm);
