/* eslint-disable eqeqeq */
import CheckIcon from '@material-ui/icons/Check';
import Modal from 'components/Modal';
import ModalFooter from 'pages/MenuPerDatePage/ModalFooter';
import ModalTitle from 'pages/MenuPerDatePage/ModalTitle';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import PropTypes from 'prop-types';
import React from 'react';
import { useMenuPerDate } from 'hooks/useMenuPerDate';
import { useMenuPerDateHeader } from 'hooks/useMenuPerDateHeader';
import { useDispatch } from 'react-redux';
import { CheckCoupon } from 'actions/CheckCoupon';
import CouponForm from 'containers/CouponForm';

const Header = ({ date }) => {
  const {
    currentPlan,
    selectedMeals,
    selectedSnacks,
    selectedJuices,
  } = useMenuPerDate(date);
  const {
    isOpen,
    addPaused,
    RemovePaused,
    handleSubmit,
    cancel,
    handleConfirmSelection,
  } = useMenuPerDateHeader(date, selectedSnacks, selectedMeals, selectedJuices);

  const dispatch = useDispatch();
  const handleCheck = promoId => () => {
    dispatch(CheckCoupon(promoId));
  };

  const extraCharges =
    selectedSnacks.reduce((a, b) => a + (isNaN(b.price) ? 0 : b.price), 0) +
    selectedMeals.reduce((a, b) => {
      return a + (isNaN(b.totalPrice) ? 0 : b.totalPrice);
    }, 0) +
    selectedJuices.length * 10;

  return (
    <div className="d-flex justify-content-between">
      {currentPlan.pausedDays && currentPlan.pausedDays.includes(date) ? (
        <button
          className="btn btn-xs btn-soft-primary btn-wide transition-3d-hover text-left mb-2"
          type="button"
          onClick={RemovePaused}
        >
          <span className="media align-items-center">
            <PlayArrowIcon style={{ fontSize: '35px', color: '#fbaf02' }} />

            <span className="media-body">
              <span className="font-size-1">Resume</span>
              <span className="d-block">this day</span>
            </span>
          </span>
        </button>
      ) : (
        <>
          <button
            className="btn btn-xs btn-soft-primary btn-wide transition-3d-hover text-left mb-2"
            type="button"
            onClick={addPaused}
          >
            <span className="media align-items-center">
              <PauseIcon style={{ fontSize: '35px' }} />

              <span className="media-body ">
                <span className="font-size-1">Pause</span>
                <span className="d-block">this day</span>
              </span>
            </span>
          </button>
          <button
            className="btn btn-xs btn-soft-primary btn-wide transition-3d-hover text-left mb-2"
            type="button"
            disabled={
              selectedSnacks.length < 0 &&
              selectedMeals.length < 0 &&
              selectedJuices.length < 0
            }
            onClick={handleConfirmSelection}
          >
            <span className="media align-items-center">
              <CheckIcon style={{ fontSize: '35px', color: '#fbaf02' }} />

              <span className="media-body">
                <span className="font-size-1">Confirm</span>
                <span className="d-block">your selection</span>
              </span>
            </span>
          </button>
        </>
      )}
      <Modal
        isOpen={isOpen}
        onClose={cancel}
        title={ModalTitle()}
        footer={ModalFooter(handleSubmit, cancel)}
      >
        Please Confirm your selection for the day : {date}
        {extraCharges && extraCharges > 0 && (
          <CouponForm handleCheck={handleCheck} />
        )}
      </Modal>
    </div>
  );
};

Header.propTypes = {
  date: PropTypes.string,
};
export default Header;
