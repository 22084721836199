import PropTypes from 'prop-types';
import React from 'react';

const navigation = {
  PREVIOUS: 'PREV',
  NEXT: 'NEXT',
  TODAY: 'TODAY',
  DATE: 'DATE',
};

const Toolbar = ({ onNavigate, localizer: { messages }, label }) => {
  const navigate = action => () => {
    onNavigate(action);
  };

  return (
    <div className="d-flex justify-content-between">
      <div>
        <button
          className="btn btn-soft-github mx-1 py-1"
          type="button"
          onClick={navigate(navigation.PREVIOUS)}
        >
          {messages.previous}
        </button>
        <button
          className="btn btn-soft-github mx-1 py-1"
          type="button"
          onClick={navigate(navigation.TODAY)}
        >
          {messages.today}
        </button>

        <button
          className="btn btn-soft-github mx-1 py-1"
          type="button"
          onClick={navigate(navigation.NEXT)}
        >
          {messages.next}
        </button>
      </div>
      <span className="h3 bold">{label}</span>
    </div>
  );
};

Toolbar.propTypes = {
  label: PropTypes.node.isRequired,
  localizer: PropTypes.object,
  onNavigate: PropTypes.func.isRequired,
};

export default Toolbar;
