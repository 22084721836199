/* eslint-disable no-bitwise */

import React from 'react';

import Container from 'pages/PricesPage/Container';

const PricesPage = () => {
  return (
    <section className="space-top-4 g-py-50">
      <div className="container">
        {/* License Content */}
        <div className="u-shadow-v22 g-bg-white g-pa-20 g-pa-40--lg g-mt-minus-100 rounded g-mb-40">
          <h3>
            <span style={{ fontWeight: 400 }}>Weight Loss</span>
          </h3>

          <Container dietType="WL" />

          <p>&nbsp;</p>
          <h3>
            <span style={{ fontWeight: 400 }}>Weight Watchers</span>
          </h3>

          <p>
            <span style={{ fontWeight: 400 }}>
              <Container dietType="WW" />
            </span>
          </p>
          <p>&nbsp;</p>
          <h3>
            <span style={{ fontWeight: 400 }}>Weight Gainers</span>
          </h3>

          <p>
            <span style={{ fontWeight: 400 }}>
              <Container dietType="WG" />
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default PricesPage;
