/* eslint-disable max-lines */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';

const Terms = () => {
  return (
    <section className="space-top-4 g-py-50">
      <div className="container">
        {/* License Content */}
        <div className="u-shadow-v22 g-bg-white g-pa-20 g-pa-40--lg g-mt-minus-100 rounded g-mb-40">
          <h3>
            <span style={{ fontWeight: 400 }}>General Terms</span>
          </h3>
          <p>
            <span style={{ fontWeight: 400 }}>
              After registration you will receive the system choices for the
              first two days after can choose meals after2 days If you don't
              choose, the system will choose for you You can cancel the order
              before 48 hours We can pause any day before 24 hours if we do not
              want to receive orders that day Orders arrive the same day and
              during the time intervals chosen by the customer at registration.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Additional charges will be applicable for any extras added If you
              exceed the number of grams authorized for your subscription the
              difference amount will be applicable:
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              <p>
                Weight Lose: Carbs -> 100g, Protein -> 100g, Vegetable -> 50g
              </p>
              <p>
                Weight Watcher: Carbs -> 150g,Protein -> 150g, Vegetable -> 50g
              </p>
              <p>
                Weight Gainer: Carbs -> 200g,Protein -> 200g, Vegetable -> 50g
              </p>
              <p>
                Your upgrade is applicable for higher packages/plans For
                degrading your packages only in renewal.
              </p>
            </span>
          </p>
          <p>&nbsp;</p>
          <h3>
            <span style={{ fontWeight: 400 }}>TERMS & CONDITIONS </span>
          </h3>
          <p>
            <span style={{ fontWeight: 400 }}>
              Dietlicious Restaurant LLC maintains the https://dietlicious.ae/
              Website (Site). United Arab of Emirates is our country of domicile
              and stipulate that the governing law is the local law. ‘’Visa or
              MasterCard debit and credit cards in AED will be accepted for
              payment. We will not trade with or provide any services to OFAC
              and sanctioned countries’’.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              Customer using the website who are Minor /under the age of 18
              shall not register as a User of the website and shall not transact
              on or use the website. Cardholder must retain a copy of
              transaction records and Dietlicious Restaurant LLC policies and
              rules. User is responsible for maintaining the confidentiality of
              his account.
            </span>
          </p>
          <h3>&nbsp;</h3>
          <h3>
            <span style={{ fontWeight: 400 }}>PRIVACY POLICY</span>
          </h3>
          <p>
            <span style={{ fontWeight: 400 }}>
              All credit/debit cards details and personally identifiable
              information will NOT be stored, sold, shared, rented or leased to
              any third parties’’ Dietlicious Restaurant LLC will not pass any
              debit/credit card details to third parties Dietlicious Restaurant
              LLC takes appropriate steps to ensure data privacy and security
              including through various hardware and software methodologies.
              However, (https://dietlicious.ae/) cannot guarantee the security
              of any information that is disclosed online.
            </span>
          </p>
          <p>
            <span style={{ fontWeight: 400 }}>
              The Dietlicious Restaurant LLC is not responsible for the privacy
              policies of websites to which it links. If you provide any
              information to such third parties different rules regarding the
              collection and use of your personal information may apply. You
              should contact these entities directly if you have any questions
              about their use of the information that they collect. The Website
              Policies and Terms & Conditions may be changed or updated
              occasionally to meet the requirements and standards. Therefore,
              the Customers are encouraged to frequently visit these sections to
              be updated about the changes on the website. Modifications will be
              effective on the day they are posted.
            </span>
          </p>
          <p>&nbsp;</p>
          <h3>
            <span style={{ fontWeight: 400 }}>PAYMENT CONFIRMATION</span>
          </h3>
          <p>
            <span style={{ fontWeight: 400 }}>
              Once the payment is made, the confirmation notice will be sent to
              the client via email within 24 hours of receipt of payment. 
            </span>
          </p>
          <p>&nbsp;</p>
          <h3>
            <span style={{ fontWeight: 400 }}>Refund Policy</span>
          </h3>
          <p>
            <span style={{ fontWeight: 400 }}>
              Refunds will be done only through the Original Mode of Payment.
              Refunds are made in case of a mistake in the service provided by
              the restaurant. Refunds are made if the service provided is
              harmful to the customer. The customer can recover his money after
              the case study from the restaurant administration and take the
              appropriate decision.
            </span>
          </p>
          <p>&nbsp;</p>
          <h3>
            <span style={{ fontWeight: 400 }} id="cancel">
              CANCELLATION POLICY
            </span>
          </h3>
          <p>
            <span style={{ fontWeight: 400 }}>
              Customer can cancel their requested services or order before 48
              hours; refunds will be made back to the payment solution used
              initially by the customer. Please allow for up to 45days for the
              refund transfer to be completed.
            </span>
          </p>
          <p>&nbsp;</p>
          <h3>
            <span style={{ fontWeight: 400 }}>DELIVERY/SHIPPING POLICY</span>
          </h3>
          <p>
            <span style={{ fontWeight: 400 }}>
              Orders are delivered to customers through the restaurant's
              drivers. Requests are delivered to the locations specified by the
              client when creating the account. Delivery takes place in all
              areas registered on the site and application. Delivery process:
              Local areas one hour - Free delivery to all areas of Abu Dhabi and
              Al Ain registered on the site and application.
            </span>
          </p>
        </div>
        {/* End License Content */}
        <div className="text-center">
          <p>
            Have questions or suggestions?
            {' '}
            <a className="text-nowrap" href="https://dietlicious.ae">
              Contact Us
            </a>
          </p>
          <p>&nbsp;</p>
        </div>
      </div>
    </section>
  );
};

export default Terms;
