import React from 'react';
import SignUpForm from 'containers/SignUpForm';
import SignUpStrings from 'constants/text/signUp';
import { signUp } from 'actions/User';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const SignUp = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = values => {
    dispatch(signUp(values, history));
  };

  return (
    <div>
      <div className="main main-raised" style={{ marginTop: '50px' }}>
        <div className="container space-2">
          <div className=" w-md-75 w-lg-50 mx-md-auto ">
            <h1 className="h3 text-primary font-weight-normal mb-0">
              {SignUpStrings.welcomeTo}
              <span className="font-weight-semi-bold">
                {SignUpStrings.dietlicious}
              </span>
            </h1>
            <p>{SignUpStrings.FillOutTheFormToGetStarted}</p>
          </div>
          <div className="d-flex align-items-center position-relative ">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-3 space-lg-0">
                  <SignUpForm onSubmit={handleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
