/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { freeIngredientsPerPackage } from 'constants/customMeal';
import { useSelector } from 'react-redux';

export const useCreateMeal = change => {
  const currentPlan = useSelector(({ userReducer }) => userReducer.currentPlan);
  const { packageType } = currentPlan;

  const [carbsOptions, setCarbsOptions] = useState([]);
  const [proteinsOptions, setProteinsOptions] = useState([]);
  const [sidesOptions, setSidesOptions] = useState([]);

  const [carbsPrice, setCarbsPrice] = useState(0);
  const [protPrice, setProtPrice] = useState(0);
  const [sidePrice, setSidePrice] = useState(0);

  const [carbsQuantity, setCarbsQuantity] = useState(0);
  const [proteinQuantity, setProteinsQuantity] = useState(0);
  const [sideQuantity, setSidesQuantity] = useState(0);

  const [selectedSubCarb, setSelectedSubCarb] = useState(0);
  const [selectedSubProt, setSelectedSubProt] = useState(0);
  const [selectedSubSide, setSelectedSubSide] = useState(0);

  const updateCarbsOptions = selected => {
    setCarbsOptions(selected.value);
  };

  const updateProteinsOptions = selected => {
    setProteinsOptions(selected.value);
  };

  const updateSidesOptions = selected => {
    setSidesOptions(selected.value);
  };

  const updateSubCarbs = selected => {
    setSelectedSubCarb(selected.value);
  };

  const updateSubProteins = selected => {
    setSelectedSubProt(selected.value);
  };

  const updateSubSides = selected => {
    setSelectedSubSide(selected.value);
  };

  const updateCarbsQuantity = selected => {
    setCarbsQuantity(selected.value);
  };

  const updateProteinsQuantity = selected => {
    setProteinsQuantity(selected.value);
  };

  const updateSidesQuantity = selected => {
    setSidesQuantity(selected.value);
  };

  useEffect(() => {
    const quantity =
      carbsQuantity - freeIngredientsPerPackage[packageType].carbs <= 0
        ? 0
        : carbsQuantity - freeIngredientsPerPackage[packageType].carbs;

    setCarbsPrice(Math.ceil(quantity / 50) * selectedSubCarb.price);
  }, [carbsQuantity, selectedSubCarb]);
  useEffect(() => {
    const quantity =
      proteinQuantity - freeIngredientsPerPackage[packageType].prots <= 0
        ? 0
        : proteinQuantity - freeIngredientsPerPackage[packageType].prots;

    setProtPrice(Math.ceil(quantity / 50) * selectedSubProt.price);
  }, [proteinQuantity, selectedSubProt]);
  useEffect(() => {
    const quantity =
      sideQuantity - freeIngredientsPerPackage[packageType].sides <= 0
        ? 0
        : sideQuantity - freeIngredientsPerPackage[packageType].sides;

    setSidePrice(Math.ceil(quantity / 50) * selectedSubSide.price);
  }, [sideQuantity, selectedSubSide]);
  useEffect(() => {
    const totalPrice = (carbsPrice || 0) + (protPrice || 0) + (sidePrice || 0);

    change('price', totalPrice);
    change('carbsPrice', totalPrice);
    change('sidePrice', sidePrice);
    change('protPrice', protPrice);
  }, [carbsPrice, protPrice, sidePrice]);

  return {
    carbsOptions,
    proteinsOptions,
    sidesOptions,
    updateCarbsOptions,
    updateProteinsOptions,
    updateSidesOptions,
    updateSubCarbs,
    updateSubProteins,
    updateSubSides,
    updateCarbsQuantity,
    updateProteinsQuantity,
    updateSidesQuantity,
    carbsPrice,
    protPrice,
    sidePrice,
  };
};
