import PropTypes from 'prop-types';
import React from 'react';

const homeImageCard = ({ body, image, title, left }) => {
  return (
    <div
      className="row justify-content-md-center edonec-section-two"
      style={left ? { flexDirection: 'row-reverse' } : undefined}
    >
      <div
        className="col-md-6 align-self-md-center mb-3 mb-md-0 aos-init aos-animate"
        data-aos="fade-right"
      >
        <img className="img-fluid rounded" src={image} alt="description" />
      </div>
      {/* Card */}
      <div
        className="col-md-6 align-self-md-center order-md-2 mb-9 mb-md-0 aos-init aos-animate"
        data-aos="fade-left"
      >
        {/* Description */}
        <h2 className="font-weight-medium text-primary">
          {title}
          <br />
        </h2>
        <p>{body}</p>
      </div>
    </div>
  );
};

homeImageCard.propTypes = {
  body: PropTypes.string,
  title: PropTypes.string,
  image: PropTypes.string,
  left: PropTypes.bool,
};

export default homeImageCard;
