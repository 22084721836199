import AsyncButton from 'components/AsyncButton';
import DisplayData from 'components/DisplayData/DisplayData';
import PropTypes from 'prop-types';
import React from 'react';

const CustomMealCard = ({ customMeal, onClick, text }) => {
  return (
    <div className="card card-frame border-dashed mb-3">
      <div
        className="card-body"
        style={{
          minHeight: '350px',
        }}
      >
        <h2 className="card-title text-center text-primary font-weight-bold">
          Custom Meal
        </h2>
        <div className="card-text ">
          <DisplayData title="Carbs " value={customMeal.carbs.value} />

          <DisplayData title="quantity " value={customMeal.carbs.quantity} />

          <DisplayData title="Proteins " value={customMeal.protein.value} />

          <DisplayData title="quantity" value={customMeal.protein.quantity} />

          <DisplayData title="Sides" value={customMeal.sides.value} />

          <DisplayData title="quantity " value={customMeal.sides.quantity} />

          <DisplayData title="Price " value={customMeal.totalPrice} />
          {text && (
            <AsyncButton
              className="mt-4"
              onClick={onClick}
              type="button"
              text={text}
            />
          )}
        </div>
      </div>
    </div>
  );
};

CustomMealCard.propTypes = {
  customMeal: PropTypes.shape({
    carbs: PropTypes.string,
    carbsQuantity: PropTypes.number,
    protein: PropTypes.string,
    proteinsQuantity: PropTypes.number,
    sides: PropTypes.string,
    sidesQuantity: PropTypes.number,
    totalPrice: PropTypes.number,
  }),
  onClick: PropTypes.func,
  text: PropTypes.string,
};

export default CustomMealCard;
