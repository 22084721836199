/* eslint-disable react-hooks/exhaustive-deps */
import { FormSection, reduxForm } from 'redux-form';
import React, { useEffect, useState } from 'react';
import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import { useSelector } from 'react-redux';
import AsyncButton from 'components/AsyncButton';

const IngredientsForm = ({
  handleSubmit,
  ingredientOptions,
  supplimentOptions,
}) => {
  const values = useSelector(
    ({ form }) => form?.IngredientsForm?.values?.supplements,
  );
  const [total, setTotal] = useState(0);

  useEffect(() => {
    let _total = 0;

    supplimentOptions &&
      supplimentOptions.map(o => {
        _total = +(
          ((values && values[o.value.name]) || 0) * o.value.price +
          _total
        ).toFixed(2);

        return false;
      });
    setTotal(_total);
  }, [values]);
  const _handleSubmit = e => {
    e.preventDefault();
    handleSubmit(e);
  };
  const min = '0';

  return (
    <form onSubmit={_handleSubmit}>
      {ingredientOptions && (
        <>
          <SelectField
            multi
            name="ingredients"
            label="Ingredients"
            options={ingredientOptions}
          />
        </>
      )}
      <FormSection name="supplements">
        {supplimentOptions &&
          supplimentOptions.map(o => (
            <div key={o.label} className="row">
              <div className="col-md-8">
                <div className="d-flex justify-content-between">
                  <InputField
                    label={o.value.name}
                    type="number"
                    min={min}
                    validate={[Validators.positive]}
                    name={o.value.name}
                  />
                  <div className="vertical-cetered-text">
                    {`Price : ${+(
                      ((values && values[o.value.name]) || 0) * o.value.price
                    ).toFixed(2)} AED`}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </FormSection>
      <div className="d-flex justify-content-between">
        {`total amount to be paid = ${total} AED`}
        <AsyncButton
          className="btn btn-primary transition-3d-hover"
          type="submit"
          text="Submit"
        />
      </div>
    </form>
  );
};

IngredientsForm.propTypes = {
  handleSubmit: PropTypes.func,
  ingredientOptions: PropTypes.array,
  supplimentOptions: PropTypes.array,
};

export default reduxForm({ form: 'IngredientsForm', enableReinitialize: true })(
  IngredientsForm,
);
