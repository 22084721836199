/* eslint-disable max-lines */
const prices = {
  WL: {
    0: {
      1: {
        5: 125,
        20: 500,
        26: 650,
        30: 750,
        cal: '350-400',
      },
      2: {
        5: 250,
        20: 1000,
        26: 1300,
        30: 1500,
        cal: '500-700',
      },
      3: {
        5: 375,
        20: 1500,
        26: 1950,
        30: 2250,
        cal: '800-1000',
      },
      4: {
        5: 500,
        20: 2000,
        26: 2600,
        30: 3000,
        cal: '1200-1400',
      },
      5: {
        5: undefined,
        20: undefined,
        26: undefined,
        30: undefined,
      },
    },
    1: {
      1: {
        5: 175,
        20: 700,
        26: 910,
        30: 1050,
        cal: '400-500',
      },
      2: {
        5: 300,
        20: 1200,
        26: 1560,
        30: 1800,
        cal: '700-800',
      },
      3: {
        5: 425,
        20: 1700,
        26: 2210,
        30: 2550,
        cal: '1000-1200',
      },
      4: {
        5: 550,
        20: 2200,
        26: 2860,
        30: 3300,
        cal: '1400-1600',
      },
      5: {
        5: undefined,
        20: undefined,
        26: undefined,
        30: undefined,
      },
    },
  },
  WW: {
    0: {
      1: {
        5: 135,
        20: 550,
        26: 715,
        30: 825,
        cal: '400-500',
      },
      2: {
        5: 275,
        20: 1100,
        26: 1430,
        30: 1650,
        cal: '700-900',
      },
      3: {
        5: 410,
        20: 1650,
        26: 2145,
        30: 2475,
        cal: '1000-1100',
      },
      4: {
        5: 550,
        20: 2200,
        26: 2860,
        30: 3300,
        cal: '1400-1600',
      },
      5: {
        5: undefined,
        20: undefined,
        26: undefined,
        30: undefined,
      },
    },
    1: {
      1: {
        5: 185,
        20: 750,
        26: 975,
        30: 1125,
        cal: '500-700',
      },
      2: {
        5: 325,
        20: 1300,
        26: 1690,
        30: 1950,
        cal: '800-1000',
      },
      3: {
        5: 460,
        20: 1850,
        26: 2405,
        30: 2775,
        cal: '1100-1400',
      },
      4: {
        5: 600,
        20: 2400,
        26: 3120,
        30: 3600,
        cal: '1600-1800',
      },
      5: {
        5: undefined,
        20: undefined,
        26: undefined,
        30: undefined,
      },
    },
  },
  WG: {
    0: {
      1: {
        5: 150,
        20: 600,
        26: 780,
        30: 900,
        cal: '600-900',
      },
      2: {
        5: 300,
        20: 1200,
        26: 1560,
        30: 1800,
        cal: '1100-1400',
      },
      3: {
        5: 450,
        20: 1800,
        26: 2340,
        30: 2700,
        cal: '1600-1800',
      },
      4: {
        5: 600,
        20: 2400,
        26: 3120,
        30: 3600,
        cal: '2000-2200',
      },
      5: {
        5: 750,
        20: 3000,
        26: 3900,
        30: 4500,
        cal: '2500-2800',
      },
    },
    1: {
      1: {
        5: 200,
        20: 800,
        26: 1040,
        30: 1200,
        cal: '900-1100',
      },
      2: {
        5: 350,
        20: 1400,
        26: 1820,
        30: 2100,
        cal: '1400-1600',
      },
      3: {
        5: 500,
        20: 2000,
        26: 2600,
        30: 3000,
        cal: '1800-2000',
      },
      4: {
        5: 650,
        20: 2600,
        26: 3380,
        30: 3900,
        cal: '2200-2400',
      },
      5: {
        5: undefined,
        20: undefined,
        26: undefined,
        30: undefined,
      },
    },
  },
};

export default prices;
