import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import DatePickerField from 'components/DatePickerField';

const FourthStep = ({ activeStep, handleBack, handleSubmit }) => {
  return (
    <form onSubmit={handleSubmit}>
      <div>
        <DatePickerField label="Starting Date" name="startDate" />
      </div>
      <button
        disabled={activeStep === 0}
        onClick={handleBack}
        type="button"
        className="btn btn-secondary"
      >
        Back
      </button>
      <button
        variant="contained"
        color="primary"
        type="submit"
        className="btn btn-primary"
      >
        {activeStep === 4 ? 'Finish' : 'Next'}
      </button>
    </form>
  );
};

FourthStep.propTypes = {
  activeStep: PropTypes.number,
  handleBack: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default reduxForm({
  form: 'FourthStep',
  enableReinitialize: true,
})(FourthStep);
