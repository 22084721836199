/* eslint-disable max-lines */
export const ABU_DHABI_ZONES = [
  'Abu Al Abyad Island',
  'Abu Dhabi University',
  'Airport Road AXD',
  'Al Aryam Island',
  'Al Bander',
  'Al Barakkah',
  'Al Bateen AXD',
  'Al Dhafra AXD',
  'Al Dhafra Area',
  'Al Falah Area',
  'Al Falah street',
  'Al Guwaifat',
  'Al Hadbah Street',
  'Al Khaleej Al Arabi',
  'Al Ladeem Street',
  'Al Manaser',
  'Al Maryah Island',
  'Al Muneera',
  'Al Muroor',
  'Al Muzoon',
  'Al Nahda Al Askarya',
  'Al Nahyan Area',
  'Al Najda Street',
  'Al Qurm',
  'Al Raha Beach',
  'Al Raha Gardens',
  'Al Rawdah',
  'Al Rayhan',
  'Al Reef Villas',
  'Al Reem Island',
  'Al Seef Compound',
  'Al Taweelah',
  'Al Zaab',
  'Al Zahraa',
  'Al Zina',
  'Al saada street',
  'Bahya',
  'Banyas',
  'Bida Zayed',
  'Bidda Al Motawah',
  'Bin al Jesrin',
  'Corniche',
  'Dalma SR',
  'Defence Road',
  'Delma Island',
  'Delma street',
  'Electra street',
  'Gayathi',
  'Grand Moshq Area',
  'Gulf Gardens',
  'Hameem street',
  'Hazza ben Zayed street',
  'Hills AbuDhabi',
  'Hydra Village AXD',
  'Istiqlal street',
  'Jawasat',
  'Karama street AXD',
  'Khalidiyah AXD',
  'Khalifa City A',
  'Khalifa City B',
  'Khalifa City',
  'Khalifa Garden',
  'Khalifa street AXD',
  'Liwa SR',
  'Liwa Village',
  'Liwa street',
  'Liwa',
  'Madinat Zayed',
  'Mafraq Area',
  'Maharba',
  'Mangrove Village',
  'Mangrove one compound',
  'Marfa',
  'Marina Mall',
  'Masdar City',
  'Mina Road AXD',
  'Mufasah',
  'Muhamed Ben Zayed City',
  'Mushref',
  'Nahda new',
  'Nasser Street',
  'Navy Gate',
  'New Corniche AXD',
  'New Shahama',
  'Officers City',
  'Old Mazdar Road',
  'Old Shahama',
  'Rahba',
  'Ruwais',
  'SAS Al Nakheel',
  'Sadiyat Island',
  'Salam street',
  'Shahama',
  'Shakhbouth City',
  'Shalilah',
  'Shawamak',
  'Shuwayhat Island',
  'Sila',
  'Sir Bani Yas Island',
  'Tarif',
  'Tourist Club',
  'Um Al Nar',
  'Wathaba',
  'Yas Island',
  'Yasat Ali Island',
  'Zafranan Area',
  'Zayed Sport City',
  'Zayed Street',
  'Zayed the first street',
];

// eslint-disable-next-line camelcase
export const Al_AIN_ZONES = [
  'Abu Samra',
  'Ain Al Fayda',
  'Airport East District',
  'Al Agabiyaa',
  'Al Ain City',
  'Al Ameriya',
  'Al Barsa Garden',
  'Al Batin',
  'Al Bhuraimi Oman',
  'Al Dhahera',
  'Al Faqah',
  'Al Foah',
  'Al Ganeema',
  'Al Hayer',
  'Al Hili',
  'Al Jahili',
  'Al Jimi',
  'Al Khabisi',
  'Al Khalidia Alain',
  'Al Khalif',
  'Al Khatim',
  'Al Khazna',
  'Al Kubisi',
  'Al Kuwaitat',
  'Al Manasif',
  'Al Manasir Alain',
  'Al Maqam',
  'Al Markhaniya',
  'Al Masoudi',
  'Al Muntazi',
  'Al Muroor Alain',
  'Al Mutaredh',
  'Al Mutawaa',
  'Al Muwaiji',
  'Al Naifa',
  'Al Niama',
  'Al Niyadat',
  'Al Qattara',
  'Al Qua',
  'Al Saad',
  'Al Salamat',
  'Al Sanaiyyah',
  'Al Sarooj',
  'Al Shuaibar',
  'Al Shwaib Fujairah Road',
  'Al Shwaib',
  'Al Touba',
  'Al Towayya',
  'Al Wagan',
  'Al Yahar',
  'Alain City Center',
  'Andalus Complex',
  'Asharej',
  'Cental District',
  'Falaj Hazza',
  'Green Land Comp',
  'Hilton',
  'Industrial Area Alain',
  'Jabel Hafeet',
  'Khalifa Street Alain',
  'Kharis',
  'Khatim Shukla Buraimi',
  'Libab',
  'Lisily',
  'Markam',
  'Masakin',
  'Meyzad',
  'Mubarak R/A',
  'Murabba',
  'Murejib',
  'Nahel Towm',
  'Nahil',
  'Remah',
  'Rumaila Alain',
  'Sanaiya',
  'Shiab Al Ashkhar',
  'Surouj',
  'Sweihan',
  'Swihan Alain',
  'Tawan',
  'Tower Clock Area',
  'Town Center',
  'Um Ghaffa',
  'Wadi Al ain',
  'Zakhir',
];

export const ZONES = [
  {
    label: 'Abu Dhabi',
    value: ABU_DHABI_ZONES,
  },
  {
    label: 'Al Ain',
    value: Al_AIN_ZONES,
  },
];
