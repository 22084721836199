/* eslint-disable max-lines */
import moment from 'moment';

export const formatEvents = (currentPlan, userOrders) => {
  if (!currentPlan) return [];
  const _startDay = moment(
    currentPlan.startedAt || currentPlan.createdAt,
  ).format('DD-MM-YYYY');

  const startDay = moment(_startDay, 'DD-MM-YYYY', true);
  const startDayMinus2 = moment(_startDay, 'DD-MM-YYYY', true).subtract(
    2,
    'days',
  );
  const startDayMinus1 = moment(_startDay, 'DD-MM-YYYY', true).subtract(
    1,
    'days',
  );
  const _startDayMinus1 = startDayMinus1.format('DD-MM-YYYY');
  const _today = moment().format('DD-MM-YYYY');
  const today = moment(_today, 'DD-MM-YYYY', true);

  const todayPlus1 = moment(_today, 'DD-MM-YYYY', true)
    .add(1, 'days')
    .format('DD-MM-YYYY');
  const todayPlus2 = moment(_today, 'DD-MM-YYYY', true)
    .add(2, 'days')
    .format('DD-MM-YYYY');

  const events = [];

  if (today.isSameOrAfter(moment(_startDay, 'DD-MM-YYYY'))) {
    const selected =
      userOrders &&
      Object.keys(userOrders).sort((a, b) => moment(a) - moment(b));

    let totalNbOfDays = currentPlan.remainingNumberOfDays + 1;

    let state = '';

    const past = today.diff(startDay, 'days');

    if (selected?.includes(_today)) totalNbOfDays++;

    if (_startDayMinus1 !== _today) {
      events.push({
        date: _startDayMinus1,
        state: 'welcome',
        start: startDayMinus1,
        end: startDayMinus1.hour(moment().hour() + 1),
        allDay: true,
      });
      totalNbOfDays--;
    }
    if (
      currentPlan.daysOff.includes(startDayMinus1.days()) &&
      _startDayMinus1 === _today
    ) {
      totalNbOfDays--;
    }

    let pausedDaysCount = 0;

    currentPlan &&
      currentPlan.pausedDays &&
      currentPlan.pausedDays.forEach(element => {
        const isFuturePausedDay = moment(
          element,
          'DD-MM-YYYY',
          true,
        ).isSameOrAfter(moment(moment().format('DD-MM-YYYY'), 'DD-MM-YYYY'));

        if (isFuturePausedDay) pausedDaysCount++;
      });

    totalNbOfDays += pausedDaysCount;

    for (let i = 0; i <= totalNbOfDays; i++) {
      const extractedDay = moment()
        .add(i, 'days')
        .days();

      if (currentPlan.daysOff.includes(extractedDay)) {
        totalNbOfDays++;
      }
    }

    for (let i = 0; i < totalNbOfDays; i++) {
      const extractedDate = moment().add(i, 'days');
      const extractedDay = extractedDate.days();
      const date = extractedDate.format('DD-MM-YYYY');

      if ([0, 1, 2].includes(i)) {
        state = 'prepare';
      } else if (selected && selected.includes(date)) {
        state = 'selected';
      } else {
        state = 'choose';
      }
      if (currentPlan.pausedDays && currentPlan.pausedDays.includes(date))
        state = 'paused';
      if (currentPlan.daysOff.includes(extractedDay)) {
        state = 'off';
      }
      if (_startDayMinus1 === date) state = 'welcome';

      events.push({
        date,
        state,
        start: moment(date, 'DD-MM-YYYY', true),
        end: moment(date, 'DD-MM-YYYY', true).hour(moment().hour() + 1),
        allDay: true,
      });
    }

    const lastDay = moment()
      .add(totalNbOfDays, 'days')
      .format('DD-MM-YYYY');

    events.push({
      date: lastDay,
      state: 'renew',
      start: moment(lastDay, 'DD-MM-YYYY', true),
      end: moment(lastDay, 'DD-MM-YYYY', true).hour(moment().hour() + 1),
      allDay: true,
    });

    for (let j = 1; j < past; j++) {
      const extractedDate = moment(startDayMinus1, 'DD-MM-YYYY').add(j, 'days');
      const extractedDay = extractedDate.days();
      const extractedDateString = extractedDate.format('DD-MM-YYYY');

      if (
        currentPlan.daysOff.includes(extractedDay) ||
        (currentPlan.pausedDays &&
          currentPlan.pausedDays.includes(extractedDateString))
      ) {
        events.push({
          date: extractedDateString,
          state: 'off',
          start: extractedDate,
          end: extractedDate.hour(moment().hour() + 1),
          allDay: true,
        });
      }
    }

    selected &&
      selected.map(orderDate => {
        if (today.isAfter(moment(orderDate, 'DD-MM-YYYY', true), true)) {
          state = 'rate';

          events.push({
            date: orderDate,
            state,
            start: moment(orderDate, 'DD-MM-YYYY', true),
            end: moment(orderDate, 'DD-MM-YYYY', true).hour(
              moment().hour() + 1,
            ),
            allDay: true,
          });
        }

        return false;
      });
  } else {
    const selected =
      userOrders &&
      Object.keys(userOrders).sort((a, b) => moment(a) - moment(b));

    let totalNbOfDays = currentPlan.remainingNumberOfDays;

    let state = '';

    let pausedDaysCount = 0;

    currentPlan.pausedDays.forEach(element => {
      const isFuturePausedDay = moment(
        element,
        'DD-MM-YYYY',
        true,
      ).isSameOrAfter(startDayMinus1);

      if (isFuturePausedDay) pausedDaysCount++;
    });

    totalNbOfDays += pausedDaysCount;

    for (let i = 0; i <= totalNbOfDays; i++) {
      if (currentPlan.daysOff.includes(startDayMinus1.add(1, 'days').days())) {
        totalNbOfDays++;
      }
    }

    for (let i = 0; i < totalNbOfDays + 1; i++) {
      const extractedDate = startDayMinus2.add(1, 'days');
      const extractedDay = extractedDate.days();
      const date = extractedDate.format('DD-MM-YYYY');

      if ([1, 2].includes(i) && (date === todayPlus1 || date === todayPlus2)) {
        state = 'prepare';
      } else if (selected && selected.includes(date)) {
        state = 'selected';
      } else {
        state = 'choose';
      }
      if (currentPlan.pausedDays && currentPlan.pausedDays.includes(date))
        state = 'paused';
      if (currentPlan.daysOff.includes(extractedDay)) {
        state = 'off';
      }
      if (i === 0) state = 'welcome';
      events.push({
        date,
        state,
        start: moment(date, 'DD-MM-YYYY', true),
        end: moment(date, 'DD-MM-YYYY', true).hour(moment().hour() + 1),
        allDay: true,
      });
    }

    const lastDay = startDayMinus2.add(1, 'days').format('DD-MM-YYYY');

    events.push({
      date: lastDay,
      state: 'renew',
      start: moment(lastDay, 'DD-MM-YYYY', true),
      end: moment(lastDay, 'DD-MM-YYYY', true).hour(moment().hour() + 1),
      allDay: true,
    });
  }

  return events;
};
