import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
// eslint-disable-next-line sort-imports
import couponReducer from './CouponReducer';
import loading from './Loading';
import meals from './Meals';
import menus from './Menus';
import orders from './Orders';
import customItems from './customItems';
import packageReducer from './PackageReducer';

import userReducer from './UserReducer';

export default combineReducers({
  form,
  loading,
  userReducer,
  couponReducer,
  packageReducer,
  meals,
  menus,
  orders,
  customItems,
});
