/* eslint-disable max-lines */
import {
  ADD_CURRENT_PLAN,
  FETCH_CURRENT_PLAN,
  LOGGED_IN,
  LOGGED_OUT,
  USER_SIGNUP_FAILURE,
} from 'constants/types/Auth';
import { FieldValue, Users, auth, firestore } from 'config/firebase';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import { toast } from 'react-toastify';

export const signIn = (values, history) => async dispatch => {
  dispatch(startLoading());
  try {
    const res = await auth.signInWithEmailAndPassword(
      values.email,
      values.password,
    );
    const fullUser = await fetchUser(res.user);

    dispatch({ type: ADD_CURRENT_PLAN, payload: fullUser.plan });
    dispatch({ type: LOGGED_IN, payload: fullUser });
    history.push('/');
  } catch (error) {
    errorHandler(error);
    dispatch({ type: LOGGED_OUT });
  }
  dispatch(stopLoading());
};
export const signOut = history => async dispatch => {
  dispatch(startLoading());
  try {
    await auth.signOut();
    dispatch({ type: LOGGED_OUT });

    history.push('/');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
export const signUp = (values, history) => async dispatch => {
  dispatch(startLoading());
  try {
    const res = await auth.createUserWithEmailAndPassword(
      values.email,
      values.password,
    );
    const { uid } = res.user;
    const plan = {
      packageType: null,
      nbMealsPerDay: 0,
      nbSnacksPerDay: 0,
      nbOfDays: 0,
      remainingNumberOfDays: 0,
      daysOff: [],
      price: 0,
      priceAfterDiscount: 0,
      createdAt: null,
      isActive: false,
    };

    delete values.password;
    delete values.age;
    const fullUser = { ...values, plan, id: uid };

    fullUser.zone = fullUser.region;
    delete fullUser.region;
    await firestore
      .collection('users')
      .doc(uid)
      .set(fullUser);

    dispatch({ type: LOGGED_IN, payload: { ...values, plan, id: uid } });
    history.push('/packages');
  } catch (error) {
    errorHandler(error);
    dispatch({ type: USER_SIGNUP_FAILURE, payload: error });
    dispatch(stopLoading());
  }
  dispatch(stopLoading());
};
export const setUser = user => async dispatch => {
  if (!user) return;
  dispatch(startLoading());
  const fullUser = await fetchUser(user);
  const planData = {
    currentPlan: fullUser.plan,
    daysOff: fullUser.plan && fullUser.plan.daysOff,
    pausedDays: (fullUser.plan && fullUser.plan.pausedDays) || [],
  };

  dispatch({ type: FETCH_CURRENT_PLAN, payload: planData });
  dispatch({ type: LOGGED_IN, payload: fullUser });
  dispatch(stopLoading());
};
export const fetchUser = async user => {
  const userCollection = firestore.collection('users');
  const fullUser = await (await userCollection.doc(user.uid).get()).data();

  return {
    ...fullUser,
    plan: {
      ...(fullUser?.plan || {}),
      createdAt: new Date(fullUser?.plan?.createdAt),
    },
    id: user.uid,
  };
};
export const addPausedDays = (user, date, history, isAdd = true) => async (
  dispatch,
  getState,
) => {
  dispatch(startLoading());
  const {
    userReducer: { currentPlan },
  } = getState();

  if (isAdd) currentPlan.pausedDays.push(date);
  else {
    currentPlan.pausedDays = currentPlan.pausedDays.filter(
      pausedDay => pausedDay !== date,
    );
  }
  const planData = {
    currentPlan,
    daysOff: user.plan && user.plan.daysOff,
    pausedDays: (currentPlan && currentPlan.pausedDays) || [],
  };

  try {
    await Users.doc(user.id).update({
      'plan.pausedDays': isAdd
        ? FieldValue.arrayUnion(date)
        : FieldValue.arrayRemove(date),
    });
    dispatch({ type: FETCH_CURRENT_PLAN, payload: planData });

    history.goBack();
    if (isAdd) {
      toast('day paused !', {
        className: 'toast-info',
      });
    } else
      toast('day unpaused !', {
        className: 'toast-info',
      });
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};

export const updateUser = (userInfo, history) => async (dispatch, getState) => {
  try {
    dispatch(startLoading());

    const state = getState();
    const {
      userReducer: { user },
    } = state;

    if (!user) return;

    await Users.doc(user.id).update(userInfo);
    const updatedUser = await Users.doc(user.id).get();

    dispatch({ type: LOGGED_IN, payload: updatedUser.data() });
    history.push('/');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
