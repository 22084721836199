/* eslint-disable max-lines */
export const strings = {
  pcs: 'pcs',
  buildYourBreakFastSectionTitles: {
    carbs: 'Carbohydrates',
    protein: 'Protein',
    vegetable: 'Vegetable',
    eggs: 'Eggs',
    turkeyLite: 'Turkey(Lite)',
    Oats: 'Oats',
    sweet_potato: 'Sweet Potatos',
    sliteBread: 'Slite Bread',
  },
  buildYourMealSectionTitles: {
    carbs: 'Carbohydrates',
    rice: 'Rice',
    potato: 'Potato',
    pasta: 'Pasta',
    sauce: 'Pasta sauce',
    protein: 'Protein',
    chicken: 'Chicken',
    fish: 'White fish',
    salmon: 'Salmon',
    beef: 'Beef',
    sides: 'Side items',
    salad: 'Salad / Nuts',
    vegetable: 'Vegetable',
  },
  buildYourBreakFast: {
    protein: {
      eggs: {
        whiteEggs: 'White eggs',
        fullEggs: 'Full eggs',
      },
    },
  },

  BreakfastVegetables: {
    spinach: 'Spinach',
    mushroom: 'Mushroom',
    broccoli: 'Broccoli',
  },
  buildYourMeal: {
    carbohydrtes: {
      rice: {
        kabsa: 'Kabsa rice',
        sticky: 'Sticky rice',
        brown: 'Brown rice',
        majoobs: 'Majoobs rice',
        birivani: 'Birivani rice',
        frikeh: 'Frikeh (Freeekeh)',
        quinoa: 'Quinoa',
        couscous: 'Couscous',
        plain: 'Plain rice',
        lemon: 'Lemon rice',
      },
      potato: {
        mash: 'Mash potato',
        cream: 'Cream cheese mash potato',
        sweetCorn: 'Sweet corn green beans mash potato',
        grill: 'Grill potato wedges',
        gratin: 'Potato grating (Dauphinoise)',
        pineNut: 'Pine nut potato',
        wholeGrain: 'Whole grain mustrad mash potato',
        roast: 'Roast potato',
        baby: 'Baby potato',
        lemon: 'Lemon ross marry potato',
        grilSweet: 'Gril sweet potato',
        sweet: 'Sweet potato mash',
        tikka: 'Tikka potato',
        new: 'New potato',
      },
      pasta: {
        wholePenne: 'Whole wheat penne',
        wholeSpag: 'Whole wheat spaghetti',
        penne: 'Penne',
        spag: 'Spaghetti',
        farfalle: 'Farfalle (Bow tie)',
        fettuccine: 'Fettucine',
        Fusilli: 'Fusilli',
      },
      pastaSauce: {
        tomato: 'Tomato sauce',
        creamMushroom: 'Cream Mushroom sauce',
        pink: 'Pink sauce',
        pesto: ' Pesto sauce',
        creamCheddar: 'Cream cheddar cheese sauce',
        bechamel: 'Bechamel sauce',
        bolognaise: 'Bolognaise sauce',
        carbonara: 'Carbonara sauce',
        creamChlantro: 'Cream chlantro sauce',
      },
    },
    protein: {
      chicken: {
        jerk: 'Jerk chicken grill',
        tandoori: 'Tandoori chicken grill',
        limeOrLemon: 'Lime or lemon cilantro chicken',
        almond: 'Almond spicy chicken',
        tikka: 'Tikka chicken',
        honey: 'Honey mustard chicken',
        lemonOregano: 'Lemon oregano chicken',
        kabab: 'Chicken kabab',
        buffalo: 'Buffalo chicken',
        butter: 'Butter chicken',
        spinach: 'Spinach mushroom chicken',
        biriyani: 'Biriyani chicken',
        lemon: 'Lemon chicken',
        stew: 'Chicken stew',
        teriyaki: 'Teriyaki chicken',
        mushroom: 'Chicken mushroom',
        marinara: 'Chicken marinara',
        kabasa: 'Kabsa chicken',
        majoran: 'Chicken majoran',
        orange: 'Orange lemon chicken',
        curry: 'Curry yogurt chicken',
      },
      whiteFish: {
        spicy: 'Spicy lemon fish',
        kabasa: 'Fish kabasa',
        rosemary: 'Rosemary fish',
      },
      salmon: {
        lemon: 'Lemon ginger salmon',
        honey: 'Honey mustrad salmon',
        rosemary: 'Lemon rosemary salmon',
        pesto: 'Pesto salmon',
        nuts: 'Nuts crusted salmon',
      },
      beef: {
        meatBowl: 'Meatbowl beef',
        mangolion: 'Mangolion beef',
        bolognaise: 'Beef bolognaise',
        korean: 'Korean micnemeat beef',
        stew: 'Beef stew',
        steak: 'Beef steak',
      },
    },
    sides: {
      salad: {
        mix: 'Mix salad',
        turkey: 'Turkey',
        walnut: 'Walnut',
        wild: 'Wild rice',
        yoghurt: 'Yoghurt',
        asparagus: 'Fresh asparagus',
      },
      vegetable: {
        steam: 'Steam vegetable',
        grill: 'Grill vegetable',
        fresh: 'Fresh vegetable',
        stew: 'Vegetable stew',
        sautVeg: 'Sauteed vegetable',
        sautMush: 'Sauteed mushrooms',
        sautSpin: 'Sauteed spinach',
        sautKale: 'Sauteed kale with garlic',
      },
    },
  },
  en: {
    dietlicious: 'Dietlicious',
    signup: 'Sign Up',
    signin: 'Sign In',
    fullname: 'Full name',
    email: 'Email',
    phone: 'Phone',
    password: 'Password',
    welcome: 'Welcome',
    to: 'to',
    next: 'Next',
    forgotyourpassword: 'Forgot your password?',
    required: 'required',
    start: 'Start',
    address: 'Address',
    firstAddress: 'First address',
    secondAddress: 'Second address',
    zip: 'Zipcode',
    region: 'Region',
    optional: 'optional',
    pickThisAddress: 'Pick this address',
    noticePickingAddress:
      'Please choose an address then click on "Pick this address" to confirm your choice',
    back: 'Back',
    home: 'Home',
    comingOrders: 'Coming orders',
    settings: 'Settings',
    days: {
      0: 'Sun',
      1: 'Mon',
      2: 'Tue',
      3: 'Wed',
      4: 'Thu',
      5: 'Fri',
      6: 'Sat',
    },
    gmt: 'GMT',
    meals: 'Meals',
    snacks: 'Snacks',
    selectMSTooltip: 'Select your meals and snacks above',
    previousOrders: 'Previous orders',
    addressMandatory: 'Please select your address',
    Breakfast: 'Breakfast',
    'Snacks & Soups': 'Snack & Soups',
    'Lunch & Dinner': 'Lunch & Dinner',
    deletingMealTitle: 'Removing Meal',
    deletingMealMsg: 'Are you sure you want to remove ',
    ok: 'OK',
    cancel: 'Cancel',
    packageTypes: {
      WL: 'Weight Lose',
      WW: 'Weight Watchers',
      WG: 'Weight Gainers',
    },
    daysString: 'days',
    remaining: 'remaining',
    calories: 'Calories',
    daysOff: 'Days-off',
    packages: 'Packages',
    birthday: 'Birthday',
    notes: 'Notes',
    resetPwMsg:
      'If you feel your password has been compromised, you can change it by clicking on the button below',
    lorem:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor',
    resetPw: 'Reset password',
    account: 'Account',
    saveChanges: 'Save changes',
    confirm: 'Confirm',
    birthdayPickerPlaceholder: 'Enter your birthday',
    language: 'Language',
    zone: 'zone',
    selectYourZone: 'Select your zone',
    selectYourZoneNotice: 'Please select your zone for faster delivery',
    selectYourNewPackage: 'Select your new package',
    dietType: 'Diet type',
    nbrOfMealsPerDay: 'Nbr of meals/day',
    nbrOfSnacksPerDay: 'Nbr of snacks/day',
    nbrOfDaysPerMonth: 'Nbr of days/month',
    day: 'days',
    subscribe: 'Subscribe',
    selectYourMeals: 'Select your meals',
    price: 'Price',
    remove: 'Remove',
    finalize: 'Finalize',
    payment: 'Payment',
    paymentAlertTitle: 'Confirmation alert',
    paymentAlertMsg: 'Are you sure you want to cancel payment?',
    yes: 'Yes',
    removedIng: 'Removed ingredients',
    supplements: 'Supplements',
    maximumSelection: 'You have reached your maximum selection',
    items: 'Items',
    purchasing: 'Purchasing',
    currency: 'AED',
    package: 'Package',
    orderSummary: 'Order summary',
    requiredField: 'Missing required field',
    invalidEmail: 'Invalid email address',
    invalidPhone: 'Invalid phone number',
    invalidPassword: 'Password must contain at least 6 characters',
    pwResetMsg: 'An email has been sent to you to reset your password',
    requiredEmail: 'You must enter your email address',
    sessionExpired: 'Your session has expired',
    successUpdate: 'Successfully updated your profile',
    areYouSureAlertTitle: 'Are you sure you want to continue?',
    resetPwAlertMsg:
      'By clicking on "Yes", you will receive a link on your email address to reset your password and you\'ll be directly signed out from the app',
    comingOrderNotice:
      'Refresh your meals status by clicking on the green "Refresh" button below',
    refresh: 'Refresh',
    cannotSelectMore: 'You cannot select more days off',
    selectYourDaysOff: 'Select your days off',
    langChangedAlertMsg:
      'You have modified the language. To apply this modification, the application needs to be restarted',
    firstSubsMsg: 'Proceed to your first subscription',
    Cooked: 'Cooked',
    Preparing: 'Preparing',
    Delivered: 'Delivered',
    defaultIngredients: 'Default ingredients',
    suppIngredients: 'Supplements',
    errorPayment: 'Error while trying to proceed your payment',
    transactionMessages: {
      2000: 'Order created successfully',
      2999: 'Error while creating order',
      3000: 'Payment created successfully',
      3999: 'Error while creating payment',
    },
    total: 'Total',
    deliveryTime: 'Delivery time',
    checkTransaction:
      "Check your transaction status by clicking here to refresh your meal's list",
    requestProcessedMsg: 'Your request is being processed...',
    proceed: 'Proceed',
    pause: 'Pause',
    unpause: 'Unpause',
    rate: 'Rate',
    noteNoticeMsg: "Leave a note to our chef! he's a good listener",
    languageNotice:
      'For you to enjoy the best dietlicious experience, please select your favourite language',
    ratingNotice: 'We value your feedback as much as the taste of our meals',
    dayMealsNotice1:
      'Choose your meals wisely, what is ordered cannot be unordered',
    dayMealsNotice2: 'Meal count includes breakfast, lunch and dinner',
    dayMealsNotice3:
      "Can't make up your mind? we will select one breakfast and one lunch for you",
    orderSummaryNotice: 'Your order details are listed below',
    selectNewPackageNotice:
      'The package you select cannot be cancelled until the ends of its term',
    afterRatingNotice: 'Your review has been well received',
    paymentNotice:
      'By clicking on the button below, you will be redirected to our gateway to finalize the transaction',
    selectDayMealsAlertMsg:
      "You've selected your list of meals/snacks for this day, this cannot be changed after validating this order.",
    clickHereToRefresh: "Click here to refresh your order's list",
    formErrors: {
      accepted: `Le champ doit être accepté.`,
      after: `Le champ doit être après :date.`,
      after_or_equal: `Le champ doit correspondre ou bien être après :date.`,
      alpha: `Le champ ne peut contenir que des lettres.`,
      alpha_space: `Le champ ne peut contenir que des lettres et des espaces.`,
      alpha_num: `Le champ ne peut contenir que des lettres et des chiffres.`,
      alpha_num_space: `Le champ ne peut contenir que des lettres, chiffres, et espaces.`,
      alpha_num_dash: `Le champ ne peut contenir que des lettres, chiffres, et tirets.`,
      alpha_num_dash_space: `Le champ ne peut contenir que des lettres, chiffres, tirets, et espaces.`,
      array: `Le champ doit êttre un tableau.`,
      before: `Le champ doit être avant :date.`,
      before_or_equal: `Le champ doit correspondre ou bien être avant  :date.`,
      between: `Le champ doit être entre :min et :max:type.`,
      boolean: `Le champ doit être booléen.`,
      card_exp: `Le champ doit être une date d'expiration valide.`,
      card_num: `Le champ doit être un numéro valide de carte de crédit .`,
      currency: `Le champ doit être une devise valide.`,
      date: `Le champ doit être une date.`,
      date_equals: `Le champ doit correspondre à :date.`,
      email: `Le champ doit êre une adresse email valide.`,
      in: `Le champ selectionné doit être :values.`,
      integer: `Le champ doit être un entier.`,
      max: `Le champ ne doit pas dépasser :max:type.`,
      min: `Le champ doit au moins être :min:type.`,
      not_in: `Le champ selectionné ne doit pas être :values.`,
      not_regex: `Le champ ne doit pas correspondre au motif requis.`,
      numeric: `Le champ doit être un chiffre.`,
      phone: `Le champ doit être un numéro de téléphone valide.`,
      regex: `Le champ doit correspondre au motif requis.`,
      required: `Le champ est requis.`,
      requiredAutocomplete: `Veuillez selectionner une valeur de la liste`,
      size: `Le champ doit être :size:type.`,
      string: `Le champ doit être une chaîne.`,
      typeof: `Le champ n'est pas le type correct de :type.`,
      url: `Le champ doit être un url.`,
      passwordConfirm: 'Veuillez entrer le même mot de passe',
    },
    networkError:
      'It appears that you are offline, please check your internet connection.',
    profile: 'Profile',
    genders: [
      { label: 'Male', value: 'M' },
      { label: 'Female', value: 'F' },
    ],
    callUs: 'Call us',
    endsIn: 'Ends in',
    checkout: 'Checkout',
    discount: 'Discount code',
    calendar: {
      off: 'off',
      paused: 'paused',
      mealsSelected: 'meals selected',
      prepare: 'prepare',
      rate: 'rate',
      delivered: 'delivered',
      choose: 'choose',
      dayNamesShort: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    },
    help: 'Help',
    selected: 'Selected',
    of: 'of',
    protein: 'Protein',
    carbs: 'Carbs',
    Fat: 'Fat',
    grams: 'g',
    caloriesShort: 'Cal',
    cannotSelectMoreMeals: 'You cannot select more meals',
    cannotSelectMoreSnacks: 'You cannot select more snacks',
    addYourMeal: 'Add your meal',
    for: 'for',
    buildYourMealSectionTitles: {
      carbs: 'Carbohydrates',
      rice: 'Rice',
      potato: 'Potato',
      pasta: 'Pasta',
      sauce: 'Pasta sauce',
      protein: 'Protein',
      chicken: 'Chicken',
      fish: 'White fish',
      salmon: 'Salmon',
      beef: 'Beef',
      sides: 'Side items',
      salad: 'Salad / Nuts',
      vegetable: 'Vegetable',
    },
    buildYourMeal: {
      carbohydrtes: {
        rice: {
          kabsa: 'Kabsa rice',
          sticky: 'Sticky rice',
          brown: 'Brown rice',
          majoobs: 'Majoobs rice',
          birivani: 'Birivani rice',
          frikeh: 'Frikeh (Freeekeh)',
          quinoa: 'Quinoa',
          couscous: 'Couscous',
          plain: 'Plain rice',
          lemon: 'Lemon rice',
        },
        potato: {
          mash: 'Mash potato',
          cream: 'Cream cheese mash potato',
          sweetCorn: 'Sweet corn green beans mash potato',
          grill: 'Grill potato wedges',
          gratin: 'Potato grating (Dauphinoise)',
          pineNut: 'Pine nut potato',
          wholeGrain: 'Whole grain mustrad mash potato',
          roast: 'Roast potato',
          baby: 'Baby potato',
          lemon: 'Lemon ross marry potato',
          grilSweet: 'Gril sweet potato',
          sweet: 'Sweet potato mash',
          tikka: 'Tikka potato',
          new: 'New potato',
        },
        pasta: {
          wholePenne: 'Whole wheat penne',
          wholeSpag: 'Whole wheat spaghetti',
          penne: 'Penne',
          spag: 'Spaghetti',
          farfalle: 'Farfalle (Bow tie)',
          fettuccine: 'Fettucine',
          Fusilli: 'Fusilli',
        },
        pastaSauce: {
          tomato: 'Tomato sauce',
          creamMushroom: 'Cream Mushroom sauce',
          pink: 'Pink sauce',
          pesto: ' Pesto sauce',
          creamCheddar: 'Cream cheddar cheese sauce',
          bechamel: 'Bechamel sauce',
          bolognaise: 'Bolognaise sauce',
          carbonara: 'Carbonara sauce',
          creamChlantro: 'Cream chlantro sauce',
        },
      },
      protein: {
        chicken: {
          jerk: 'Jerk chicken grill',
          tandoori: 'Tandoori chicken grill',
          limeOrLemon: 'Lime or lemon cilantro chicken',
          almond: 'Almond spicy chicken',
          tikka: 'Tikka chicken',
          honey: 'Honey mustard chicken',
          lemonOregano: 'Lemon oregano chicken',
          kabab: 'Chicken kabab',
          buffalo: 'Buffalo chicken',
          butter: 'Butter chicken',
          spinach: 'Spinach mushroom chicken',
          biriyani: 'Biriyani chicken',
          lemon: 'Lemon chicken',
          stew: 'Chicken stew',
          teriyaki: 'Teriyaki chicken',
          mushroom: 'Chicken mushroom',
          marinara: 'Chicken marinara',
          kabasa: 'Kabsa chicken',
          majoran: 'Chicken majoran',
          orange: 'Orange lemon chicken',
          curry: 'Curry yogurt chicken',
        },
        whiteFish: {
          spicy: 'Spicy lemon fish',
          kabasa: 'Fish kabasa',
          rosemary: 'Rosemary fish',
        },
        salmon: {
          lemon: 'Lemon ginger salmon',
          honey: 'Honey mustrad salmon',
          rosemary: 'Lemon rosemary salmon',
          pesto: 'Pesto salmon',
          nuts: 'Nuts crusted salmon',
        },
        beef: {
          meatBowl: 'Meatbowl beef',
          mangolion: 'Mangolion beef',
          bolognaise: 'Beef bolognaise',
          korean: 'Korean micnemeat beef',
          stew: 'Beef stew',
          steak: 'Beef steak',
        },
      },
      sides: {
        salad: {
          mix: 'Mix salad',
          turkey: 'Turkey',
          walnut: 'Walnut',
          wild: 'Wild rice',
          yoghurt: 'Yoghurt',
          asparagus: 'Fresh asparagus',
        },
        vegetable: {
          steam: 'Steam vegetable',
          grill: 'Grill vegetable',
          fresh: 'Fresh vegetable',
          stew: 'Vegetable stew',
          sautVeg: 'Sauteed vegetable',
          sautMush: 'Sauteed mushrooms',
          sautSpin: 'Sauteed spinach',
          sautKale: 'Sauteed kale with garlic',
        },
      },
    },
    calendarHelpNotice:
      "1. Choose your Meals 48 Hours earlier. If you didn't, the system will choose for you.\n2. Meals being delivered in the night before its consumption.\n3. We do NOT prepare meals to be eatin in Firday, BUT we delivered each Friday night to be eaten in Saturday.\n4. Keep food chilled, use Microwave to heat up before eating.\n5. Enjoy eating it, then rate your meals using our App.",
    pauseThisDay: 'Pause this day',
    resumeThisDay: 'Resume this day',
    notRated: 'Not rated',
    tellUsMore: 'Tell us more (optional)',
    submit: 'Submit',
    upgradeYourPlan: 'Upgrade your plan',
    packagePrice: 'Package price',
    gainedFromOldPackage: 'Gained from old package',
    notEnoughUpgrade: 'Please select an upgrade over your existing package',
    priceAfterPromotion: 'Price after coupon',
  },
  ar: {
    dietlicious: 'دايتلسيوس',
    signup: 'تسجيل',
    signin: 'دخول',
    fullname: 'الاسم الكامل',
    email: 'البريد الإلكتروني',
    phone: 'الهاتف',
    password: 'كلمة السر',
    welcome: 'مرحبا بكم',
    to: 'في',
    next: 'التالى',
    forgotyourpassword: 'هل نسيتم كلمة السر؟',
    required: 'إجباري',
    start: 'بدأ',
    address: 'العنوان',
    firstAddress: 'العنوان الأول',
    secondAddress: 'العنوان الثاني',
    zip: 'الرمز البريدي',
    region: 'المنطقة',
    optional: 'اختياري',
    pickThisAddress: 'اختيار هذا العنوان',
    noticePickingAddress:
      "يرجى اختيار عنوان ثم الضغط على 'اختيار هذا العنوان' لتأكيد اختيارك",
    back: 'الى الخلف',
    home: 'الصفحة الرئيسية',
    comingOrders: 'الطلبات القادمة',
    settings: 'ضبط',
    days: {
      0: 'الأحد',
      1: 'الاثنين',
      2: 'الثلاثاء',
      3: 'الأربعاء',
      4: 'الخميس',
      5: 'الجمعة',
      6: 'السبت',
    },
    gmt: 'توقيت جرينتش',
    meals: 'وجبات الطعام',
    snacks: 'وجبات خفيفة',
    selectMSTooltip: 'حدد وجبات الطعام والوجبات الخفيفة أعلاه',
    previousOrders: 'الطلبات السابقة',
    addressMandatory: 'الرجاء اختيار عنوانك',
    Breakfast: 'وجبة افطار',
    'Snacks & Soups': 'وجبة خفيفة و شوربة',
    'Lunch & Dinner': 'غداء و عشاء',
    deletingMealTitle: 'حذف الوجبة',
    deletingMealMsg: 'هل أنت متأكد من ازالت ',
    ok: 'حسنا',
    cancel: 'إلغاء',
    packageTypes: {
      WL: 'برنامج تنزيل الوزن',
      WW: 'برنامج المحافظة على الوزن',
      WG: 'برنامج زيادة الوزن',
    },
    daysString: 'أيام',
    remaining: 'متبقية',
    calories: 'سعرات الحرارية',
    daysOff: 'أيام العطلة',
    packages: 'الاشتراكات',
    birthday: 'عيد الميلاد',
    notes: 'ملاحظات',
    resetPwMsg:
      'إذا كنت تشعر أن كلمة المرور الخاصة بك قد تم اختراقها ، فيمكنك تغييرها بالنقر فوق الزر أدناه',
    lorem:
      'بلاده الذود وايرلندا عل تحت, في فقد استعملت البولندي بريطانيا،. ما كلّ لإعلان وانهاء للإتحاد. حصدت الجوي أعمال عن يتم, بالعمل التبرعات قد وتم, فصل ان تعداد لإنعدام العمليات',
    resetPw: 'إعادة تعيين كلمة المرور',
    account: 'الحساب',
    saveChanges: 'حفظ التغييرات',
    confirm: 'تأكيد',
    birthdayPickerPlaceholder: 'أدخل عيد ميلادك',
    language: 'اللغة',
    zone: 'المنطقة',
    selectYourZone: 'حدد منطقتك',
    selectYourZoneNotice: 'يرجى اختيار منطقتك لتسليم أسرع',
    selectYourNewPackage: 'حدد اشتراكك الجديد',
    dietType: 'نوع النظام الغذائي',
    nbrOfMealsPerDay: 'عدد وجبات الطعام/اليوم',
    nbrOfSnacksPerDay: 'عدد الوجبات الخفيفة/اليوم',
    nbrOfDaysPerMonth: 'عدد الأيام/الشهر',
    day: 'يوم',
    subscribe: 'اشتراك',
    selectYourMeals: 'اختر وجبتك',
    price: 'السعر',
    remove: 'إزالة',
    finalize: 'إنهاء',
    payment: 'دفع',
    paymentAlertTitle: 'تنبيه تأكيد',
    paymentAlertMsg: 'هل أنت متأكد من أنك تريد إلغاء الدفع؟',
    yes: 'نعم',
    removedIng: 'المكونات المزالة',
    supplements: 'المكملات',
    maximumSelection: 'لقد وصلت إلى الحد الأقصى لاختيارك',
    items: 'العناصر',
    purchasing: 'الشراء',
    currency: 'درهم إماراتي',
    package: 'الصفقة',
    orderSummary: 'ملخص الطلب',
    requiredField: 'حقل مطلوب مفقود',
    invalidEmail: 'عنوان البريد الإلكتروني غير صالح',
    invalidPhone: 'رقم الهاتف غير صحيح',
    invalidPassword: 'يجب أن تحتوي كلمة المرور على 6 أحرف على الأقل',
    pwResetMsg:
      'تم إرسال بريد إلكتروني إليك لإعادة تعيين كلمة المرور الخاصة بك',
    requiredEmail: 'يجب عليك ادخال بريدك الالكتروني',
    sessionExpired: 'انتهت جلستك',
    successUpdate: 'تم تجديد حسابك بنجاح',
    areYouSureAlertTitle: 'هل أنت متأكد أنك تريد المتابعة؟',
    resetPwAlertMsg:
      'هل أنت متأكد أنك تريد إعادة تعيين كلمة المرور الخاصة بك؟ بالنقر فوق "نعم" ، ستتلقى رابطًا على عنوان بريدك الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك وسيتم تسجيل خروجك مباشرة من التطبيق',
    comingOrderNotice:
      'قم بتجديد وجبات الطعام الخاصة بك عن طريق النقر على "تحديث" الأخضر',
    refresh: 'تحديث',
    cannotSelectMore: 'لا يمكنك اختيار المزيد من أيام العطلة',
    selectYourDaysOff: 'حدد أيام العطلة الخاصة بك',
    langChangedAlertMsg:
      'لقد قمت بتعديل اللغة. لتنفيذ هذا التعديل ، يجب إعادة تشغيل التطبيق',
    firstSubsMsg: 'قم باشتراكك الأول',
    Cooked: 'مطبوخة',
    Preparing: 'في طور التحضير',
    Delivered: 'سلم',
    defaultIngredients: 'المكونات الافتراضية',
    suppIngredients: 'المكملات',
    errorPayment: 'خطأ أثناء محاولة متابعة دفعتك',
    transactionMessages: {
      2000: 'تم إنشاء الطلب بنجاح',
      2999: 'خطأ أثناء إنشاء النظام',
      3000: 'تم إنشاء الدفع بنجاح',
      3999: 'خطأ أثناء إنشاء الدفع',
    },
    total: 'المجموع',
    deliveryTime: 'موعد التسليم',
    checkTransaction: 'تحقق من حالة معاملتك بالنقر هنا لتحديث قائمة وجباتك',
    requestProcessedMsg: 'يتم معالجة طلبك...',
    proceed: 'تقدم',
    pause: 'إيقاف',
    unpause: 'تشغيل',
    rate: 'تقيم',
    noteNoticeMsg: 'أترك ملاحظة للطاهي لدينا! انه مستمع جيد',
    languageNotice: 'لتستمتع بأفضل تجربة غذائية ، يرجى اختيار لغتك المفضلة',
    ratingNotice: 'نحن نقدر ملاحظاتك بقدر طعم وجباتنا',
    dayMealsNotice1: 'اختر وجباتك بحكمة ، ما هو مطلوب لا يمكن أن يؤمر',
    dayMealsNotice2: 'عدد الوجبات يشمل الإفطار والغداء والعشاء',
    dayMealsNotice3:
      'لا تستطيع تعويض عقلك؟ سنختار وجبة إفطار واحدة ووجبة غداء واحدة لك',
    orderSummaryNotice: 'تفاصيل طلبك مدرجة أدناه',
    selectNewPackageNotice: 'لا يمكن إلغاء الاشتراك حتى نهايته',
    afterRatingNotice: 'لقد تم تلقي تقيمك',
    paymentNotice:
      'بالنقر على الزر أدناه ، سيتم إعادة توجيهك إلى بوابتنا للانتهاء من المعاملة',
    selectDayMealsAlertMsg:
      'لقد حددت قائمة الوجبات / الوجبات الخفيفة لهذا اليوم ، وهذا لا يمكن تغييره بعد التحقق من صحة هذا الطلب',
    clickHereToRefresh: 'انقر هنا لتحديث قائمة الطلب الخاصة بك',
    formErrors: {
      accepted: `Le champ doit être accepté.`,
      after: `Le champ doit être après :date.`,
      after_or_equal: `Le champ doit correspondre ou bien être après :date.`,
      alpha: `Le champ ne peut contenir que des lettres.`,
      alpha_space: `Le champ ne peut contenir que des lettres et des espaces.`,
      alpha_num: `Le champ ne peut contenir que des lettres et des chiffres.`,
      alpha_num_space: `Le champ ne peut contenir que des lettres, chiffres, et espaces.`,
      alpha_num_dash: `Le champ ne peut contenir que des lettres, chiffres, et tirets.`,
      alpha_num_dash_space: `Le champ ne peut contenir que des lettres, chiffres, tirets, et espaces.`,
      array: `Le champ doit êttre un tableau.`,
      before: `Le champ doit être avant :date.`,
      before_or_equal: `Le champ doit correspondre ou bien être avant  :date.`,
      between: `Le champ doit être entre :min et :max:type.`,
      boolean: `Le champ doit être booléen.`,
      card_exp: `Le champ doit être une date d'expiration valide.`,
      card_num: `Le champ doit être un numéro valide de carte de crédit .`,
      currency: `Le champ doit être une devise valide.`,
      date: `Le champ doit être une date.`,
      date_equals: `Le champ doit correspondre à :date.`,
      email: `Le champ doit êre une adresse email valide.`,
      in: `Le champ selectionné doit être :values.`,
      integer: `Le champ doit être un entier.`,
      max: `Le champ ne doit pas dépasser :max:type.`,
      min: `Le champ doit au moins être :min:type.`,
      not_in: `Le champ selectionné ne doit pas être :values.`,
      not_regex: `Le champ ne doit pas correspondre au motif requis.`,
      numeric: `Le champ doit être un chiffre.`,
      phone: `Le champ doit être un numéro de téléphone valide.`,
      regex: `Le champ doit correspondre au motif requis.`,
      required: `Le champ est requis.`,
      requiredAutocomplete: `Veuillez selectionner une valeur de la liste`,
      size: `Le champ doit être :size:type.`,
      string: `Le champ doit être une chaîne.`,
      typeof: `Le champ n'est pas le type correct de :type.`,
      url: `Le champ doit être un url.`,
      passwordConfirm: 'Veuillez entrer le même mot de passe',
    },
    networkError:
      'It appears that you are offline, please check your internet connection.',
    profile: 'Profile',
    genders: [
      { label: 'Male', value: 'M' },
      { label: 'Female', value: 'F' },
    ],
    callUs: 'Call us',
    endsIn: 'Ends in',
    checkout: 'Checkout',
    discount: 'Discount code',
    calendar: {
      off: 'off',
      paused: 'paused',
      mealsSelected: 'meals selected',
      prepare: 'prepare',
      rate: 'rate',
      delivered: 'delivered',
      choose: 'choose',
      dayNamesShort: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
    },
    help: 'Help',
    selected: 'Selected',
    of: 'of',
    protein: 'Protein',
    carbs: 'Carbs',
    Fat: 'Fat',
    grams: 'g',
    caloriesShort: 'Cal',
    cannotSelectMoreMeals: 'You cannot select more meals',
    cannotSelectMoreSnacks: 'You cannot select more snacks',
    addYourMeal: 'Add your meal',
    for: 'for',
    buildYourMealSectionTitles: {
      carbs: 'Carbohydrtes',
      rice: 'Rice',
      potato: 'Potato',
      pasta: 'Pasta',
      sauce: 'Pasta sauce',
      protein: 'Protein',
      chicken: 'chicken',
      fish: 'White fish',
      salmon: 'Salmon',
      beef: 'Beef',
      sides: 'Side items',
      salad: 'Salad / Nuts',
      vegetable: 'Vegetable',
    },
    buildYourMeal: {
      carbohydrtes: {
        rice: {
          kabsa: 'Kabsa rice',
          sticky: 'Sticky rice',
          brown: 'Brown rice',
          majoobs: 'Majoobs rice',
          birivani: 'Birivani rice',
          frikeh: 'Frikeh (Freeekeh)',
          quinoa: 'Quinoa',
          couscous: 'Couscous',
          plain: 'Plain rice',
          lemon: 'Lemon rice',
        },
        potato: {
          mash: 'Mash potato',
          cream: 'Cream cheese mash potato',
          sweetCorn: 'Sweet corn green beans mash potato',
          grill: 'Grill potato wedges',
          gratin: 'Potato grating (Dauphinoise)',
          pineNut: 'Pine nut potato',
          wholeGrain: 'Whole grain mustrad mash potato',
          roast: 'Roast potato',
          baby: 'Baby potato',
          lemon: 'Lemon ross marry potato',
          grilSweet: 'Gril sweet potato',
          sweet: 'Sweet potato mash',
          tikka: 'Tikka potato',
          new: 'New potato',
        },
        pasta: {
          wholePenne: 'Whole wheat penne',
          wholeSpag: 'Whole wheat spaghetti',
          penne: 'Penne',
          spag: 'Spaghetti',
          farfalle: 'Farfalle (Bow tie)',
          fettuccine: 'Fettucine',
          Fusilli: 'Fusilli',
        },
        pastaSauce: {
          tomato: 'Tomato sauce',
          creamMushroom: 'Cream Mushroom sauce',
          pink: 'Pink sauce',
          pesto: ' Pesto sauce',
          creamCheddar: 'Cream cheddar cheese sauce',
          bechamel: 'Bechamel sauce',
          bolognaise: 'Bolognaise sauce',
          carbonara: 'Carbonara sauce',
          creamChlantro: 'Cream chlantro sauce',
        },
      },
      protein: {
        chicken: {
          jerk: 'Jerk chicken grill',
          tandoori: 'Tandoori chicken grill',
          limeOrLemon: 'Lime or lemon cilantro chicken',
          almond: 'Almond spicy chicken',
          tikka: 'Tikka chicken',
          honey: 'Honey mustard chicken',
          lemonOregano: 'Lemon oregano chicken',
          kabab: 'Chicken kabab',
          buffalo: 'Buffalo chicken',
          butter: 'Butter chicken',
          spinach: 'Spinach mushroom chicken',
          biriyani: 'Biriyani chicken',
          lemon: 'Lemon chicken',
          stew: 'Chicken stew',
          teriyaki: 'Teriyaki chicken',
          mushroom: 'Chicken mushroom',
          marinara: 'Chicken marinara',
          kabasa: 'Kabsa chicken',
          majoran: 'Chicken majoran',
          orange: 'Orange lemon chicken',
          curry: 'Curry yogurt chicken',
        },
        whiteFish: {
          spicy: 'Spicy lemon fish',
          kabasa: 'Fish kabasa',
          rosemary: 'Rosemary fish',
        },
        salmon: {
          lemon: 'Lemon ginger salmon',
          honey: 'Honey mustrad salmon',
          rosemary: 'Lemon rosemary salmon',
          pesto: 'Pesto salmon',
          nuts: 'Nuts crusted salmon',
        },
        beef: {
          meatBowl: 'Meatbowl beef',
          mangolion: 'Mangolion beef',
          bolognaise: 'Beef bolognaise',
          korean: 'Korean micnemeat beef',
          stew: 'Beef stew',
          steak: 'Beef steak',
        },
      },
      sides: {
        salad: {
          mix: 'Mix salad',
          turkey: 'Turkey',
          walnut: 'Walnut',
          wild: 'Wild rice',
          yoghurt: 'Yoghurt',
          asparagus: 'Fresh asparagus',
        },
        vegetable: {
          steam: 'Steam vegetable',
          grill: 'Grill vegetable',
          fresh: 'Fresh vegetable',
          stew: 'Vegetable stew',
          sautVeg: 'Sauteed vegetable',
          sautMush: 'Sauteed mushrooms',
          sautSpin: 'Sauteed spinach',
          sautKale: 'Sauteed kale with garlic',
        },
      },
    },
    calendarHelpNotice:
      "1. Choose your Meals 48 Hours earlier. If you didn't, the system will choose for you.\n2. Meals being delivered in the night before its consumption.\n3. We do NOT prepare meals to be eatin in Firdya; BUT we delivered each Friday night to be eaten in Saturday.\n4. Keep food chilled, use Microwave to heat up before eating.\n5. Enjoy eating it, then rate your meals using our App.",
    pauseThisDay: 'Pause this day',
    resumeThisDay: 'Resume this day',
    notRated: 'Not rated',
    tellUsMore: 'Tell us more (optional)',
    submit: 'Submit',
    upgradeYourPlan: 'Upgrade your plan',
    packagePrice: 'Package price',
    gainedFromOldPackage: 'Gained from old package',
    notEnoughUpgrade: 'Please select an upgrade over your existing package',
    priceAfterPromotion: 'Price after coupon',
  },
};
