/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import CustomMealCard from 'components/CustomMealCard';
import MealCard from 'components/MealCard';
import RatingModal from 'pages/RateMeal/RatingModal';
import { setRating } from 'actions/Rating';
import { useDispatch } from 'react-redux';
import { useMenuPerDate } from 'hooks/useMenuPerDate';
import { useRouteMatch } from 'react-router-dom';

const RateMeal = () => {
  const {
    params: { date },
  } = useRouteMatch();
  const dispatch = useDispatch();
  const { allMeals, customMeals, snacks } = useMenuPerDate(date);
  const [openModal, setOpenModal] = useState(false);
  const [mealId, setMealId] = useState();
  const handleSubmit = (value, _mealId) => () => {
    dispatch(setRating(value, _mealId));
    setOpenModal(false);
  };
  const cancel = () => {
    setOpenModal(false);
  };
  const handleOpening = id => () => {
    setMealId(id);
    setOpenModal(true);
  };

  return (
    <div className="container space-top-3">
      <h3 className="h3">Your Meals:</h3>
      <div className="container">
        <div className="card-sm-columns card-sm-2-count card-lg-3-count">
          {customMeals &&
            customMeals.length > 0 &&
            customMeals.map(customMeal => (
              <CustomMealCard customMeal={customMeal} />
            ))}
        </div>
        <div className="card-sm-columns card-sm-2-count card-lg-3-count">
          {allMeals &&
            allMeals.map(
              meal =>
                meal.type === 'MEAL' && (
                  <MealCard
                    isMenuCard="Rate"
                    meal={meal}
                    key={meal.id}
                    onClick={handleOpening(meal.id)}
                  />
                ),
            )}
        </div>
      </div>
      <h3 className="h3">Your Snacks:</h3>
      <div className="container">
        <div className="card-sm-columns card-sm-2-count card-lg-3-count">
          {snacks &&
            snacks.map(snack => (
              <MealCard
                isMenuCard="Rated"
                meal={snack}
                key={snack.id}
                onClick={handleOpening(snack.id)}
              />
            ))}
        </div>
      </div>
      <RatingModal
        openModal={openModal}
        handleSubmit={handleSubmit}
        cancel={cancel}
        mealId={mealId}
      />
    </div>
  );
};

export default RateMeal;
