/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { addPausedDays } from 'actions/User';
import { fetchOneMenu } from 'actions/Menu';
import { selectMealsSnacks } from 'actions/Meal';
import { useHistory } from 'react-router-dom';

export const useMenuPerDateHeader = (
  date,
  selectedSnacks,
  selectedMeals,
  selectedJuices,
) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector(({ userReducer }) => userReducer.user);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchOneMenu(date));
  }, []);

  const addPaused = () => {
    dispatch(addPausedDays(user, date, history, TextTrackCueList));
  };
  const RemovePaused = () => {
    dispatch(addPausedDays(user, date, history, false));
  };
  const handleSubmit = () => {
    dispatch(
      selectMealsSnacks(
        selectedSnacks,
        selectedMeals,
        selectedJuices,
        date,
        history,
      ),
    );
  };
  const cancel = () => {
    setIsOpen(false);
  };

  const handleConfirmSelection = () => {
    setIsOpen(true);
  };

  return {
    isOpen,
    addPaused,
    RemovePaused,
    handleSubmit,
    cancel,
    handleConfirmSelection,
  };
};
