import { Field } from 'redux-form';
import Map from 'components/Map';
import PropTypes from 'prop-types';
import React from 'react';

const MapField = ({ name, validate, label, getAddress }) => {
  return (
    <Field
      name={name}
      id={name}
      component={Map}
      validate={validate}
      label={label}
      getAddress={getAddress}
    />
  );
};

MapField.propTypes = {
  name: PropTypes.string.isRequired,
  validate: PropTypes.array,
  label: PropTypes.string,
  getAddress: PropTypes.func,
};

export default MapField;
