/* eslint-disable no-extra-parens */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  fetchAllMeals,
  setCustomMeals,
  setCustomBreakfasts,
  setBreakfasts,
  setMeals,
  setSnacks,
  setJuices,
} from 'actions/Meal';

import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

export const useMenuPerDate = date => {
  const dispatch = useDispatch();
  const history = useHistory();

  const customMeals = useSelector(({ meals }) => meals.customMeals[date]);
  const customBreakfasts = useSelector(({ meals }) => {
    return meals.customBreakfasts[date];
  });

  const allMeals = useSelector(({ meals }) => meals.allMeals[date]);
  const snacks = useSelector(({ meals }) => meals.snacks[date]);
  const juices = useSelector(({ meals }) => meals.juices[date]);
  const breakfasts = useSelector(({ meals }) => meals.breakfasts[date]);

  const currentPlan = useSelector(({ userReducer }) => userReducer.currentPlan);
  const { mealsToHide } = useSelector(({ meals, menus }) => {
    if (!meals.availableMeals.length)
      return { mealsToHide: [], mealsToShow: [] };
    const hiddenMeals = menus?.selectedMenu?.hiddenMeals || [];
    const { availableMeals } = meals;
    const _mealsToHide = [];
    const _mealsToShow = [];

    availableMeals.map(meal => {
      if (hiddenMeals.includes(meal.id)) _mealsToShow.push(meal);
      else _mealsToHide.push(meal);

      return false;
    });

    return { mealsToHide: _mealsToHide, mealsToShow: _mealsToShow };
  });

  const [selectedSnacks, setSelectedSnacks] = useState([]);
  const [selectedMeals, setSelectedMeals] = useState([]);
  const [selectedBreakfasts, setSelectedBreakfasts] = useState([]);
  const [selectedJuices, setSelectedJuices] = useState([]);

  useEffect(() => {
    dispatch(fetchAllMeals());
  }, []);

  useEffect(() => {
    setSelectedMeals(
      [
        ...(customMeals || []),
        ...(customBreakfasts || []),
        ...(allMeals || []),
      ] || [],
    );
  }, [allMeals && allMeals.length, customMeals && customMeals.length]);
  useEffect(() => {
    setSelectedSnacks([...(snacks || [])] || []);
  }, [snacks && snacks.length]);

  useEffect(
    () => {
      setSelectedBreakfasts(
        [...(breakfasts || []), ...(customBreakfasts || [])] || [],
      );
    },
    [breakfasts && breakfasts.length],
    customMeals && customMeals.length,
  );
  useEffect(() => {
    setSelectedSnacks([...(snacks || [])] || []);
  }, [snacks && snacks.length]);
  useEffect(() => {
    setSelectedJuices([...(juices || [])] || []);
  }, [juices && juices.length]);

  const selectedMealsNumber = selectedMeals.length + selectedBreakfasts.length;

  const selectMeal = meal => () => {
    if (selectedMealsNumber < currentPlan.nbMealsPerDay) {
      dispatch(setMeals(meal, true, date));
    }
  };

  const removeMeal = meal => () => {
    if (
      selectedMeals.length <= currentPlan.nbMealsPerDay &&
      selectedMeals.length > 0
    ) {
      dispatch(setMeals(meal, false, date));

      if (customMeals && customMeals.includes(meal)) {
        dispatch(setCustomMeals(meal, false, history, date));
      }
    }
  };
  const selectBreakfast = breakfast => () => {
    if (selectedMealsNumber < currentPlan.nbMealsPerDay) {
      dispatch(setBreakfasts(breakfast, true, date));
    }
  };

  const removeBreakfast = breakfast => () => {
    if (
      selectedBreakfasts.length <= currentPlan.nbMealsPerDay &&
      selectedBreakfasts.length > 0
    ) {
      dispatch(setBreakfasts(breakfast, false, date));

      if (customBreakfasts && customBreakfasts.includes(breakfast)) {
        dispatch(setCustomBreakfasts(breakfast, false, history, date));
      }
    }
  };

  const selectJuice = juice => () => {
    dispatch(setJuices(juice, true, date));
  };

  const removeJuice = juice => () => {
    if (selectedJuices.length > 0) {
      dispatch(setJuices(juice, false, date));
    }
  };
  const selectSnack = snack => () => {
    if (selectedSnacks.length < currentPlan.nbSnacksPerDay) {
      dispatch(setSnacks(snack, true, date));
    }
  };

  const removeSnack = snack => () => {
    if (
      selectedSnacks.length <= currentPlan.nbSnacksPerDay &&
      selectedSnacks.length > 0
    ) {
      dispatch(setSnacks(snack, false, date));
    }
  };

  return {
    currentPlan,
    selectedMeals,
    selectedSnacks,
    selectedBreakfasts,
    selectedJuices,
    mealsToHide,
    customMeals,
    customBreakfasts,
    selectJuice,
    removeJuice,
    removeMeal,
    selectBreakfast,
    selectSnack,
    removeSnack,
    selectMeal,
    removeBreakfast,
    setBreakfasts,
    allMeals,
    snacks,
  };
};
