import FirstRaisedView from 'components/FirstRaisedView';
import HomeFirstSection from 'components/HomeFirstSection';
import RaisedView from 'components/RaisedView';
import React from 'react';
import SecondRaisedView from 'components/SecondRaisedView';
import ThirdRaisedView from 'components/ThirdRaisedView';
import homePageStrings from 'constants/text/homePage';
import Footer from 'components/Footer';

const Home = () => {
  return (
    <div>
      <HomeFirstSection />
      <RaisedView className="main main-raised z-index-4 ">
        <FirstRaisedView
          list={homePageStrings.list}
          head={homePageStrings.firstHead}
        />
      </RaisedView>
      <RaisedView className="main main-raised mb-3 mt-9">
        <SecondRaisedView />
      </RaisedView>
      <RaisedView className="main main-raised mb-3 mt-9">
        <ThirdRaisedView />
      </RaisedView>
      <Footer />
    </div>
  );
};

export default Home;
