import RaisedView from 'components/RaisedView';
import React from 'react';
import SignInForm from 'containers/SignInForm';
import { signIn } from 'actions/User';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

const SignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = values => {
    dispatch(signIn(values, history));
  };

  return (
    <div>
      <RaisedView className="main main-raised z-index-4 ">
        <main id="content" role="main">
          <div className="d-flex align-items-center position-relative height-lg-100vh">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-3 space-lg-0">
                  <div className="mb-7">
                    <h2 className="h3 text-primary font-weight-normal mb-0">
                      Welcome
                      <span className="font-weight-semi-bold"> back</span>
                    </h2>
                    <p>Login to manage your account.</p>
                  </div>
                  <SignInForm onSubmit={handleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </main>
      </RaisedView>
    </div>
  );
};

export default SignIn;
