export default {
  accepted: 'The field must be accepted.',
  after: 'The field must be after :date.',
  after_or_equal: 'The field must be after or on :date.',
  alpha: 'The field may only contain letters.',
  alpha_space: 'The field may only contain letters and spaces.',
  alpha_num: 'The field may only contain letters and numbers.',
  alpha_num_space: 'The field may only contain letters, numbers, and spaces.',
  alpha_num_dash: 'The field may only contain letters, numbers, and dashes.',
  alpha_num_dash_space:
    'The field may only contain letters, numbers, dashes, and spaces.',
  array: 'The field must be an array.',
  before: 'The field must be before :date.',
  before_or_equal: 'The field must be before or on :date.',
  between: 'The field must be between :min and :max:type.',
  boolean: 'The field must be a boolean.',
  card_exp: 'The field must be a valid expiration date.',
  card_num: 'The field must be a valid credit card number.',
  currency: 'The field must be a valid currency.',
  date: 'The field must be a date.',
  date_equals: 'The field must be on :date.',
  email: 'The field must be a valid email address.',
  in: 'The selected field must be :values.',
  integer: 'The field must be an integer.',
  max: 'The field may not be greater than :max:type.',
  min: 'The field must be at least :min:type.',
  not_in: 'The selected field must not be :values.',
  not_regex: 'The field must not match the required pattern.',
  numeric: 'The field must be a number.',
  phone: 'The field must be a valid phone number.',
  regex: 'The field must match the required pattern.',
  required: 'The field is required.',
  size: 'The field must be :size:type.',
  string: 'The field must be a string.',
  typeof: 'The field is not the correct type of :type.',
  url: 'The field must be a url.',
};
