/* eslint-disable no-extra-parens */
/* eslint-disable no-case-declarations */
import {
  ALL_SELECTED_MEALS,
  CUSTOM_MEALS,
  FETCH_ALL_MEALS,
  FETCH_HOMEPAGE_MEALS,
  SELECTED_SNACKS,
  CUSTOM_BREAKFASTS,
  SELECTED_BREAKFASTS,
  SELECTED_JUICES,
} from 'constants/types/Meals';
import { FETCH_ORDERS } from 'constants/types/Orders';

const initialState = {
  availableMeals: [],
  homePageMeals: [],
  customMeals: {},
  allMeals: {},
  snacks: {},
  juices: {},
  customBreakfasts: {},
  breakfasts: {},
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ALL_MEALS:
      return { ...state, availableMeals: [...payload] };
    case FETCH_HOMEPAGE_MEALS:
      return { ...state, homePageMeals: [...payload] };
    case CUSTOM_MEALS:
      return { ...state, customMeals: { ...payload } };
    case CUSTOM_BREAKFASTS:
      return { ...state, customBreakfasts: { ...payload } };
    case ALL_SELECTED_MEALS:
      return { ...state, allMeals: { ...payload } };
    case SELECTED_JUICES:
      return { ...state, juices: { ...payload } };
    case SELECTED_BREAKFASTS:
      return { ...state, breakfasts: { ...payload } };
    case SELECTED_SNACKS:
      return { ...state, snacks: { ...payload } };
    case FETCH_ORDERS:
      const days = typeof payload === 'object' ? Object.keys(payload) : [];
      const snacks = {};

      days.map(day => {
        snacks[day] = [...(payload[day]?.snacks || [])] || [];

        return false;
      });
      const allMeals = {};

      days.map(day => {
        allMeals[day] =
          [
            ...(payload[day]?.meals?.filter(o => o.type !== 'CUSTOM_MEAL') ||
              []),
          ] || [];

        return false;
      });

      const customMeals = {};

      days.map(day => {
        customMeals[day] =
          [
            ...(payload[day]?.breakfasts?.filter(
              o => o.type === 'CUSTOM_MEAL',
            ) || []),
          ] || [];

        return false;
      });

      const breakfasts = {};

      const cutsomBreakfasts = {};

      days.map(day => {
        breakfasts[day] =
          [
            ...(payload[day]?.breakfasts?.filter(o => o.type === 'BREAKFAST') ||
              []),
          ] || [];

        return false;
      });

      days.map(day => {
        cutsomBreakfasts[day] =
          [
            ...(payload[day]?.breakfasts?.filter(
              o => o.type === 'CUSTOM_BREAKFAST',
            ) || []),
          ] || [];

        return false;
      });

      return {
        ...state,
        snacks,
        allMeals,
        customMeals,
        breakfasts,
        cutsomBreakfasts,
      };
    default:
      return state;
  }
};
