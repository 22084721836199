/**
 * Firebase Config
 */
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/auth';
import firebase from 'firebase/app';

const ENV = 'prod';

// import firebaseConfig from './firebaseConfig';

// Initialize Firebase

const firebaseConfig =
  ENV === 'prod'
    ? {
        apiKey: 'AIzaSyACYENkqiEFW2WF4LN8PmmxYJmzoLGv4ag',
        authDomain: 'dietlicious-restaurant.firebaseapp.com',
        databaseURL: 'https://dietlicious-restaurant.firebaseio.com',
        projectId: 'dietlicious-restaurant',
        storageBucket: 'dietlicious-restaurant.appspot.com',
        messagingSenderId: '86451209069',
        appId: '1:86451209069:web:7f98516a083416d5ec1064',
        measurementId: 'G-EGDCN3V5GY',
      }
    : {
        apiKey: 'AIzaSyCLP0rpyMBdyIXxwyR8TCxXcVpfrd6elqs',
        authDomain: 'dietliciousdeveloper.firebaseapp.com',
        databaseURL: 'https://dietliciousdeveloper.firebaseio.com',
        projectId: 'dietliciousdeveloper',
        storageBucket: 'dietliciousdeveloper.appspot.com',
        messagingSenderId: '506633133671',
        appId: '1:506633133671:web:7b7694428090249d8fbec2',
        measurementId: 'G-T6ZTGYL96Q',
      }; // Initialize Firebase

firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const storage = firebase.storage();
const database = firebase.database();
const firestore = firebase.firestore();

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
});

firebase
  .firestore()
  .enablePersistence({ synchronizeTabs: true })
  .catch(err => {
    console.log(err);
  });
// const admin = firebase.admin();
export const Payments = firestore.collection('payments');
export const Dates = firestore.collection('dates');
export const Meals = firestore.collection('meals');
export const Bags = firestore.collection('bags');
export const Orders = firestore.collection('orders');
export const CollectionCounts = firestore.collection('collection-counts');
export const Promotions = firestore.collection('promotions');
export const Menu = firestore.collection('menu');
export const DriversOrders = firestore.collection('driversOrders');
export const DefaultMeals = firestore.collection('defaultMeals');
export const Users = firestore.collection('users');
export const Ratings = firestore.collection('ratings');
export const CustomItems = firestore.collection('cutomItems');
export const ColdProspects = firestore.collection('coldProspects');
export const Batch = firestore.batch();
export const { FieldValue } = firebase.firestore;

export { auth, database, storage, firestore, firebase };

// CustomItems.get().then((itemsSnap) => {
// 	const items = [];
// 	itemsSnap.forEach((doc) => {
// 		items.push({ ...doc.data(), id: doc.id });
// 	});
// 	localStorage.setItem("customItems", JSON.stringify(items));
// });

// Promise.all([JSON.parse(localStorage.getItem("customItems")).map((item) => CustomItems.doc(item.id).set(item))]);
