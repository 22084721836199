import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
});

const discounted = (price, discount, owedMoney = 0) => {
  return ((price + owedMoney) * (100 - discount)) / 100;
};

const SpanningTable = props => {
  const values = useSelector(({ form }) => form.FirstStepForm.values);
  const packageRed = useSelector(({ packageReducer }) => packageReducer);
  const discount = useSelector(
    ({ couponReducer }) => couponReducer.response.value,
  );
  const currentPlan = useSelector(({ userReducer }) => userReducer.currentPlan);
  const alreadyHasPlan = currentPlan.remainingNumberOfDays > 0;

  const owedMoney =
    currentPlan.remainingNumberOfDays > 0
      ? (currentPlan.priceAfterDiscount / currentPlan.nbOfDays) *
        currentPlan.remainingNumberOfDays
      : 0;
  const { classes } = props;

  return (
    <Paper className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableCell align="left" className="h5TableCell">
            #. Description
          </TableCell>

          <TableCell align="left" className="h5TableCell">
            Days Plan
          </TableCell>

          <TableCell align="left" className="h5TableCell">
            Days Off
          </TableCell>

          <TableCell align="left" className="h5TableCell">
            Price
          </TableCell>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="left" className="h6TableCell">
              {values.dietType}
            </TableCell>
            <TableCell align="left" className="h7TableCell">
              {packageRed.planDays.days}
            </TableCell>
            <TableCell align="left" className="h6TableCell">
              {packageRed.planDays.nbrOfDaysOff}
            </TableCell>
            <TableCell align="left" className="h6TableCell">
              {packageRed.planDays.price.toFixed(2)}
            </TableCell>
          </TableRow>
          {discount ? (
            <TableRow>
              <TableCell align="left" colSpan={3} className="h5TableCell">
                #. Discount
              </TableCell>
              <TableCell align="left" className="h5TableCell">
                {`${discount} %`}
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
          {alreadyHasPlan ? (
            <TableRow>
              <TableCell align="left" colSpan={3} className="h5TableCell">
                #. Money saved from previous plan
              </TableCell>
              <TableCell align="left" className="h5TableCell">
                {`${owedMoney.toFixed(2)} AED`}
              </TableCell>
            </TableRow>
          ) : (
            <></>
          )}
          <TableRow>
            <TableCell align="left" colSpan={3} className="h5TableCell">
              #. Total
            </TableCell>
            <TableCell align="left" className="h5TableCell">
              {discount
                ? discounted(
                    packageRed.planDays.price,
                    discount,
                    owedMoney,
                  ).toFixed(2)
                : (packageRed.planDays.price - owedMoney).toFixed(2)}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Paper>
  );
};

SpanningTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SpanningTable);
