import { COUPON_IS_VALID, COUPON_IS__NOT_VALID } from 'constants/types/Coupon';

const initialState = {
  response: {},
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case COUPON_IS_VALID:
      return { ...state, response: payload };
    case COUPON_IS__NOT_VALID:
      return { ...state, error: payload };
    default:
      return state;
  }
};
