import { NavLink, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SvgLogo from 'components/SvgLogo';
import { signOut } from 'actions/User';

const HeaderLinks = () => {
  const [toggleMenu, setToggleMenu] = useState(true);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(({ userReducer }) => userReducer.loggedIn);
  const history = useHistory();
  const handleToggleMenu = () => {
    setToggleMenu(!toggleMenu);
  };

  const handleClick = () => {
    if (isLoggedIn) dispatch(signOut(history));
    else history.push('/sign-in');
  };

  return (
    <>
      <NavLink
        className="navbar-brand u-header__navbar-brand u-header__navbar-brand-center"
        to="/"
        aria-label="Front"
      >
        <SvgLogo />
      </NavLink>

      <button
        type="button"
        className={`navbar-toggler btn u-hamburger ${toggleMenu &&
          'collapsed'}`}
        aria-label="Toggle navigation"
        aria-expanded={toggleMenu}
        aria-controls="navBarBasic"
        data-toggle="collapse"
        data-target="#navBarBasic"
        onClick={handleToggleMenu}
      >
        <span id="hamburgerTriggerBasic" className="u-hamburger__box">
          <span className="u-hamburger__inner" />
        </span>
      </button>
      <div
        id="navBarBasic"
        className="navbar-collapse u-header__navbar-collapse"
      >
        <ul
          className={`navbar-nav u-header__navbar-nav ul-style-custom ${!toggleMenu &&
            'ul-style-toggle-custom'}`}
        >
          <li className="nav-item u-header__nav-item">
            <NavLink className="nav-link u-header__nav-link" to="/about-us">
              <span>About us</span>
            </NavLink>
          </li>
          <li className="nav-item u-header__nav-item">
            <NavLink className="nav-link u-header__nav-link" to="/terms">
              <span>Terms</span>
            </NavLink>
          </li>
          <li className="nav-item u-header__nav-item">
            <NavLink className="nav-link u-header__nav-link" to="/pricing">
              <span>Pricing</span>
            </NavLink>
          </li>
          {isLoggedIn ? (
            <li className="nav-item u-header__nav-item">
              <NavLink className="nav-link u-header__nav-link" to="/profile">
                <span>Profile</span>
              </NavLink>
            </li>
          ) : (
            <li className="nav-item u-header__nav-item">
              <NavLink className="nav-link u-header__nav-link" to="/sign-up">
                <span>Sign up</span>
              </NavLink>
            </li>
          )}

          <li className="nav-item u-header__nav-link">
            <button
              type="button"
              className="btn btn-sm btn-primary transition-3d-hover"
              onClick={handleClick}
            >
              {isLoggedIn ? 'Sign Out' : 'Sign in'}
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default HeaderLinks;
