import { FECTH_CUSTOM_ITEMS } from 'constants/types/customItems';

const initialState = { customItems: null };

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FECTH_CUSTOM_ITEMS:
      return { ...state, items: payload };

    default:
      return state;
  }
};
