import RaisedView from 'components/RaisedView';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ForgotPasswordForm from 'containers/ForgotPasswordForm';
import { resetPassword } from 'actions/ResetPassword';

const ForgotPasswordPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = values => {
    dispatch(resetPassword(values, history));
  };

  return (
    <div>
      <RaisedView className="main main-raised z-index-4 ">
        <main id="content" role="main">
          <div className="d-flex align-items-center position-relative height-lg-100vh">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-3 space-lg-0">
                  <form className="js-validate mt-5">
                    <div className="mb-7">
                      <h2 className="h3 text-primary font-weight-normal mb-0">
                        <span className="font-weight-semi-bold">
                          {' '}
                          Forgot Password ?
                        </span>
                      </h2>
                      <p>
                        Please enter your email address below and we will send
                        you information to recover your account.
                      </p>
                    </div>
                    <ForgotPasswordForm onSubmit={handleSubmit} />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </main>
      </RaisedView>
    </div>
  );
};

export default ForgotPasswordPage;
