import { Field } from 'redux-form';
import PropTypes from 'prop-types';
import React from 'react';
import InputPhone from 'components/InputPhone';

const InputPhoneField = ({
  label,
  name,
  placeholder,
  type,
  forgotPasswordLink,
  forgotPasswordMessage,
  validate,
  leftIcon,
  rightIcon,
  className,
  value,
  ...props
}) => {
  const isPassword = type === 'password';

  return (
    <Field
      name={name}
      id={name}
      staticValue={value}
      label={label}
      component={InputPhone}
      isPassword={isPassword}
      forgotPasswordLink={forgotPasswordLink}
      placeholder={placeholder}
      forgotPasswordMessage={forgotPasswordMessage}
      type={type}
      aria-label="name"
      className={className}
      leftIcon={leftIcon}
      rightIcon={rightIcon}
      validate={validate}
      {...props}
    />
  );
};

InputPhoneField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  forgotPasswordLink: PropTypes.string,
  forgotPasswordMessage: PropTypes.string,
  validate: PropTypes.array,
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node,
  className: PropTypes.string,
  value: PropTypes.string,
};

export default InputPhoneField;
