import React, { useState } from 'react';
import DaysModal from 'components/DaysModal';
import PropTypes from 'prop-types';

const Package = ({ plan, setNumberOfDays, selectButton }) => {
  const [modalState, setModalState] = useState({
    open: false,
    activeDays: [],
  });

  const handleClick = () => {
    setNumberOfDays(plan.days);
    setModalState({ ...modalState, open: true });
  };

  return (
    <>
      <div
        className="js-slide slick-slide slick-current slick-active pt-md-5 pt-sm-5"
        style={{ height: 'auto' }}
      >
        {/* Pricing */}
        <div className="card transition-3d-hover">
          {/* Header */}
          <header className="card-header text-center p-5">
            <h4 className="h6 text-primary mb-3 mt-4">
              {' '}
              {plan.days}
              Days
            </h4>
            <span className="d-block">
              <span className="display-4 text-dark font-weight-normal">
                <span className="align-top font-size-2">AED</span>
                {plan.price}
              </span>
              <span className="d-block text-secondary font-size-1">
                per month
              </span>
            </span>
          </header>
          {/* End Header */}
          {/* Content */}
          <div className="card-body p-5">
            <button
              type="button"
              onClick={handleClick}
              className={`btn btn-sm btn-block transition-3d-hover ${
                selectButton === 'Selected' ? 'btn-primary' : 'btn-soft-primary'
              }`}
            >
              {selectButton}
            </button>
          </div>
          <div>
            <DaysModal
              setModalState={setModalState}
              modalState={modalState}
              plan={plan}
            />
          </div>
          {/* End Content */}
        </div>

        {/* End Pricing */}
      </div>
    </>
  );
};

Package.propTypes = {
  plan: PropTypes.object,
  setNumberOfDays: PropTypes.func,
  selectButton: PropTypes.string,
};
export default Package;
