/* eslint-disable max-lines */
import { auth } from 'config/firebase';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';
import { toast } from 'react-toastify';

export const resetPassword = (values, history) => async dispatch => {
  dispatch(startLoading());
  try {
    auth.sendPasswordResetEmail(values.email);

    toast('Email has been sent !', {
      className: 'toast-success',
    });
    history.push('/sign-in');
  } catch (error) {
    errorHandler(error);
  }
  dispatch(stopLoading());
};
