export const deliveryTimeOptions = [
  {
    label: 'Morning(6AM to 10AM)',
    value: 'Morning(6AM to 10AM)',
  },
  {
    label: 'Brunch(10AM to 2PM)',
    value: 'Brunch(10AM to 2PM)',
  },
  {
    label: 'Evening(2PM to 10PM)',
    value: 'Evening(2PM to 10PM)',
  },
];
