import { startLoading, stopLoading } from 'actions/Loading';
import { Ratings } from 'config/firebase';
import { errorHandler } from 'helpers/errorHandler';
import { toast } from 'react-toastify';

export const setRating = (rating, mealId) => async (dispatch, getState) => {
  dispatch({ type: startLoading() });

  const {
    userReducer: { user },
  } = getState();

  try {
    await Ratings.doc(mealId).set({ [user.id]: rating });
    toast(`you've just rated this meal with ${rating} stars!`, {
      className: 'toast-info',
    });
    dispatch(stopLoading());
  } catch (error) {
    errorHandler(error);
    dispatch(stopLoading());
  }
  dispatch(stopLoading());
};
