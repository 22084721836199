import { GoogleApiWrapper, Map as GoogleMap, Marker } from 'google-maps-react';
import React, { useState, useEffect } from 'react';
import { startLoading, stopLoading } from 'actions/Loading';
import { GOOGLE_API_KEY } from 'constants/ApiConstants';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

const mapStyles = {
  marginTop: '50px',
  width: '100%',
  height: '300px',
  position: 'relative',
};

const Map = ({ input: { onChange }, google, label, getAddress, name }) => {
  const dispatch = useDispatch();

  const [center, setCenter] = useState({
    lat: 24.451055155839693,
    lng: 54.37848030422947,
  });
  const [marker, setMarker] = useState({
    lat: 24.451055155839693,
    lng: 54.37848030422947,
  });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      setCenter({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
    navigator.geolocation.getCurrentPosition(position => {
      setMarker({
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      });
    });
  }, []);

  const [address, setAddress] = useState('');

  const onDrag = (mapProps, map) => {
    setCenter({
      lat: map.center.lat(),
      lng: map.center.lng(),
    });
  };

  const onClick = (mapProps, map, coord) => {
    const latitude = coord.latLng.lat();
    const longitude = coord.latLng.lng();

    onChange({
      latitude,
      longitude,
    });
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_API_KEY}`;

    setMarker({
      lat: latitude,
      lng: longitude,
    });

    dispatch(startLoading());
    fetch(url)
      .then(response => response.json())
      .then(result => {
        dispatch(stopLoading());
        const formattedAddress = result.results[0].formatted_address;

        setAddress(formattedAddress);
        getAddress(formattedAddress);
      })

      .catch(err => {
        dispatch(stopLoading());
      });
  };

  return (
    <div>
      <label className="form-label" htmlFor={name}>
        <span className="d-flex justify-content-between align-items-center">
          {label}
        </span>
      </label>
      {marker && center && (
        <GoogleMap
          google={google}
          zoom={13}
          mapTypeControl={false}
          center={center}
          streetViewControl={false}
          initialCenter={center}
          onDragend={onDrag}
          onClick={onClick}
          style={mapStyles}
        >
          <Marker position={marker} name={address} />
        </GoogleMap>
      )}
      <div className="h5">
        <p>{address}</p>
      </div>
    </div>
  );
};

Map.propTypes = {
  input: PropTypes.object,
  label: PropTypes.string,
  google: PropTypes.string,
  name: PropTypes.string,
  getAddress: PropTypes.func,
};

export default GoogleApiWrapper({
  apiKey: GOOGLE_API_KEY,
})(Map);
