/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

const InputPhone = ({
  id,
  label,
  isPassword,
  type,
  input,
  forgotPasswordLink,
  rightIcon,
  leftIcon,
  forgotPasswordMessage,
  className,
  staticValue,
  meta: { submitFailed, error, warning },
  ...props
}) => {
  useEffect(() => {
    input.onChange(staticValue);
  }, [staticValue]);
  const [isPasswordShown, toggleShowPassword] = React.useState(false);
  const handletoggleShowPassword = () => {
    toggleShowPassword(!isPasswordShown);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  return (
    <div
      className={`form-group ${className} ${
        submitFailed && (error || warning) ? 'u-has-error' : ''
      }`}
    >
      <label className="form-label" htmlFor={id}>
        <span className="d-flex justify-content-between align-items-center">
          {label}
          {isPassword && forgotPasswordMessage && (
            <Link
              className="link-muted  font-weight-normal"
              to={forgotPasswordLink}
            >
              {forgotPasswordMessage}
            </Link>
          )}
        </span>
      </label>
      <div className="input-group">
        {leftIcon && (
          <div className="input-group-append">
            <span className="input-group-text" id="basic-addon2">
              {leftIcon}
            </span>
          </div>
        )}

        <PhoneInput {...props} {...input} className="form-control" />

        {rightIcon && (
          <div className="input-group-append">
            <span className="input-group-text" id="basic-addon2">
              {rightIcon}
            </span>
          </div>
        )}
        {isPassword && (
          <div className="input-group-append">
            <span className="input-group-text p-0">
              <InputAdornment>
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handletoggleShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {!isPasswordShown ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            </span>
          </div>
        )}
      </div>
      {submitFailed &&
        ((error && (
          <div className="invalid-feedback" style={{ display: 'block' }}>
            {error}
          </div>
        )) ||
          (warning && (
            <div className="invalid-feedback" style={{ display: 'block' }}>
              {warning}
            </div>
          )))}
    </div>
  );
};

InputPhone.propTypes = {
  toggleShowPassword: PropTypes.func,
  props: PropTypes.object,
  isPassword: PropTypes.bool,
  input: PropTypes.object,
  type: PropTypes.string,
  isPasswordShown: PropTypes.bool,
  id: PropTypes.string,
  label: PropTypes.string,
  forgotPasswordMessage: PropTypes.string,
  forgotPasswordLink: PropTypes.string,
  meta: PropTypes.object,
  rightIcon: PropTypes.node,
  leftIcon: PropTypes.node,
  className: PropTypes.string,
  staticValue: PropTypes.string,
};
export default InputPhone;
