import { startLoading, stopLoading } from 'actions/Loading';
import { FETCH_ORDERS } from 'constants/types/Orders';
import { Orders } from 'config/firebase';

export const fetchOrders = () => async (dispatch, getState) => {
  dispatch(startLoading());
  const {
    userReducer: { user },
  } = getState();

  const orders = (await Orders.doc(user.id).get()).data();

  dispatch({ type: FETCH_ORDERS, payload: orders });
  dispatch(stopLoading());
};
