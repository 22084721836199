import React, { useState } from 'react';
import { enjoy, meal, plan } from 'assets/images';
import IconList from 'components/IconList';
import PropTypes from 'prop-types';

const FirstRaisedView = ({ list, head }) => {
  const handleChangeImage = index => () => {
    if (index === 0)
      setPickedImage({
        image: plan,
      });
    if (index === 1)
      setPickedImage({
        image: meal,
      });
    if (index === 2)
      setPickedImage({
        image: enjoy,
      });
  };

  const [pickedImage, setPickedImage] = useState({
    image: plan,
  });
  const { image } = pickedImage;

  return (
    <div className="container space-2 space-lg-3">
      <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-9">
        <span className="d-block text-secondary font-size-1 font-weight-medium text-uppercase mb-2">
          {head && head.conviction}
        </span>
        <h2 className="font-weight-medium text-secondary">
          <div className="ls-2">{head && head.tasteLikeHeaven}</div>
        </h2>
      </div>
      <div className="row">
        <div className="col-lg-5 order-lg-2 mb-7 mb-lg-0">
          <ul className="nav nav-box" role="tablist">
            {list &&
              list.map((l, i) => (
                <IconList
                  key={l.title}
                  list={l}
                  index={i}
                  handleChangeImage={handleChangeImage(i)}
                />
              ))}
          </ul>
        </div>
        <div className="col-lg-7 order-lg-1 align-self-lg-end d-none-xs">
          <div className="tav-content pr-md-4">
            <div className="shadow-lg rounded app-preview ">
              <div className="tab-pane fade active show">
                <img
                  className="img-fluid rounded-lg on-hover-levitate"
                  src={image}
                  alt="Description"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

FirstRaisedView.propTypes = {
  list: PropTypes.array,
  head: PropTypes.shape({
    conviction: PropTypes.string,
    tasteLikeHeaven: PropTypes.string,
  }),
};
export default FirstRaisedView;
