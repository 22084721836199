import PropTypes from 'prop-types';
import React from 'react';
// eslint-disable-next-line sort-imports
import { RctCardHeading } from './RctCardHeading';

const RctCard = ({
  children,
  customClasses,
  heading,
  headingCustomClasses,
  colClasses,
}) => (
  <div className={colClasses && colClasses}>
    <div className={`rct-block ${customClasses || ''}`}>
      {heading && (
        <RctCardHeading title={heading} customClasses={headingCustomClasses} />
      )}
      {children}
    </div>
  </div>
);

RctCard.propTypes = {
  children: PropTypes.node,
  customClasses: PropTypes.string,
  heading: PropTypes.string,
  headingCustomClasses: PropTypes.string,
  colClasses: PropTypes.string,
};
export { RctCard };
