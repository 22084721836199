import { FirstSectionBackground } from 'assets/images';
import { Link } from 'react-router-dom';
import React from 'react';
import homePageStrings from 'constants/text/homePage';
import { useSelector } from 'react-redux';

const HomeFirstSection = () => {
  const isLoggedIn = useSelector(({ userReducer }) => userReducer.loggedIn);
  const currentPlan = useSelector(({ userReducer }) => userReducer.currentPlan);
  const getPackageType = packageType => {
    if (packageType === 'WG') return 'Weight Gainers';
    if (packageType === 'WW') return 'Weight Watchers';

    return 'Weight Lose';
  };

  return (
    <div
      className="gradient-overlay-half-dark-v1 d-md-flex bg-img-hero"
      style={{ backgroundImage: `url(${FirstSectionBackground})` }}
    >
      <div className="justify-content-between container d-md-flex align-items-md-center height-md-100vh position-relative space-top-2 space-bottom-3 space-top-md-5 space-md-0">
        <div className="w-lg-65 mb-9">
          <div className=" mb-5">
            {!isLoggedIn ? (
              <h1 className="display-4 text-white">
                {homePageStrings.welcomeTo}
                <strong className="font-weight-semi-bold">
                  {' '}
                  {homePageStrings.dietlicious}
                </strong>
              </h1>
            ) : (
              <div className="text-white">
                <div className="row">
                  <div className="col-7 h2 font-weight-bold">Your Diet:</div>
                  <div className="col-5 h2  font-weight-bold">
                    {getPackageType(currentPlan.packageType)}
                  </div>
                </div>
                <div className="row">
                  <div className="col-7 h2 font-weight-bold">
                    Remaining Number Of Days:
                  </div>
                  <div className="col-5 h2  font-weight-bold">
                    {`${currentPlan.remainingNumberOfDays} Days`}
                  </div>
                </div>
                <div className="row">
                  <Link to="/packages">
                    <div className="col-7 h2 font-weight-bold">
                      Upgrade your plan
                    </div>
                  </Link>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-2 mb-2 align-self-center " id="dashboard-signup">
          {isLoggedIn ? (
            <Link
              type="submit"
              className="btn btn-block btn-white transition-3d-hover"
              to="/meal-calendar"
            >
              Plan your diet
            </Link>
          ) : (
            <Link
              type="submit"
              className="btn btn-block btn-primary transition-3d-hover"
              to="/sign-up"
            >
              Sign up
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default HomeFirstSection;
