import { IoIosCard, IoIosList, IoMdRestaurant } from 'react-icons/io';
import PropTypes from 'prop-types';
import React from 'react';

const renderIcon = index => {
  let Icon;

  switch (index) {
    case 0:
      Icon = IoIosCard;
      break;
    case 1:
      Icon = IoIosList;
      break;
    case 2:
      Icon = IoMdRestaurant;
      break;
    default:
      break;
  }

  return <Icon size="3rem" color="#080040" />;
};
const IconList = ({ list, index, handleChangeImage }) => {
  return (
    <li className="nav-item w-100 mx-0 mb-3 position-relative">
      <button
        type="button"
        className="btn btn-transparent nav-link p-4"
        onClick={handleChangeImage}
      >
        <div className="media">
          <figure className="ie-height-56 w-100 max-width-8 mt-3 mr-4">
            {renderIcon(index)}
          </figure>
          <div className="media-body">
            <h3 className="h6">{list.title}</h3>
            <p className="mb-0">{list.body}</p>
          </div>
        </div>
      </button>
    </li>
  );
};

IconList.propTypes = {
  list: PropTypes.object,
  index: PropTypes.number,
  handleChangeImage: PropTypes.func,
};
export default IconList;
