import React, { useState } from 'react';
import Modal from 'components/Modal';
import ModalFooter from 'pages/MenuPerDatePage/ModalFooter';
import PropTypes from 'prop-types';
import Rating from '@material-ui/lab/Rating';
import StarIcon from '@material-ui/icons/Star';

const RatingModal = ({ openModal, handleSubmit, cancel, mealId }) => {
  const [rating, setRating] = useState(0);

  return (
    <Modal
      isOpen={openModal}
      onClose
      footer={ModalFooter(handleSubmit(rating, mealId), cancel)}
      title={(
        <>
          <StarIcon />
          Rating
        </>
      )}
    >
      <div className="con-center">
        Please Rate This Meal
        <br />
        <Rating
          name="half-rating"
          onChange={(e, v) => setRating(v)}
          defaultValue={2.5}
          value={rating}
          precision={0.5}
        />
      </div>
    </Modal>
  );
};

RatingModal.propTypes = {
  openModal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  cancel: PropTypes.func,
  mealId: PropTypes.string,
};

export default RatingModal;
