import PropTypes from 'prop-types';
import React from 'react';

const RaisedView = ({ children, className }) => {
  return (
    <div className={className}>
      <div className="container">
        <div>
          <div className="container ">
            <div className="bg-white">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

RaisedView.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};
export default RaisedView;
