import IngredientsForm from 'containers/IngredientsForm';
import Modal from 'components/Modal';
import PropTypes from 'prop-types';
import React from 'react';
import RestaurantIcon from '@material-ui/icons/Restaurant';

const IngredientsModal = ({
  openSelectionModal,
  handleSubmit,
  ingredientOptions,
  supplimentOptions,
  onClose,
  initialValues,
}) => {
  const ingredients = ingredientOptions && ingredientOptions.map(o => o.value);

  return (
    <Modal
      isOpen={openSelectionModal}
      closeModal={onClose}
      title={(
        <>
          <RestaurantIcon />
          Ingredients and supplements
        </>
      )}
    >
      <>
        Please Choose your ingredients and supplements:
        <IngredientsForm
          onSubmit={handleSubmit}
          initialValues={{ ...initialValues, ingredients }}
          ingredientOptions={ingredientOptions}
          supplimentOptions={supplimentOptions}
        />
      </>
    </Modal>
  );
};

IngredientsModal.propTypes = {
  openSelectionModal: PropTypes.bool,
  handleSubmit: PropTypes.func,
  ingredientOptions: PropTypes.array,
  supplimentOptions: PropTypes.array,
  onClose: PropTypes.func,
  initialValues: PropTypes.object,
};

export default IngredientsModal;
