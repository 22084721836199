/* eslint-disable react-hooks/exhaustive-deps */
import 'react-big-calendar/lib/css/react-big-calendar.css';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CreateMeal from 'pages/CreateMeal';
import Header from 'components/Header';
import HomePage from 'pages/HomePage';
import Loader from 'components/Loader';
import MealCalendar from 'pages/MealCalendar';
import MenuPerDatePage from 'pages/MenuPerDatePage';
import Packages from 'pages/Packages';
import RateMeal from 'pages/RateMeal';
import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import { auth } from 'config/firebase';
import { setUser } from 'actions/User';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import EditProfile from 'pages/EditProfile';
import Terms from 'pages/Terms';
import AboutUs from 'pages/AboutUs';
import PricesPage from 'pages/PricesPage';
import CreateCustomBreakfast from 'pages/CreateCustomBreakfast';

const App = () => {
  const dispatch = useDispatch();
  const [verified, setVerified] = useState(false);
  const isLogged = useSelector(({ userReducer }) => userReducer.loggedIn);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  useEffect(() => {
    const unsubsriber = auth.onAuthStateChanged(user => {
      if (user) dispatch(setUser(user));
      else setVerified(true);
    });

    return unsubsriber;
  }, []);
  useEffect(() => {
    if (isLogged) setVerified(true);
  }, [isLogged]);
  if (!verified) return <Loader />;
  const isHeaderVisible = ![
    '/sign-in',
    '/sign-up',
    '/forgot-password',
  ].includes(location.pathname);

  return (
    <div>
      {isHeaderVisible && <Header />}
      <Switch>
        <Route exact path="/packages" component={Packages} />
        <Route exact path="/sign-in" component={SignIn} />
        <Route exact path="/" component={HomePage} />
        <Route exact path="/sign-up" component={SignUp} />
        <Route exact path="/profile" component={EditProfile} />
        <Route exact path="/meal-calendar" component={MealCalendar} />
        <Route exact path="/menu/:date" component={MenuPerDatePage} />
        <Route exact path="/menu-selected/:date" component={MenuPerDatePage} />
        <Route exact path="/forgot-password" component={ForgotPasswordPage} />
        <Route exact path="/menu/:date/create-meal" component={CreateMeal} />
        <Route
          exact
          path="/menu/:date/create-custom-breakfast"
          component={CreateCustomBreakfast}
        />

        <Route exact path="/rate-meal/:date" component={RateMeal} />
        <Route exact path="/terms" component={Terms} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/pricing" component={PricesPage} />
      </Switch>
    </div>
  );
};

export default App;
