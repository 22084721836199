/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import React from 'react';

const SvgLogo = props => {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 396.8 163.3"
      style={{
        enableBackground: 'new 0 0 396.8 163.3',
        ...props.style,
        height: '3.4rem',
      }}
      xmlSpace="preserve"
    >
      <style
        type="text/css"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: '\n\t.st0{fill:#FAAF18;}\n' }}
      />
      <g>
        <path
          className="st0"
          d="M15.6,140.5c2.7-3.1,5.4-6.1,8.1-9.2c0.4,0.3,0.8,0.5,1.1,0.7c3,2.2,6.1,4.4,9.1,6.5c0.4,0.3,0.7,0.7,1.2,0.6
		c0.1-0.1,0.2-0.2,0.2-0.2c0.1-0.4,0.1-0.8,0.2-1.2c0.2-2.4,0.6-4.7,0.7-7.2c-0.4-0.2-0.8-0.3-1.2-0.4c-5.1-1.5-10-3.2-14.8-5.3
		c-2.8-1.3-5.6-2.6-8.1-4.4c-1.2-0.9-2.4-1.8-3.5-2.9c-1.9-1.8-2.5-4-2.3-6.5c0.3-3.5,1.8-6.6,3.8-9.5c0.5-0.7,1.2-1.3,1.9-1.8
		c4.4-3.7,9.5-6.4,14.9-8.2c5-1.7,10.1-2.5,15.3-2.5c0.6,0,1.1,0,1.8,0c0.1-0.4,0.2-0.6,0.3-0.9c0.4-2,0.8-4,1.2-6
		c0.1-0.4,0.2-0.8,0.4-1.2c0.2-0.3,0.5-0.5,0.8-0.7c0.6-0.3,1.2,0,1.3,0.7c0.1,0.6,0.1,1.2,0,1.9c-0.2,1.6-0.5,3.3-0.7,4.9
		c-0.1,0.4-0.1,0.8-0.1,1.2c0.3,0.5,0.8,0.4,1.2,0.4c4.5,0.5,8.8,1.4,13.1,2.9c7.8,2.9,14,7.9,18.7,14.7c3.6,5.3,6.1,11.1,7.6,17.3
		c0.3,1.1,0.4,2.1,0.7,3.2c0.1,0.3,0.2,0.7,0.3,1.2c0.4-0.2,0.8-0.3,1.2-0.5c3.3-1.6,6.7-3.2,10-4.8c0.5-0.2,1-0.5,1.5-0.7
		c0.5,0.4,0.3,0.9,0.3,1.4c0.2,4,0.3,8,0.4,12c0.2,4.5,0.4,8.9,0.5,13.4c0,0.3,0,0.6,0.1,0.9c0.2-0.1,0.3-0.1,0.4-0.1
		c0.7-1.1,1.4-2.3,2.1-3.5c0.2-0.3,0.2-0.8,0.2-1.2c-0.2-6.2,1.7-11.8,5-16.9c1.5-2.3,3.3-4.3,5.5-5.9c1.2-0.9,2.5-1.6,4-2
		c2.1-0.6,4-0.1,5.7,1.3c1.5,1.4,2.7,3,3.5,4.9c0.1,0.3,0.2,0.6,0.3,0.8c1.3,0.2,7.3-0.1,8.7-0.3c0.2-1.5,0-3.1,0-4.6
		c0-1.6,0-3.1,0-4.7c0-1.6,0-3.1,0-4.7c0-1.6,0-3.1,0-4.7c0-1.6,0-3.1,0-4.8c1.2-0.7,2.5-1.5,3.7-2.2c1.3-0.8,2.6-1.5,3.9-2.3
		c1.3-0.7,2.4-1.5,3.9-2.1c0.2,3.4,0.2,6.6,0.3,9.9c0.1,3.3,0.2,6.6,0.2,9.8c0.1,3.3,0.1,6.5,0.2,9.9c0.5,0,0.8,0.1,1.2,0.1
		c2.2-0.1,4.5-0.1,6.7-0.2c0.7,0,1.5,0,2.1,0.4c1,0.5,1.1,1.5,0.4,2.3c-0.4,0.4-0.9,0.7-1.5,0.8c-0.5,0.1-0.9,0.1-1.4,0.1
		c-1.9,0-3.9,0-5.8,0c-0.5,0-0.9,0-1.5,0.1c-0.1,0.4-0.1,0.6-0.1,0.9c0.1,4.9,0.2,9.8,0.3,14.7c0,2,0.1,4.1,0.1,6.1
		c0,0.7,0,1.3,0.1,1.9c0.4,0.4,0.8,0.3,1.1,0.2c0.7-0.3,1.3-0.6,1.9-1.1c0.8-0.6,1.6-1.4,2.3-2.1c4.1-4.4,7.9-9,11.7-13.6
		c0.1-0.2,0.2-0.3,0.4-0.5c1.1-1,1.4-2.3,1.5-3.8c0.5-7.4,2.2-14.5,4.7-21.4c1.9-5.3,4.4-10.5,7.2-15.4c0.3-0.4,0.5-0.9,0.8-1.4
		c-0.6-1.5-1.3-2.9-2-4.6c0.5,0.2,0.7,0.5,0.9,0.7c0.2,0.2,0.4,0.5,0.6,0.7c0.2,0.3,0.5,0.6,0.7,0.8c0.2,0.2,0.4,0.4,0.7,0.7
		c0.2-0.3,0.4-0.6,0.6-0.8c10.7-16.2,24.1-29.8,39.8-41.1c18.5-13.3,38.9-22.8,60.9-28.7c1.5-0.4,3-0.7,4.6-1.1
		c0.2,0.3,0.3,0.6,0.4,0.9c1,4.4,2.1,8.8,3.1,13.2c0,0.1,0,0.3,0,0.5c-0.2,0.4-0.7,0.4-1.1,0.5c-7.1,1.9-14.1,4.1-20.9,6.9
		c-9.2,3.7-18,8-26.3,13.5c-6.6,4.4-12.8,9.3-18.7,14.6c-11.5,10.3-22,21.7-31.4,34c-0.4,0.6-0.9,1.2-1.3,1.7
		c-0.1,0.7,0.3,1.2,0.4,1.7c2.3,6.1,4.2,12.4,5.6,18.8c0.8,3.5,1.3,7,1.6,10.6c0.4,4.6-0.2,9-1.6,13.4c-0.6,1.7-1.2,3.4-2,5.1
		c-0.6,1.2-1.3,2.2-2.3,3.2c-2,2-4.3,2.5-6.9,1.6c-1.5-0.5-2.8-1.4-4-2.3c-2-1.6-3.6-3.4-5.2-5.4c-3.4-4.2-6.2-8.7-8.5-13.6
		c-0.1-0.2-0.2-0.5-0.4-0.7c-0.6,0.1-0.7,0.6-1,1c-2.9,4-5.9,7.8-9.2,11.4c-1.9,2.1-3.9,4-6.2,5.7c-1.2,0.9-2.6,1.8-3.9,2.5
		c-1,0.6-2.2,1-3.4,1.2c-2.4,0.5-4.3-0.3-5.8-2.2c-1.3-1.7-2.1-3.7-2.2-5.9c0-0.4-0.1-0.8-0.1-1.4c-0.6,0.4-1,0.7-1.4,1.1
		c-2.4,1.9-4.9,3.7-7.5,5.3c-2.6,1.5-5.2,2.8-8.2,3.4c-1.4,0.3-2.9,0.5-4.3,0.3c-2-0.2-3.7-1.1-5.1-2.5c-2.2-2.2-3.9-4.7-5-7.6
		c-0.2-0.4-0.3-0.9-0.5-1.3c-0.6,0-0.6,0.4-0.8,0.7c-1.7,3.2-3.4,6.3-5.1,9.5c-0.2,0.5-0.5,0.9-0.8,1.3c-1.7,0.2-8,0.2-9.3,0
		c-0.2-1.6-0.3-3.3-0.4-4.9c-0.1-1.6-0.2-3.2-0.3-4.8c-0.1-1.7-0.2-3.3-0.3-5c-0.1-1.7-0.2-3.3-0.3-5c-0.1-1.6-0.2-3.2-0.3-4.8
		c-0.2-1.6-0.2-3.3-0.4-4.9c-0.5,0-0.5,0.3-0.6,0.5c-1.2,3.3-2.5,6.5-4.1,9.6c-2.7,5.2-6,10-10.4,13.9c-1,0.9-2.1,1.8-3.3,2.7
		c-3.9,2.9-8.2,3.7-12.9,2.8c-2.8-0.6-5.5-1.5-8.2-2.5c-3.9-1.5-7.6-3.3-11.3-5.2c-0.5-0.2-0.9-0.4-1.5-0.7
		c-0.2,0.3-0.3,0.5-0.4,0.8c-0.2,0.6-0.4,1.3-0.6,1.9c-0.3,0.8-0.4,1.7-0.4,2.6c0,0.5,0,1,0,1.6c0,0.9-0.4,1.7-1.3,2.3
		C24.4,158.1,18.5,150.5,15.6,140.5 M38.6,141.6c4.5,2.8,9.1,5.3,13.9,7.5c3,1.4,6.1,2.6,9.4,3.4c2.4,0.6,4.9,1.1,7.4,0.8
		c1.8-0.2,3.3-0.9,4.5-2.3c1.1-1.3,2-2.7,2.5-4.3c1.8-5,2.4-10.2,1.5-15.5c-0.7-4.1-2.3-7.7-4.7-11.1c-1.8-2.5-3.8-4.8-6.1-6.8
		c-5.9-5.2-12.6-8.9-20-11.6c-0.2-0.1-0.4-0.2-0.6-0.2c-1-0.2-1.7,0.2-1.9,1.3c-0.1,0.3-0.1,0.5-0.1,0.8c-0.7,4.4-1.3,8.7-2,13.1
		c-0.1,0.4-0.1,0.8-0.1,1.2c0,0.6,0.3,1,0.9,1.1c0.5,0.1,1,0.2,1.5,0.2c3.1,0.1,6.1,0.3,9.2,0.4c0.9,0,1.8,0.1,2.7,0.1
		c0.2,0,0.5,0.1,0.8,0.2c-1.3,2.5-2.9,4.7-4.9,6.6c-1.3,1.2-2.7,2.1-4.3,2.8c-2.1,0.9-4.3,1.3-6.5,1.5c-0.6,0.1-1.2,0-1.8,0.3
		C39.5,132.8,38.5,140.1,38.6,141.6 M41.8,100.7c-0.4-0.1-0.6-0.2-0.9-0.2c-3.4-0.6-6.9-0.9-10.4-1.1c-2.9-0.2-5.8-0.1-8.7,0.3
		c-1.7,0.2-3.4,0.6-5,1.2c-1.1,0.4-2.1,1-2.9,1.8c-1.8,1.7-2.6,4.4-0.3,6.9c0.2,0.3,0.5,0.6,0.8,0.8c0.8,0.6,1.6,1.2,2.4,1.7
		c1.6,1,3.3,1.7,5.1,2.4c5.1,1.9,10.3,3.2,15.6,4.2c0.3,0.1,0.7,0,1,0.1c0.1-0.2,0.2-0.3,0.2-0.5c1-5.5,2-10.9,3-16.4
		C41.8,101.4,41.8,101.1,41.8,100.7 M121,147.8c0.1,0.2,0.2,0.3,0.3,0.4c2.8,2.1,5.7,3,9.2,1.7c2.5-0.9,4.7-2.1,6.9-3.6
		c0.3-0.2,0.5-0.5,0.7-0.6c0-4.8,0-9.6,0-14.3c0-0.2-0.1-0.5-0.2-0.7c-1.6-0.2-7-0.2-8.2,0c-0.1,0.3-0.2,0.7-0.2,1
		c-0.2,2.8-1.2,5.3-2.6,7.8c-1.4,2.6-3.1,4.9-5.1,7.1C121.7,147.1,121.4,147.4,121,147.8 M180.4,141c0.1,1.3,0.2,2.6,0.3,3.9
		c0.1,0.6,0.3,1.1,0.5,1.6c0.7,1.7,1.9,1.9,3,0.5c0.7-0.9,1.3-1.9,1.7-2.9c1.5-3.2,2.4-6.6,3.1-10.1c1-4.9,0.5-9.7-0.3-14.5
		c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.2-0.5-0.3-0.7-0.1c-0.3,0.3-0.5,0.7-0.7,1c-1.3,2.3-2.5,4.7-3.5,7.1
		C181.8,131.5,180.6,136.1,180.4,141 M122.3,132.9c0-0.5,0-1,0-1.6c-0.1-0.9-0.3-1.8-0.8-2.7c-0.8-1.3-2.2-1.8-3.6-1.2
		c-0.6,0.3-1.2,0.7-1.7,1.1c-1.2,1.2-1.8,2.6-2,4.3c-0.1,1.3-0.1,2.6,0.2,3.9c0.4,2.3,1.1,4.4,1.9,6.6c0.1,0.2,0.2,0.5,0.3,0.7
		c0.5,0.8,1.1,0.9,1.7,0.2c0.1-0.2,0.2-0.3,0.3-0.5c1.2-1.9,2.2-3.8,2.8-5.9C122,136.2,122.3,134.6,122.3,132.9 M32.9,152.5
		c-0.2-0.2-0.4-0.3-0.5-0.4c-3.1-1.9-6.2-3.9-9.2-5.8c-0.1-0.1-0.3-0.2-0.4-0.3c-0.6-0.4-1.3-0.3-1.8,0.3c-0.2,0.3-0.3,0.6-0.1,0.9
		c0.1,0.2,0.3,0.4,0.4,0.6c1.6,2,3.2,4.1,4.9,6.1c0.8,0.9,1.7,1.7,2.6,2.5c0.4,0.4,0.9,0.7,1.4,0.9c0.7,0.3,1.3,0.2,1.8-0.5
		c0.2-0.3,0.3-0.5,0.4-0.8C32.8,154.9,33,153.7,32.9,152.5"
        />
        <path
          className="st0"
          d="M311,142.1c-1.5,0.5-2.9,1.1-4.3,1.5c-1.5,0.4-3,0.5-4.6,0.8c-0.1,0.4-0.4,0.9-0.6,1.4
		c-0.9,2.5-2.2,4.8-3.9,6.9c-1.2,1.5-2.5,2.8-4.1,3.8c-1.5,1-3.1,1.7-4.8,2c-3.2,0.6-6-0.3-8.4-2.3c-1.4-1.1-2.5-2.5-3.2-4.2
		c-0.6-1.2-1-2.4-1.5-3.6c-0.1-0.4-0.3-0.7-0.5-1.3c-0.9,1.4-1.6,2.8-2.3,4.1c-0.7,1.3-1.4,2.6-2.1,3.8c-0.7,1.3-1.4,2.6-2.1,3.9
		c-1.7,0.2-8.1,0.1-9.4,0c-0.4-1.2-0.2-2.4-0.4-3.7c-0.1-1.3-0.2-2.6-0.3-3.9c-0.1-1.2-0.2-2.5-0.3-3.7c-0.1-1.2-0.2-2.5-0.3-3.7
		c-0.5-0.2-0.7,0.2-0.9,0.5c-2.9,2.9-5.8,5.7-8.6,8.6c-1.2,1.3-2.6,2.2-4.1,3c-2.2,1.1-4.5,2.1-7,2.5c-1.2,0.2-2.4,0.2-3.6,0.1
		c-2.4-0.3-4.3-1.5-5.6-3.5c-1-1.5-1.5-3.2-2-4.9c-0.1-0.4-0.2-0.8-0.4-1.4c-0.9,1.3-1.5,2.6-2.2,3.7c-0.7,1.3-1.4,2.6-2.1,3.8
		c-0.7,1.2-1.3,2.5-2,3.6c-1.3,0.2-7.7,0.2-9.3,0.1c-0.3-0.4-0.3-1-0.3-1.5c-0.2-3.7-0.5-7.5-0.7-11.2c-0.4-5.2-0.7-10.4-1.1-15.6
		c-0.1-1.7-0.2-3.3-0.3-5.1c1.4-0.9,2.9-1.5,4.4-2.2c1.5-0.7,3-1.4,4.5-2.2c1.5-0.7,3-1.5,4.6-2.2c0.1,0.5,0.2,0.9,0.2,1.3
		c0.1,2.7,0.2,5.4,0.3,8.1c0.2,5.5,0.4,10.9,0.6,16.4c0,0.6,0.1,1.2,0.2,2.1c0.3-0.4,0.5-0.5,0.6-0.7c0.7-1.2,1.4-2.3,2.1-3.5
		c0.1-1.3,0.1-2.6,0.2-3.9c0.3-4,0.6-8.1,1.8-12c0.5-1.6,1.1-3.1,1.9-4.6c0.6-1,1.3-2,2.2-2.8c2.1-2,4.6-2.7,7.5-2.4
		c3.2,0.4,5.9,1.8,8.6,3.6c-0.4,1.7-1.1,3.3-1.6,4.9c-0.5,1.6-1.1,3.1-1.7,4.8c-0.3-0.2-0.6-0.3-0.8-0.5c-1.8-1.7-3.6-3.4-5.5-5.1
		c-0.3-0.3-0.6-0.6-1-0.8c-0.2-0.2-0.7,0-0.8,0.2c-0.1,0.2-0.2,0.5-0.3,0.7c-0.7,3.1-1.2,6.2-1,9.4c0.1,1.6,0.4,3.1,0.9,4.6
		c1,2.7,2.9,4.5,5.6,5.4c3.1,1,6,0.8,9-0.4c2.9-1.3,5.2-3.2,7.3-5.6c-0.3-4.6-0.6-9.2-0.9-13.8c1.5-0.9,3-1.5,4.5-2.2
		c1.5-0.7,3-1.4,4.5-2.2c1.5-0.7,3-1.5,4.6-2.1c0.1,0.3,0.1,0.6,0.1,0.8c0.1,3.4,0.3,6.8,0.4,10.1c0.2,5.1,0.4,10.3,0.6,15.4
		c0,0.4,0,0.8,0.1,1.2c0.4,0.2,0.5-0.1,0.6-0.3c0.6-1,1.2-2,1.8-2.9c0.4-0.7,0.6-1.4,0.6-2.2c0-3.2,0.5-6.3,1.6-9.3
		c1-3.1,2.5-5.9,4.6-8.4c1.1-1.4,2.4-2.6,3.9-3.5c1.8-1.2,3.7-1.9,5.8-2c2.4-0.2,4.5,0.5,6.5,1.9c1.6,1.1,2.8,2.6,3.8,4.3
		c1.5,2.7,2.3,5.7,2.5,8.8c0.2,2.3,0.1,4.6-0.3,6.8c-0.1,0.4-0.1,0.9-0.2,1.4c2.3,0.4,5.1-0.8,7.8-3.5c-0.1-1.9-0.1-3.8-0.2-5.7
		c-0.1-1.9-0.1-3.8-0.2-5.8c0.3-0.2,0.5-0.4,0.8-0.5c3.8-1.6,7.7-3.2,11.5-4.8c0.3-0.1,0.6-0.2,0.9-0.3c0.1,0.3,0.1,0.5,0.1,0.7
		c0.1,2,0.2,3.9,0.3,5.9c0.2,3.9,0.4,7.8,0.7,11.7c0.1,1.3,0.2,2.6,0.8,3.9c0.2-0.1,0.5-0.1,0.6-0.3c0.4-0.7,0.8-1.3,1.1-2.1
		c1-2.7,1.9-5.5,2.7-8.3c0.7-2.3,1.3-4.7,1.9-7.1c0.1-0.2,0.1-0.4,0.2-0.7c2-0.8,4.2-1.2,6.2-1.8c2-0.6,4.1-1.1,6.2-1.7
		c0.5,2.4,0.4,4.8,0.7,7.2c0.2,2.4,0.4,4.8,0.6,7.1c0.2,2.3,0.3,4.7,0.5,7c0.2,2.4,0.4,4.7,0.6,7c0.4,0.3,0.6,0.2,0.8,0
		c0.2-0.2,0.4-0.4,0.5-0.6c3-3.6,5.9-7.2,8.4-11c0.6-1,1.2-1.9,1.8-2.9c1-1.8,1-2.9,0.2-4.8c2.1-4.8,4.1-9.7,6.3-14.6
		c0.4,0.2,0.8,0.3,1.1,0.4c1.9,0.9,3.7,2,5.4,3.3c4.3,3.3,6.4,7.6,6.6,13c0.1,4.1-0.5,8.2-1.5,12.2c-0.2,0.6-0.3,1.2-0.4,1.8
		c0,0.1,0.1,0.2,0.1,0.3c0.6,0,0.9-0.5,1.2-0.9c2.7-2.5,5.2-5.2,7.6-8.1c0.8-0.9,1.5-1.9,2.2-2.8c0.3-0.4,0.7-0.7,1.3-0.8
		c1.1-0.1,1.8,0.5,1.9,1.6c0,0.2,0,0.4-0.1,0.6c-0.1,0.2-0.3,0.4-0.5,0.6c-4,5-8.2,9.7-12.8,14c-1.9,1.8-3.9,3.4-6.1,4.9
		c-0.6,0.4-1.1,0.7-1.7,1.1c-1.5,0.9-3.1,1-4.6,0.4c-0.9-0.3-1.8-0.8-2.6-1.4c-1.8-1.2-3.3-2.8-4.6-4.5c0.8-2.6,2.9-7.8,3.5-8.8
		c0.3,0.4,0.6,0.7,0.9,1c1.3,1.4,2.5,2.9,3.9,4.3c0.8,0.8,1.6,1.4,2.5,2.1c0.5,0.3,1,0.5,1.6,0.7c0.7,0.2,1.1-0.2,1.2-0.8
		c0.1-1.6,0.3-3.2,0.2-4.8c0-1.2-0.2-2.4-0.4-3.6c-0.4-2.3-1.5-4.2-3.1-5.8c-1.2-1.2-2.6-2.3-4.2-3c-0.4,0.5-0.7,1-1.1,1.4
		c-2.7,3.8-5.4,7.5-8.1,11.3c-2.4,3.3-5,6.5-7.7,9.6c-2.4,2.7-5.4,3.7-9,3.3c-1.3-0.1-2.4-0.8-3.4-1.8c-0.8-0.8-1.4-1.8-1.9-2.9
		c-0.8-1.8-1.3-3.6-1.5-5.5c-0.2-1.3-0.3-2.7-0.4-4c0-0.4,0.1-0.8-0.3-1.3c-0.2,0.2-0.4,0.3-0.5,0.5c-0.4,0.7-0.9,1.4-1.3,2.1
		c-0.9,1.6-1.8,3.2-2.8,4.7c-0.8,1.2-1.6,2.3-2.5,3.4c-0.7,0.8-1.5,1.6-2.4,2.3c-0.6,0.4-1.2,0.8-1.9,1c-2,0.7-3.5,0.2-4.6-1.6
		c-0.5-0.9-0.9-1.9-1.3-2.8c-0.8-2.4-1.2-4.8-1.6-7.3C311.3,144.1,311.1,143.1,311,142.1 M284.2,131.1c0.1,0.5,0.1,1,0.2,1.5
		c1.1,4.6,3.7,8.1,7.7,10.6c0.5,0.3,1.1,0.6,1.7,0.8c0.7,0.2,1.4,0.3,2.1,0.3c1.4,0,2.3-0.8,2.8-2.1c0.4-1.1,0.4-2.2,0.1-3.2
		c-1.1-4.8-3.8-8.3-8-10.9c-0.4-0.2-0.8-0.4-1.3-0.6c-0.5-0.2-1-0.3-1.5-0.3c-1.9-0.2-3.2,0.8-3.6,2.7
		C284.3,130.3,284.3,130.7,284.2,131.1"
        />
        <path
          className="st0"
          d="M342.6,17.3c-0.2,0.8-0.8,1.3-1.4,1.8c-0.9,0.7-1.9,1-3,1.3c-4.1,0.8-8.2,1.6-12.2,2.4
		c-3.1,0.6-6.1,1.2-9.2,1.8c-0.5,0.1-0.9,0.2-1.3,0.4c-1.4,0.7-2.2,2.4-1.8,4c0.4,1.5,1.7,2.6,3.3,2.6c0.5,0,0.9-0.1,1.4-0.2
		c6.8-1.4,13.7-2.7,20.5-4.1c0.3-0.1,0.6-0.1,0.9-0.1c0.4,0,0.8,0.5,0.6,0.9c-0.2,0.3-0.4,0.6-0.7,0.9c-0.8,0.9-1.8,1.6-2.8,2.2
		c-2.4,1.5-5,2.5-7.7,3.4c-4.1,1.3-8.3,2.2-12.6,2.9c-2.8,0.5-5.7,0.7-8.5,0.6c-1.8-0.1-3.5-0.4-5.2-0.9c-2.3-0.8-4.1-2.2-5.3-4.3
		c-0.8-1.4-1.4-2.8-1.7-4.3c-0.3-1.5-0.6-3.1-0.8-4.6c-0.2-1.6-0.1-3.2,0.2-4.8c0.4-1.7,1.2-3.2,2.5-4.4c1.3-1.3,2.8-2.3,4.5-3.1
		c2.5-1.3,5.1-2.1,7.8-2.8c3.6-0.9,7.3-1.6,11-2.1c3.6-0.4,7.1-0.7,10.7-0.1c1.1,0.2,2.2,0.4,3.3,0.9c0.2,0.1,0.5,0.2,0.7,0.3
		c0.4,0.2,0.9,0.4,0.8,1c-0.1,0.5-0.6,0.6-1,0.6c-1.5,0.3-3.1,0.6-4.6,0.9c-5.4,1.1-10.8,2.1-16.2,3.2c-0.3,0.1-0.6,0.1-0.9,0.2
		c-1.8,0.6-2.8,2.3-2.4,4.1c0.4,1.8,2.2,2.9,4,2.6c2.6-0.5,5.2-1,7.8-1.5c4.7-0.9,9.4-1.9,14.1-2.8c1.2-0.3,2.5-0.2,3.7,0.2
		C341.7,16.4,341.9,16.6,342.6,17.3"
        />
        <path
          className="st0"
          d="M114.4,49.7c0.9-0.2,1.8,0.1,2.5,0.3c9.5,1.7,18.5,4.6,27.2,8.9c6.7,3.4,12.8,7.8,18.4,12.8
		c4.7,4.2,9.2,8.8,13.3,13.6c0.2,0.3,0.4,0.6,0.7,0.9c0,0,0,0.1,0,0.2c-0.4,0.1-0.5-0.2-0.7-0.4c-0.2-0.1-0.4-0.3-0.6-0.5
		c-3.6-3-7.4-5.8-11.4-8.4c-2.2-1.4-4.5-2.8-6.8-4.2c-7.1-4.2-14.8-7.1-22.8-9c-3.8-0.9-7.6-1.7-11.5-2.2c-0.8-0.1-1.4-0.4-1.9-1.2
		c-1.9-3.2-4-6.3-5.9-9.5C114.7,50.4,114.6,50.1,114.4,49.7"
        />
        <path
          className="st0"
          d="M182.1,74.6c-6.2,0-11.2-5-11.2-11.3c0-6.2,5-11.3,11.2-11.3c5.7,0,11.2,4.4,11.2,11.3
		C193.3,70.5,187.5,74.7,182.1,74.6"
        />
        <path
          className="st0"
          d="M112.3,53.6c-0.5,0.5-1.1,0.5-1.7,0.7c-2.1,0.6-4.2,0.8-6.3,0.9c-3.7,0.2-7.4,0.2-11.1-0.3
		c-1.1-0.1-2.2-0.3-3.2-0.5c-0.3,0-0.6-0.1-1.3-0.2c0.6,0.4,0.9,0.6,1.2,0.8c2.3,1.1,4.7,1.8,7.2,2.3c0.8,0.2,1.6,0.3,2.5,0.4
		c0.4,0.1,0.8,0.1,1.4,0.2c-0.5,0.7-1.1,0.9-1.6,1.3c-0.6,0.5-1.2,0.9-1.7,1.3c-0.5,0.4-1,0.9-1.5,1.3c-0.5,0.5-1,0.9-1.4,1.4
		c-0.9,0-1.6-0.4-2.3-0.7c-2.3-1-4.3-2.5-6.2-4.1c-2.2-1.9-4.2-3.9-6.1-6.2c0.2-0.2,0.4-0.5,0.7-0.6c3.9-2.6,8.1-4.7,12.7-5.7
		c3.4-0.7,6.8-0.8,10.1,0.4c3.6,1.3,6.3,3.7,8.5,6.8C112.2,53.3,112.2,53.4,112.3,53.6"
        />
        <path
          className="st0"
          d="M213.9,96.4c4.5,0,9,3.4,9,9c0,5.2-4.1,9-9,9c-5.6,0-8.9-4.6-9-8.9C204.9,100.5,208.9,96.5,213.9,96.4"
        />
        <path
          className="st0"
          d="M95.2,116.7c-5-0.1-8.9-4.2-8.9-9.1c0-4.3,3.4-8.8,9-8.8c5.1,0,9,3.9,9,9.1C104.3,113.5,99.6,116.8,95.2,116.7
		"
        />
        <path
          className="st0"
          d="M264.3,113.3c-4.7,0-9-3.8-9-9.1c0-4.7,3.6-8.9,9-8.9c5.3,0,9.1,4.2,9,9C273.3,109.6,268.9,113.3,264.3,113.3"
        />
        <path
          className="st0"
          d="M112.5,56.8c0.1,0.6-0.1,0.9-0.4,1.2c-0.8,0.9-1.6,1.8-2.5,2.6c-2.4,2.1-5.1,3.8-7.9,5.3
		c-0.3,0.1-0.5,0.3-1,0.6c0.5,0,0.7,0.1,0.9,0c1.9-0.3,3.7-1,5.4-1.9c2.3-1.2,4.2-2.8,6.1-4.6c0.3-0.3,0.7-0.6,1.1-1
		c0.1,0.2,0.2,0.3,0.2,0.5c-0.3,2.6-1.2,4.9-2.8,7c-2,2.7-4.8,4.2-8.1,4.8c-2.9,0.5-5.7,0.3-8.5-0.2c-0.8-0.1-1.6-0.4-2.5-0.5
		c0.1-0.3,0-0.5,0.1-0.7c2.2-4.6,5.3-8.4,9.7-11.1c2.8-1.7,5.8-2.4,9.1-2.3C111.9,56.7,112.2,56.8,112.5,56.8"
        />
      </g>
    </svg>
  );
};

SvgLogo.propTypes = {
  style: PropTypes.string,
};
export default SvgLogo;
