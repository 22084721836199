import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom';
import PauseIcon from '@material-ui/icons/Pause';
import PropTypes from 'prop-types';
import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import RestaurantIcon from '@material-ui/icons/Restaurant';
import StarIcon from '@material-ui/icons/Star';
import TimerIcon from '@material-ui/icons/Timer';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import HomeIcon from '@material-ui/icons/Home';

const Event = ({ event: { date, state } }) => {
  let Icon;

  switch (state) {
    case 'welcome':
      Icon = () => (
        <div style={{ width: '100%' }} className="text-center">
          <HomeIcon style={{ fontSize: '35px', color: '#fbaf02' }} />
          <div className="h6 text-center text-primary">Welcome</div>
        </div>
      );

      break;
    case 'choose':
      Icon = () => (
        <Link
          style={{ width: '100%' }}
          className="text-center"
          to={`/menu/${date}`}
        >
          <TouchAppIcon style={{ fontSize: '35px', color: '#080040' }} />
          <div className="h6 text-center text-secondary">Choose</div>
        </Link>
      );

      break;
    case 'prepare':
      Icon = () => (
        <div style={{ width: '100%' }} className="text-center">
          <TimerIcon style={{ fontSize: '35px', color: '#080040' }} />
          <div className="h6 text-center text-secondary">Prepare</div>
        </div>
      );
      break;

    case 'paused':
      Icon = () => (
        <Link
          style={{ width: '100%' }}
          className="text-center"
          to={`/menu/${date}`}
        >
          <PauseIcon style={{ fontSize: '35px', color: '#fbaf02' }} />
          <div className="h6 text-center text-warning">Paused</div>
        </Link>
      );
      break;

    case 'off':
      Icon = () => (
        <div style={{ width: '100%' }} className="text-center">
          <CloseIcon style={{ fontSize: '35px', color: '#ff4545' }} />
          <div className="h6 text-center text-danger">Off</div>
        </div>
      );
      break;
    case 'selected':
      Icon = () => (
        <Link
          style={{ width: '100%' }}
          className="text-center"
          to={`/menu-selected/${date}`}
        >
          <RestaurantIcon style={{ fontSize: '35px', color: '#28a745' }} />
          <div className="h6 text-center text-success">Selected</div>
        </Link>
      );
      break;

    case 'rate':
      Icon = () => (
        <Link
          style={{ width: '100%' }}
          className="text-center"
          to={`/rate-meal/${date}`}
        >
          <StarIcon style={{ fontSize: '35px', color: '#fbaf02' }} />
          <div className="h6 text-center text-warning">Rate</div>
        </Link>
      );
      break;
    case 'renew':
      Icon = () => (
        // <Link to="/packages" style={{ width: '100%' }} className="text-center">
        //   <RefreshIcon style={{ fontSize: '35px', color: '#080040' }} />
        //   <div className="h6 text-center text-secondary">Renew</div>
        // </Link>
        <div className="h6 text-center text-secondary">Renew</div>
      );
      break;
    default:
      Icon = () => (
        <div style={{ width: '100%' }} className="text-center">
          <TouchAppIcon
            className="text-center"
            style={{ fontSize: '35px', color: '#080040' }}
          />
          <div className="h6 text-center text-secondary">Choose</div>
        </div>
      );
      break;
  }

  return (
    <>
      <Icon />
    </>
  );
};

Event.propTypes = {
  event: PropTypes.shape({
    date: PropTypes.string,
    state: PropTypes.string,
  }),
};
export default Event;
