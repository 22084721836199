/* eslint-disable react/jsx-indent */
/* eslint-disable max-lines */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Collapsible from 'components/Collapsible';
import CreateMealCard from 'components/CreateMealCard';
import CustomMealCard from 'components/CustomMealCard';
import Header from 'pages/MenuPerDatePage/Header';
import IngredientsModal from 'pages/MenuPerDatePage/IngredientsModal';
import MealCard from 'components/MealCard';
import formatIngredientsOptions from 'helpers/formatIngredientsOptions';
import formatSupplementsOptions from 'helpers/formatSupplementsOptions';
import { useMenuPerDate } from 'hooks/useMenuPerDate';
import { useRouteMatch } from 'react-router-dom';

const MenuPerDatePage = () => {
  const {
    params: { date },
  } = useRouteMatch();

  const {
    currentPlan,
    selectedMeals,
    mealsToHide,
    selectedSnacks,
    customMeals,
    customBreakfasts,
    removeMeal,
    selectSnack,
    removeSnack,
    selectMeal,
    removeJuice,
    selectJuice,
    selectedJuices,
  } = useMenuPerDate(date);
  const [openSelectionModal, setOpenSelectionModal] = useState(false);
  const [supplimentOptions, setSupplimentOptions] = useState();
  const [ingredientOptions, setIngredientOptions] = useState();
  const [selectedMeal, setSelectedMeal] = useState();

  const handleMealSelection = meal => () => {
    setSupplimentOptions(formatSupplementsOptions(meal.suppliment));
    setIngredientOptions(formatIngredientsOptions(meal.defaultIngredients));
    setSelectedMeal(meal);
    setOpenSelectionModal(true);
  };
  const onClose = () => {
    setOpenSelectionModal(false);
  };

  const extraCharges =
    selectedSnacks.reduce((a, b) => a + (isNaN(b.price) ? 0 : b.price), 0) +
    selectedMeals.reduce((a, b) => {
      return a + (isNaN(b.totalPrice) ? 0 : b.totalPrice);
    }, 0) +
    selectedJuices.length * 10;
  const handleSubmit = values => {
    setOpenSelectionModal(false);
    let _total = 0;

    supplimentOptions &&
      supplimentOptions.map(o => {
        _total = +(
          ((values.supplements && values.supplements[o.value.name]) || 0) *
            o.value.price +
          _total
        ).toFixed(2);

        return false;
      });

    const newMeal = {
      ...selectedMeal,
      supplements: values.supplements || null,
      ingredients: values.ingredients || null,
      price: _total,
    };

    selectMeal(newMeal)();
  };

  console.log({ customBreakfasts, selectedMeals });

  return (
    <div className="container space-top-3">
      <Header date={date} />
      <h3 className="h3 d-flex justify-content-between">
        <div>
          Breakfasts:
          {` ${selectedMeals.length}/${currentPlan.nbMealsPerDay}`}
        </div>
        <div>
          Extra charges:
          {` ${JSON.stringify(extraCharges)} AED`}
        </div>
      </h3>
      <div className="container">
        <Collapsible title="Breakfasts">
          <div className="card-sm-columns card-sm-2-count card-lg-3-count">
            {selectedMeals.length < currentPlan.nbMealsPerDay && (
              <CreateMealCard type="breakfast" />
            )}
            {customBreakfasts && customBreakfasts.length > 0
              ? customBreakfasts.map(customMeal => (
                  <CustomMealCard
                    customMeal={customMeal}
                    onClick={removeMeal(customMeal)}
                    text="Remove Meal"
                  />
                ))
              : selectedMeals
                  .filter(i => i.type === 'CUSTOM_BREAKFAST')
                  .map(cus => (
                    <CustomMealCard
                      customMeal={cus}
                      onClick={removeMeal(cus)}
                      text="Remove Meal"
                    />
                  ))}
          </div>
          <div className="card-sm-columns card-sm-2-count card-lg-3-count">
            {mealsToHide
              .filter(m => m.type === 'BREAKFAST')
              .map(meal => (
                <MealCard
                  isMenuCard={
                    selectedMeals.filter(
                      oneSelectedMeal => oneSelectedMeal.id === meal.id,
                    ).length > 0
                      ? 'Remove Meal'
                      : 'Choose Meal'
                  }
                  onClick={
                    selectedMeals.filter(
                      oneSelectedMeal => oneSelectedMeal.id === meal.id,
                    ).length > 0
                      ? removeMeal(meal)
                      : handleMealSelection(meal)
                  }
                  meal={meal}
                  key={meal.id}
                />
              ))}
          </div>
        </Collapsible>
      </div>
      <h3 className="h3 d-flex justify-content-between">
        <div>
          Meals:
          {` ${selectedMeals.length}/${currentPlan.nbMealsPerDay}`}
        </div>
        <div>
          Extra charges:
          {` ${JSON.stringify(extraCharges)} AED`}
        </div>
      </h3>
      <div className="container">
        <Collapsible title="Meals">
          <div className="card-sm-columns card-sm-2-count card-lg-3-count">
            {selectedMeals.length < currentPlan.nbMealsPerDay && (
              <CreateMealCard />
            )}
            {customMeals && customMeals.length > 0
              ? customMeals.map(customMeal => (
                  <CustomMealCard
                    customMeal={customMeal}
                    onClick={removeMeal(customMeal)}
                    text="Remove Meal"
                  />
                ))
              : selectedMeals
                  .filter(i => i.type === 'CUSTOM_BREAKFAST')
                  .map(cus => (
                    <CustomMealCard
                      customMeal={cus}
                      onClick={removeMeal(cus)}
                      text="Remove Meal"
                    />
                  ))}
          </div>
          <div className="card-sm-columns card-sm-2-count card-lg-3-count">
            {mealsToHide
              .filter(m => m.type === 'MEAL')
              .map(meal => (
                <MealCard
                  isMenuCard={
                    selectedMeals.filter(
                      oneSelectedMeal => oneSelectedMeal.id === meal.id,
                    ).length > 0
                      ? 'Remove Meal'
                      : 'Choose Meal'
                  }
                  onClick={
                    selectedMeals.filter(
                      oneSelectedMeal => oneSelectedMeal.id === meal.id,
                    ).length > 0
                      ? removeMeal(meal)
                      : handleMealSelection(meal)
                  }
                  meal={meal}
                  key={meal.id}
                />
              ))}
          </div>
        </Collapsible>
      </div>
      <h3 className="h3">
        Snacks:
        {`${selectedSnacks.length}/${currentPlan.nbSnacksPerDay}`}
      </h3>
      <div className="container">
        <Collapsible title="Snacks">
          <div className="card-sm-columns card-sm-2-count card-lg-3-count">
            {mealsToHide
              .filter(m => m.type === 'SNACK')
              .map(meal => (
                <MealCard
                  isMenuCard={
                    selectedSnacks.filter(
                      selectedSnack => selectedSnack.id === meal.id,
                    ).length > 0
                      ? 'Remove Snack'
                      : 'Choose Snack'
                  }
                  onClick={
                    selectedSnacks.filter(
                      selectedSnack => selectedSnack.id === meal.id,
                    ).length > 0
                      ? removeSnack(meal)
                      : selectSnack(meal)
                  }
                  meal={meal}
                  key={meal.id}
                />
              ))}
          </div>
        </Collapsible>
      </div>

      <div className="container">
        <h3 className="h3 d-flex justify-content-between">
          <div>
            Juices:
            {` ${selectedJuices.length}`}
          </div>
          <div>
            Extra charges:
            {` ${JSON.stringify(selectedJuices.length * 10)} AED`}
          </div>
        </h3>
        <Collapsible title="Juices">
          <div className="card-sm-columns card-sm-2-count card-lg-3-count">
            {mealsToHide
              .filter(m => m.type === 'JUICE')
              .map(meal => (
                <MealCard
                  isMenuCard={
                    selectedJuices.filter(i => i.id === meal.id).length > 0
                      ? 'Remove Juice'
                      : 'Choose Juice'
                  }
                  onClick={
                    selectedJuices.filter(
                      selectedSnack => selectedSnack.id === meal.id,
                    ).length > 0
                      ? removeJuice(meal)
                      : selectJuice(meal)
                  }
                  meal={meal}
                  key={meal.id}
                />
              ))}
          </div>
        </Collapsible>
      </div>
      <IngredientsModal
        openSelectionModal={openSelectionModal}
        handleSubmit={handleSubmit}
        onClose={onClose}
        ingredientOptions={ingredientOptions}
        supplimentOptions={supplimentOptions}
      />
    </div>
  );
};

export default MenuPerDatePage;
