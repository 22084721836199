import React, { useEffect, useState } from 'react';
import HeaderLinks from 'components/Header/HeaderLinks';

const Header = () => {
  const [state, setState] = useState({
    isScrolled: false,
    isFixedNavBar: false,
  });

  const renderNavbar = () => {
    switch (state.isScrolled) {
      case true:
        switch (state.isFixedNavBar) {
          case true:
            return 'u-header u-header--floating-md u-scrolled js-header-fix-moment';

          case false:
            return 'u-header u-header--floating-md u-scrolled ';

          default:
            return '';
        }

      case false:
        return 'u-header u-header--floating-md';
      default:
        return 'u-header u-header--floating-md';
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0 && state.isScrolled === false) {
        setState({ ...state, isScrolled: true });
      }
      if (window.scrollY === 0 && state.isScrolled === true) {
        setState({ ...state, isScrolled: false });
      }
      if (window.scrollY > 30 && state.isFixedNavBar === false) {
        setState({ ...state, isFixedNavBar: true });
      }
      if (window.scrollY < 30 && state.isFixedNavBar === true) {
        setState({ ...state, isFixedNavBar: false });
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [state]);

  return (
    <header className={renderNavbar()}>
      <div className="container">
        <div className="u-header__section u-header--floating__inner u-header__section.custom">
          <nav className="js-mega-menu navbar navbar-expand-md u-header__navbar u-header__navbar--no-space ">
            <HeaderLinks />
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
