import {
  SELECTED_DAYS,
  SELECTED_PLAN,
  SELECTED_START_DATE,
} from 'constants/types/Plan';

const initialState = {
  daysOff: [],
  planDays: {},
  startedAt: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SELECTED_DAYS:
      return { ...state, daysOff: payload };
    case SELECTED_PLAN:
      return { ...state, planDays: payload };
    case SELECTED_START_DATE:
      return { ...state, startedAt: payload };
    default:
      return state;
  }
};
