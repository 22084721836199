/* eslint-disable react-hooks/exhaustive-deps */
import { Calendar, momentLocalizer } from 'react-big-calendar';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Event from 'components/CalendarSides/Event';
import Toolbars from 'components/CalendarSides/Toolbar';
import { fetchOrders } from 'actions/Orders';
import { formatEvents } from 'helpers/formatEvents';
import moment from 'moment';

const localizer = momentLocalizer(moment);
const MealCalendar = () => {
  const components = { event: Event, toolbar: Toolbars };

  const currentPlan = useSelector(({ userReducer }) => userReducer.currentPlan);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchOrders());
  }, []);

  const userOrders = useSelector(({ orders }) => orders.userOrders);

  const events = formatEvents(currentPlan, userOrders);

  return (
    <div className="container space-3">
      <Calendar
        localizer={localizer}
        events={events}
        selectable
        components={components}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        toolbar
      />
    </div>
  );
};

export default MealCalendar;
