import { applyMiddleware, compose, createStore } from 'redux';
import createReducer from 'reducers';

import thunk from 'redux-thunk';

const middlewares = [thunk];

const initialState = {};

const enhancers = [applyMiddleware(...middlewares)];

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const store = createStore(
  createReducer,
  initialState,
  composeEnhancers(...enhancers),
);

export default store;
