import PropTypes from 'prop-types';
import React from 'react';

const RctCardHeading = ({ title, customClasses }) => (
  <div className={`rct-block-title ${customClasses || ''}`}>
    <h4>{title}</h4>
  </div>
);

// type checking props
RctCardHeading.propTypes = {
  title: PropTypes.string,
  customClasses: PropTypes.string,
};

export { RctCardHeading };
