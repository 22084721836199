import PropTypes from 'prop-types';
import React from 'react';
import { SELECTED_DAYS, SELECTED_PLAN } from 'constants/types/Plan';
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Modal from 'components/Modal';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

const DaysModal = ({ modalState, setModalState, plan }) => {
  const dispatch = useDispatch();

  const selectedPlan = () => {
    dispatch({ type: SELECTED_PLAN, payload: plan });
  };
  const handleChange = idx => () => {
    const { activeDays } = modalState;

    if (!modalState.activeDays.includes(idx)) {
      if (activeDays.length >= 2) return false;
      activeDays.push(idx);
      setModalState({
        ...modalState,
        activeDays,
      });
    } else {
      const _activeDays = activeDays.filter(o => o !== idx);

      setModalState({
        ...modalState,
        activeDays: _activeDays,
      });
    }

    return false;
  };

  const handleOkClose = () => {
    selectedPlan(plan);
    setModalState({ ...modalState, open: false });
    dispatch({ type: SELECTED_DAYS, payload: modalState.activeDays });
  };

  const handleCancelClose = () => {
    setModalState({ ...modalState, open: false });
  };
  const modalFooter = () => (
    <>
      <div className="app-selectbox">
        <button
          type="button"
          onClick={handleCancelClose}
          className="btn btn-sm btn-danger transition-3d-hover"
          style={{
            backgroundColor: '#080040',
            boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
            borderColor: '#080040',
          }}
        >
          Cancel
        </button>
      </div>
      <div className="app-selectbox">
        <button
          type="button"
          onClick={handleOkClose}
          className="btn btn-sm btn-primary transition-3d-hover"
        >
          Ok
        </button>
      </div>
    </>
  );

  return (
    <Modal
      closeModal={handleCancelClose}
      footer={modalFooter()}
      isOpen={modalState.open}
    >
      <div className="hs-docs-heading h4 pb-10">
        Choose your days off
        <span className="text-danger">*</span>
      </div>
      <div className="pb-10" data-toggle="buttons">
        {[
          'Sunday',
          'Monday',
          'Tuesday',
          'Wednesday',
          'Thursday',
          'Friday',
          'Saturday',
        ].map((day, idx) => (
          <div
            className={`btn btn-custom-toggle-primary${
              modalState.activeDays.includes(idx) ? ' active' : ''
            }`}
            key={day}
            onClick={handleChange(idx)}
          >
            {day}
          </div>
        ))}
      </div>
    </Modal>
  );
};

DaysModal.propTypes = {
  modalState: PropTypes.object,
  plan: PropTypes.object,
  setModalState: PropTypes.func,
};

export default withStyles(styles)(DaysModal);
