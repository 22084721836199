import { FETCH_ONE_MENU, HIDE_MEAL } from 'constants/types/Menu';

const initialState = { allMenus: [], selectedMenu: null };

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ONE_MENU:
      return { ...state, selectedMenu: payload };
    case HIDE_MEAL:
      return {
        ...state,
        selectedMenu: {
          ...state.selectedMenu,
          hiddenMeals: [...state.selectedMenu.hiddenMeals, payload],
        },
      };

    default:
      return state;
  }
};
