import {
  ADD_CURRENT_PLAN,
  FETCH_CURRENT_PLAN,
  LOGGED_IN,
  LOGGED_OUT,
  USER_SIGNIN_FAILURE,
  USER_SIGNIN_SUCCESS,
  USER_SIGNUP_FAILURE,
  USER_SIGNUP_SUCCESS,
} from 'constants/types/Auth';

const initialState = {
  currentPlan: {
    packageType: null,
    nbMealsPerDay: 0,
    nbSnacksPerDay: 0,
    nbOfDays: 0,
    remainingNumberOfDays: 0,
    priceAfterDiscount: 0,
    daysOff: [],
    price: 0,
    createdAt: null,
    startedAt: null,
    isActive: false,
    pausedDays: [],
    promotionId: '',
  },
  pausedDays: [],
  daysOff: [],
  response: [],
  loggedIn: false,
  error: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case USER_SIGNIN_SUCCESS:
      return { ...state, response: payload };
    case USER_SIGNIN_FAILURE:
      return { ...state, error: payload };
    case USER_SIGNUP_SUCCESS:
      return { ...state, response: payload };
    case USER_SIGNUP_FAILURE:
      return { ...state, error: payload };
    case LOGGED_IN: {
      const { plan, ...user } = payload;

      return { ...state, loggedIn: true, user, plan };
    }
    case LOGGED_OUT:
      return { ...state, loggedIn: false };
    case ADD_CURRENT_PLAN:
      return { ...state, currentPlan: payload };
    case FETCH_CURRENT_PLAN:
      return { ...state, ...payload };
    default:
      return state;
  }
};
