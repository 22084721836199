import { Link, useRouteMatch } from 'react-router-dom';
import React from 'react';

const CreateMealCard = ({ type }) => {
  const {
    params: { date },
  } = useRouteMatch();

  return (
    <Link
      to={`/menu/${date}/${
        type === 'breakfast' ? 'create-custom-breakfast' : 'create-meal'
      }`}
    >
      <div className="custom-meal">
        <div className="card card-frame border-dashed mb-3">
          <div
            className="img-fluid "
            style={{
              minHeight: '250px',
              backgroundSize: 'cover',
            }}
          />
          <div
            className="card-body"
            style={{
              minHeight: '230px',
            }}
          >
            <div className="card-title text-center text-muted ">+</div>
            <div className="card-text text-muted text-center">
              {` Add your ${type === 'breakfast' ? 'breakfast' : 'meal'}`}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

CreateMealCard.propTypes = {};

export default CreateMealCard;
