import React from 'react';
import SignUpStrings from 'constants/text/signUp';
import { updateUser } from 'actions/User';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import EditProfileForm from 'containers/EditProfileForm';
import { ZONES } from 'constants/zones';

const EditProfile = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSubmit = values => {
    dispatch(updateUser(values, history));
  };
  const user = useSelector(({ userReducer }) => userReducer.user);
  const region = user.zone;
  const AbuDhabi = ZONES[0].value;

  let zone = '';

  if (AbuDhabi.indexOf(region) !== -1) {
    zone = ZONES[0].value;
  } else {
    zone = ZONES[1].value;
  }
  const initial = { ...user, zone, region };

  return (
    <div>
      <div className="main main-raised" style={{ marginTop: '50px' }}>
        <div className="container space-2">
          <div className=" w-md-75 w-lg-50 mx-md-auto ">
            <h1 className="h3 text-primary font-weight-normal mb-0">
              {SignUpStrings.welcomeTo}
              <span className="font-weight-semi-bold">
                {SignUpStrings.dietlicious}
              </span>
            </h1>
            <p>{SignUpStrings.FillOutTheFormToGetStarted}</p>
          </div>
          <div className="d-flex align-items-center position-relative ">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-3 space-lg-0">
                  <EditProfileForm
                    onSubmit={handleSubmit}
                    initialValues={initial}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
