import { Field, reduxForm } from 'redux-form';
import {
  carbs,
  proteins,
  quantity as quantities,
  sides,
} from 'constants/customMeal';
import PropTypes from 'prop-types';
import React from 'react';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import { strings } from 'constants/strings';
import { useCreateMeal } from 'hooks/useCreateMeal';
import AsyncButton from 'components/AsyncButton';

const CreateMealForm = ({ handleSubmit, change }) => {
  const {
    carbsOptions,
    proteinsOptions,
    sidesOptions,
    updateCarbsOptions,
    updateProteinsOptions,
    updateSidesOptions,
    updateSubCarbs,
    updateSubProteins,
    updateSubSides,
    updateCarbsQuantity,
    updateProteinsQuantity,
    updateSidesQuantity,
    carbsPrice,
    protPrice,
    sidePrice,
  } = useCreateMeal(change);

  return (
    <form className="js-validate mt-5" onSubmit={handleSubmit}>
      <SelectField
        name="carbohydrtes"
        label="Carbohydrtes"
        options={carbs}
        validate={[Validators.required]}
        getSelectedValue={updateCarbsOptions}
      />
      {carbsOptions && carbsOptions.length > 0 && (
        <>
          <SelectField
            name="carbsType"
            options={carbsOptions}
            validate={[Validators.required]}
            getSelectedValue={updateSubCarbs}
          />

          <SelectField
            name="carbsQuantity"
            options={quantities}
            getSelectedValue={updateCarbsQuantity}
          />
        </>
      )}
      <SelectField
        name="proteins"
        label="Proteins"
        options={proteins}
        validate={[Validators.required]}
        getSelectedValue={updateProteinsOptions}
      />
      {proteinsOptions && proteinsOptions.length > 0 && (
        <>
          <SelectField
            name="proteinsType"
            options={proteinsOptions}
            validate={[Validators.required]}
            getSelectedValue={updateSubProteins}
          />

          <SelectField
            name="proteinsQuantity"
            options={quantities}
            getSelectedValue={updateProteinsQuantity}
          />
        </>
      )}
      <SelectField
        name="sides"
        label="Sides"
        options={sides}
        validate={[Validators.required]}
        getSelectedValue={updateSidesOptions}
      />
      {sidesOptions && sidesOptions.length > 0 && (
        <>
          <SelectField
            name="sidesType"
            options={sidesOptions}
            validate={[Validators.required]}
            getSelectedValue={updateSubSides}
          />

          <SelectField
            name="sidesQuantity"
            options={quantities}
            getSelectedValue={updateSidesQuantity}
          />
        </>
      )}
      <Field component="input" readOnly name="price" className="d-none" />
      {`${strings.en.price} : `}

      {` ${(carbsPrice || 0) + (protPrice || 0) + (sidePrice || 0)} ${
        strings.en.currency
      }`}

      <AsyncButton
        className="btn btn-sm btn-primary transition-3d-hover mt-3 col-3"
        type="submit"
        text="Create"
      />
    </form>
  );
};

CreateMealForm.propTypes = {
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
};
export default reduxForm({ form: 'CreateMealForm' })(CreateMealForm);
