import React from 'react';
import SvgLogo from 'components/SvgLogo';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer>
      {/* Lists */}
      <div className="border-top mt-5">
        <div className="container space-2">
          <div className="row justify-content-md-between">
            <div className="col-sm-8 col-lg-4 mb-4 mb-lg-0">
              {/* List Group */}
              <h4 className="h6 font-weight-semi-bold d-flex">
                {' '}
                <PhoneIcon />
                600550001
              </h4>
              <h4 className="h6 font-weight-semi-bold mt-5 d-flex">
                {' '}
                <MailIcon />
                info.dietlicious@gmail.com
              </h4>
              <h4 className="h6 font-weight-semi-bold mt-5 d-flex">
                {' '}
                <LocationOnIcon />
                Office N4, M Floor- near to ADDC DMS office Building name Saif
                bin Darwish, Building no:94, Dalma st, Al Nahyan, Abu Dhabi
              </h4>
              {/* End List Group */}
            </div>

            <div className="col-sm-8 col-lg-4 mb-4 mb-lg-0">
              <h4 className="h6 font-weight-semi-bold">Resources</h4>
              {/* List Group */}
              <ul className="list-group list-group-flush list-group-borderless mb-0">
                <li>
                  <Link
                    className="list-group-item list-group-item-action"
                    to="/terms"
                  >
                    Terms and conditions
                  </Link>
                </li>
                <li>
                  <Link
                    className="list-group-item list-group-item-action"
                    to="/about-us"
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    className="list-group-item list-group-item-action"
                    to="/pricing"
                  >
                    Pricing
                  </Link>
                </li>
              </ul>
              {/* End List Group */}
            </div>
            <div className="col-md-6 col-lg-4">
              {/* Button */}
              <button
                type="button"
                className="btn btn-xs btn-dark btn-wide transition-3d-hover text-left mb-2 mr-1"
              >
                <span className="media align-items-center">
                  <span className="fab fa-apple fa-2x mr-3" />
                  <span className="media-body">
                    <span className="d-block">Download on the</span>
                    <strong className="font-size-1">App Store</strong>
                  </span>
                </span>
              </button>
              {/* End Button */}
              {/* Button */}
              <button
                type="button"
                className="btn btn-xs btn-dark btn-wide transition-3d-hover text-left mb-2"
              >
                <span className="media align-items-center">
                  <span className="fab fa-google-play fa-2x mr-3" />
                  <span className="media-body">
                    <span className="d-block">Get it on</span>
                    <strong className="font-size-1">Google Play</strong>
                  </span>
                </span>
              </button>
              {/* End Button */}

              <img
                alt="Credit Card Logos"
                title="Credit Card Logos"
                src="http://www.credit-card-logos.com/images/visa_credit-card-logos/visa_mastercard_3.gif"
                width="166"
                height="50"
                border="0"
              />
            </div>
          </div>
        </div>
      </div>
      {/* End Lists */}
      {/* Copyright */}
      <div className="container text-center space-1">
        {/* Logo */}
        <a
          className="d-inline-flex align-items-center mb-2"
          href="/"
          aria-label="Dietlicious"
        >
          <SvgLogo />
        </a>
        {/* End Logo */}
        <p className="small text-muted">© Dietlicious. All rights reserved.</p>
      </div>
      {/* End Copyright */}
    </footer>
  );
};

export default Footer;
