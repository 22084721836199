/* eslint-disable no-nested-ternary */
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { RctCard } from 'components/RctCard';
import React from 'react';
import SpanningTable from 'components/SpanningTable';
import { payInfo } from 'actions/PayAction';

const Invoice = ({ activeStep, handleBack }) => {
  const dispatch = useDispatch();
  const packageRed = useSelector(({ packageReducer }) => packageReducer);
  const currentPlan = useSelector(({ userReducer }) => userReducer.currentPlan);

  const amount = packageRed.planDays.price;
  const owedMoney =
    currentPlan.remainingNumberOfDays > 0
      ? (currentPlan.priceAfterDiscount / currentPlan.nbOfDays) *
        currentPlan.remainingNumberOfDays
      : 0;

  const rest = useSelector(({ form }) => form.FirstStepForm.values);
  const daysOffPlanNumberOfDays = useSelector(
    ({ packageReducer }) => packageReducer,
  );
  const coupon = useSelector(({ couponReducer }) => couponReducer.response);

  const payment = {
    rest,
    daysOffPlanNumberOfDays: {
      ...daysOffPlanNumberOfDays,
      planDays: {
        ...daysOffPlanNumberOfDays.planDays,
        price: daysOffPlanNumberOfDays.planDays.price - owedMoney,
      },
    },
    coupon,
  };

  const handleSubmit = () => {
    dispatch(payInfo(payment));
  };

  return (
    <div className="invoice-wrapper">
      <h4>Order Summary</h4>
      <div className="row">
        <div className="col-sm-12 col-md-12 col-xl-10 mx-auto">
          <RctCard>
            <div className="invoice-head text-right">
              <SpanningTable />
            </div>
          </RctCard>
        </div>
      </div>
      <button
        disabled={activeStep === 0}
        onClick={handleBack}
        type="button"
        className="btn btn-secondary"
      >
        Back
      </button>
      <button
        variant="contained"
        color="primary"
        disabled={amount - owedMoney < 0}
        onClick={handleSubmit}
        type="submit"
        className="btn btn-primary"
      >
        {activeStep === 4
          ? amount - owedMoney > 0
            ? 'Finish'
            : 'You need to upgrade to a Higher plan'
          : 'Next'}
      </button>
    </div>
  );
};

Invoice.propTypes = {
  activeStep: PropTypes.number,
  handleBack: PropTypes.func,
};
export default Invoice;
