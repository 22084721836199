import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useRouteMatch } from 'react-router-dom';
import CreateMealForm from 'containers/CreateMealForm';
import React from 'react';
import SignUpStrings from 'constants/text/signUp';
import { setCustomMeals } from 'actions/Meal';

const CreateMeal = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    params: { date },
  } = useRouteMatch();

  const customMeals = useSelector(({ meals }) => meals.customMeals[date]);

  const handleSubmit = values => {
    const customMeal = {
      id: customMeals ? customMeals.length : 0,
      type: 'CUSTOM_MEAL',
      totalPrice: values.price,
      carbs: {
        price: values.carbsPrice,
        quantity: `${values.carbsQuantity}g`,
        value: values.carbsType.type,
      },
      protein: {
        price: values.protPrice,
        quantity: `${values.proteinsQuantity}g`,
        value: values.proteinsType.type,
      },
      sides: {
        price: values.sidePrice,
        quantity: `${values.sidesQuantity}g`,
        value: values.sidesType.type,
      },
    };

    dispatch(setCustomMeals(customMeal, true, history, date));
  };

  return (
    <div>
      <div className="main main-raised" style={{ marginTop: '50px' }}>
        <div className="container space-2">
          <div className=" w-md-75 w-lg-50 mx-md-auto ">
            <h1 className="h3 text-primary font-weight-normal mb-0">
              {SignUpStrings.welcomeTo}
              <span className="font-weight-semi-bold">Create your meal</span>
            </h1>
          </div>
          <div className="d-flex align-items-center position-relative ">
            <div className="container">
              <div className="row no-gutters">
                <div className="col-md-8 col-lg-7 col-xl-6 offset-md-2 offset-lg-2 offset-xl-3 space-3 space-lg-0">
                  <CreateMealForm onSubmit={handleSubmit} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateMeal;
