import InputField from 'components/InputField';
import PropTypes from 'prop-types';
import React from 'react';
import { reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';

const initialValues = {
  discount: '',
};
const ThirdStep = ({ activeStep, handleBack, handleCheck, handleSubmit }) => {
  const promoCode = useSelector(({ form }) => form.ThirdStep.values.discount);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <InputField
          label="Discount Coupon"
          name="discount"
          placeholder="Enter Your Discount Coupon"
          className=""
        />
        <button
          disabled={activeStep === 0}
          type="button"
          className="btn btn-primary"
          onClick={handleCheck(promoCode)}
        >
          Confirm Coupon
        </button>
      </div>
      <button
        disabled={activeStep === 0}
        onClick={handleBack}
        type="button"
        className="btn btn-secondary"
      >
        Back
      </button>
      <button
        variant="contained"
        color="primary"
        type="submit"
        className="btn btn-primary"
      >
        {activeStep === 3 ? 'Finish' : 'Next'}
      </button>
    </form>
  );
};

ThirdStep.propTypes = {
  activeStep: PropTypes.number,
  handleBack: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCheck: PropTypes.func,
};

export default reduxForm({
  form: 'ThirdStep',
  destroyOnUnmount: false,
  enableReinitialize: true,
  initialValues,
})(ThirdStep);
