import FirstStepForm from 'containers/FirstStepForm';
import PropTypes from 'prop-types';
import React from 'react';

const FirstStep = ({ activeStep, handleBack, onSubmit }) => {
  return (
    <FirstStepForm
      activeStep={activeStep}
      handleBack={handleBack}
      onSubmit={onSubmit}
      initialValues={{
        dietType: 'WL',
        nbrOfMeals: 1,
        nbrOfSnacks: '0',
      }}
    />
  );
};

FirstStep.propTypes = {
  activeStep: PropTypes.number,
  handleBack: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default FirstStep;
