import ErrorIcon from '@material-ui/icons/Error';
import React from 'react';

const ModalTitle = () => {
  return (
    <>
      <ErrorIcon />
      Are you sure ?
    </>
  );
};

export default ModalTitle;
