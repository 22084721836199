import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import {
  backgroundParallax,
  weightGain,
  weightLose,
  weightWatchers,
} from 'assets/images';
import HomeImageCard from 'components/SecondRaisedView/homeImageCard';
import React from 'react';
import homePageStrings from 'constants/text/homePage';

const SecondRaisedView = () => {
  return (
    <>
      <div className="container space-bottom-md-3 space-bottom-2">
        <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-9 space-top-3">
          <h2 className="font-weight-medium text-secondary">
            <div className="ls-2">
              {homePageStrings.whatMakesDietlicousBodacious}
            </div>
          </h2>
        </div>
        <div
          style={{ width: '100%' }}
          data-options='{direction: "normal"}'
          className="landing-section-two"
        >
          <ParallaxProvider>
            <Parallax className="custom-class" y={[23, -23]}>
              <div
                className="divimage gradient-overlay-half-dark-v2 dzsparallaxer--target"
                style={{
                  height: '130%',
                  backgroundImage: `url(${backgroundParallax})`,
                  transform: 'translate3d(0px, -41.3856px, 0px)',
                }}
              />
              <div className="container space-3 z-index-50">
                <div className="text-center w-lg-80 mx-auto">
                  <h1 className="text-white display-1 font-size-md-down-5 font-weight-semi-bold">
                    {homePageStrings.ourLiciousWays}
                  </h1>
                </div>
                <div className="text-center w-lg-40 mx-auto">
                  <p className="lead text-white">
                    {homePageStrings.parallaxBody}
                  </p>
                </div>
              </div>
            </Parallax>
          </ParallaxProvider>
        </div>
        <div className="w-md-80 w-lg-50 text-center mx-md-auto mb-9 space-top-3 space-bottom-1">
          <h2 className="font-weight-medium text-secondary">
            <div className="ls-2">{homePageStrings.ourMealPlans}</div>
          </h2>
        </div>
        <HomeImageCard
          title={homePageStrings.weightLose}
          body={homePageStrings.weightLoseBody}
          image={weightLose}
        />
        <HomeImageCard
          title={homePageStrings.weightGain}
          body={homePageStrings.weightGainBody}
          image={weightGain}
          left
        />
        <HomeImageCard
          title={homePageStrings.weightWatchers}
          body={homePageStrings.weightWatchersBody}
          image={weightWatchers}
        />
      </div>
    </>
  );
};

export default SecondRaisedView;
