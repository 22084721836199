import { startLoading, stopLoading } from 'actions/Loading';
import { PAYMENT_INFO } from 'constants/types/Payment';
import { api } from 'api';
import { errorHandler } from 'helpers/errorHandler';

// export const orderPayement = (userId, newOrder) => async (
//   dispatch,
//   getState,
// ) => {
//   if (!newOrder) return;
//   dispatch({ type: START_LOADING });

//   const { price, promotion } = newOrder;

//   const paymentObject = {
//     items: {
//       [newOrder.date]: {
//         meals: newOrder.meals,
//         snacks: newOrder.snacks,
//         breakfasts: newOrder.breakfasts,
//         juices: newOrder.juices,
//       },
//     },
//     promotionId: promotion?.promotionId,
//     amount: price,
//     currency: 'AED',
//     description: 'New Order',
//     userId,
//   };
//   try {
//     const res = await api.post('/paidOrder', {
//       isMobile: true,
//       paymentObject,
//     });
//     // fetchMeals()(dispatch, getState, null);
//     // ADD A NEW ORDER SIDE THE CALENDAR
//     addNewOrder(newOrder)(dispatch, getState, null);
//     NavigationService.navigate('Verification');
//     Linking.openURL(res.data.order.url);
//     NavigationService.navigate('Calendar');
//   } catch (error) {
//     showToast(error.message);
//   }
//   dispatch({ type: STOP_LOADING });
// };

export const payInfo = values => async (dispatch, useState) => {
  const state = useState();

  dispatch(startLoading());

  const priceAfterDiscount = values.coupon.promoId
    ? values.daysOffPlanNumberOfDays.planDays.price *
      ((100 - values.coupon.value) / 100)
    : values.daysOffPlanNumberOfDays.planDays.price;
  const paymentObject = {
    promotionId: values.coupon.id,
    amount: values.daysOffPlanNumberOfDays.planDays.price,
    currency: 'AED',
    description: 'NEW PLAN',
    userId: state.userReducer.user.id,
    items: {
      createdAt: new Date(),
      startedAt: values.daysOffPlanNumberOfDays.startedAt,
      daysOff: values.daysOffPlanNumberOfDays.daysOff,
      isActive: false,
      nbMealsPerDay: values.rest.nbrOfMeals,
      pausedDays: [],
      nbOfDays: values.daysOffPlanNumberOfDays.planDays.days,
      nbSnacksPerDay: values.rest.nbrOfSnacks,
      packageType: values.rest.dietType,
      price: values.daysOffPlanNumberOfDays.planDays.price,
      priceAfterDiscount,
      promotionId: values.coupon.promoId,
      remainingNumberOfDays: values.daysOffPlanNumberOfDays.planDays.days,
    },
  };

  try {
    const res = await api.post('/payments', { paymentObject });

    window.location.replace(res.data.order.url);
  } catch (error) {
    errorHandler(error);
  }
  dispatch({
    type: PAYMENT_INFO,
    payload: values,
  });
  dispatch(stopLoading());
};
