/* eslint-disable import/no-duplicates */
import React, { useEffect } from 'react';

import { Field, reduxForm } from 'redux-form';
import {
  breakFastProteins,
  breakFastcarbs,
  breakFastVegetables,
  eggsPieceNumber,
  turkeyPieceNumber,
  breadPcsNumber,
  quantity as quantities,
} from 'constants/customMeal';
import PropTypes from 'prop-types';
import SelectField from 'components/SelectField';
import Validators from 'helpers/formValidator';
import { strings } from 'constants/strings';
import AsyncButton from 'components/AsyncButton';
import { useCreateCustomBreakfast } from 'hooks/useCreateCustomBreakfast';

const CreateCustomBreakfastForm = ({ handleSubmit, change }) => {
  const {
    carbsOptions,
    proteinsOptions,
    sidesOptions,
    updateCarbsOptions,
    updateProteinsOptions,
    updateSidesOptions,
    updateSubCarbs,
    updateSubProteins,
    updateSubSides,
    updateCarbsQuantity,
    updateProteinsQuantity,
    updateSidesQuantity,
    carbsPrice,
    protPrice,
    sidePrice,
  } = useCreateCustomBreakfast(change);

  //   useEffect(() => {
  //     const unsub = CustomItems.onSnapshot(snapshot =>
  //       dispatch(fetchCustomItems(snapshot)),
  //     );

  //     return () => unsub();
  //   }, []);

  const getProteinSubSection = () => {
    switch (proteinsOptions[0].label) {
      case 'White eggs':
        return eggsPieceNumber;
      case 'Turkey(Lite)':
        return turkeyPieceNumber;
      case 'Beef':
        return quantities;
      default:
        return quantities;
    }
  };

  const getCarbsSubSection = () => {
    switch (carbsOptions[0].label) {
      case 'Slite Bread':
        return breadPcsNumber;
      default:
        return quantities;
    }
  };

  return (
    <form className="js-validate mt-5" onSubmit={handleSubmit}>
      <SelectField
        name="carbohydrtes"
        label="Carbohydrtes"
        options={breakFastcarbs}
        validate={[Validators.required]}
        getSelectedValue={updateCarbsOptions}
      />
      {carbsOptions && carbsOptions.length > 0 && (
        <>
          <SelectField
            name="carbsType"
            options={carbsOptions}
            validate={[Validators.required]}
            getSelectedValue={updateSubCarbs}
          />

          <SelectField
            name="carbsQuantity"
            options={getCarbsSubSection()}
            getSelectedValue={updateCarbsQuantity}
          />
        </>
      )}
      <SelectField
        name="proteins"
        label="Proteins"
        options={breakFastProteins}
        validate={[Validators.required]}
        getSelectedValue={updateProteinsOptions}
      />
      {proteinsOptions && proteinsOptions.length > 0 && (
        <>
          <SelectField
            name="proteinsType"
            options={proteinsOptions}
            validate={[Validators.required]}
            getSelectedValue={updateSubProteins}
          />

          <SelectField
            name="proteinsQuantity"
            options={getProteinSubSection()}
            getSelectedValue={updateProteinsQuantity}
          />
        </>
      )}
      <SelectField
        name="vegetable"
        label="Vegetable"
        options={breakFastVegetables}
        validate={[Validators.required]}
        getSelectedValue={updateSidesOptions}
      />
      {sidesOptions && sidesOptions.length > 0 && (
        <>
          <SelectField
            name="sidesType"
            options={sidesOptions}
            validate={[Validators.required]}
            getSelectedValue={updateSubSides}
          />

          <SelectField
            name="sidesQuantity"
            options={quantities}
            getSelectedValue={updateSidesQuantity}
          />
        </>
      )}
      <Field component="input" readOnly name="price" className="d-none" />
      {`${strings.en.price} : `}

      {` ${(carbsPrice || 0) + (protPrice || 0) + (sidePrice || 0)} ${
        strings.en.currency
      }`}

      <AsyncButton
        className="btn btn-sm btn-primary transition-3d-hover mt-3 col-3"
        type="submit"
        text="Create"
      />
    </form>
  );
};

CreateCustomBreakfastForm.propTypes = {
  handleSubmit: PropTypes.func,
  change: PropTypes.func,
};
export default reduxForm({ form: 'CreateCustomBreakfastForm' })(
  CreateCustomBreakfastForm,
);
