import React, { useState } from 'react';
import Package from 'components/Package';
import PropTypes from 'prop-types';
import prices from 'constants/prices';
import { useSelector } from 'react-redux';

const SecondStep = ({ activeStep, handleBack, onSubmit }) => {
  const dietType = useSelector(
    ({ form }) => form.FirstStepForm.values.dietType,
  );
  const nbrOfSnacks = useSelector(
    ({ form }) => form.FirstStepForm.values.nbrOfSnacks,
  );
  const packageRed = useSelector(({ packageReducer }) => packageReducer);

  const nbrOfMeals = useSelector(
    ({ form }) => form.FirstStepForm.values.nbrOfMeals,
  );
  const [, setNumberOfDays] = useState({ nbr: 0, selected: false });

  return (
    <div className="shop-grid mb-5">
      <div className="row">
        {[
          {
            days: 5,
            nbrOfDaysOff: 2,
            price: prices[dietType][nbrOfSnacks][nbrOfMeals]['5'],
          },
          {
            days: 20,
            nbrOfDaysOff: 2,
            price: prices[dietType][nbrOfSnacks][nbrOfMeals]['20'],
          },
          {
            days: 26,
            nbrOfDaysOff: 1,
            price: prices[dietType][nbrOfSnacks][nbrOfMeals]['26'],
          },
          {
            days: 30,
            nbrOfDaysOff: 0,
            price: prices[dietType][nbrOfSnacks][nbrOfMeals]['30'],
          },
        ].map(el => (
          <div className="col-sm-12 col-md-6 col-xl-4" key={el.days}>
            <div className="app-selectbox">
              <Package
                selectButton={
                  el.days === packageRed?.planDays.days
                    ? 'Selected'
                    : 'Select this'
                }
                plan={el}
                setNumberOfDays={setNumberOfDays}
              />
            </div>
          </div>
        ))}
      </div>
      <button
        disabled={activeStep === 0}
        onClick={handleBack}
        type="button"
        className="btn btn-secondary"
      >
        Back
      </button>
      <button
        variant="contained"
        color="primary"
        disabled={!packageRed.planDays}
        onClick={onSubmit}
        type="submit"
        className="btn btn-primary"
      >
        Next
      </button>
    </div>
  );
};

SecondStep.propTypes = {
  activeStep: PropTypes.number,
  handleBack: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default SecondStep;
