const formatSupplementsOptions = arr => {
  if (!arr) return [];
  const options = arr.map(el => ({
    label: `${el.en} ${el.price}AED`,
    value: { name: el.en, price: el.price },
  }));

  return options;
};

export default formatSupplementsOptions;
