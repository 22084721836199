import { COUPON_IS_VALID, COUPON_IS__NOT_VALID } from 'constants/types/Coupon';
import { errorHandler } from 'helpers/errorHandler';
import { Promotions as promotionCollection } from 'config/firebase';
import moment from 'moment';
import { toast } from 'react-toastify';

export const CheckCoupon = discountCode => async dispatch => {
  try {
    const promos = (
      await promotionCollection.where('promoId', '==', discountCode).get()
    ).docs;

    if (!promos.length) throw new Error('Cannot find Promotional code');

    if (!promos[0].data().isValid) throw new Error('Invalid Promotional code');
    if (moment.unix(promos[0].data().exp.seconds).isBefore(moment()))
      throw new Error('Promotional code expired');

    const promotion = {
      ...promos[0].data(),
      id: promos[0].ref.path.split('/')[1],
    };

    toast(`Coupon Valid with a ${promotion.value}% discount`, {
      className: 'toast-success',
    });
    dispatch({ type: COUPON_IS_VALID, payload: promotion });
  } catch (error) {
    dispatch({ type: COUPON_IS__NOT_VALID, payload: error });
    errorHandler(error);
  }
};
