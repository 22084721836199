import { FETCH_ORDERS } from 'constants/types/Orders';

const initialState = { userOrders: null };

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_ORDERS:
      return { ...state, userOrders: payload };

    default:
      return state;
  }
};
