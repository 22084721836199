import { FETCH_ONE_MENU, HIDE_MEAL } from 'constants/types/Menu';
import { FieldValue, Menu } from 'config/firebase';
import { startLoading, stopLoading } from 'actions/Loading';
import { errorHandler } from 'helpers/errorHandler';

export const fetchOneMenu = id => async dispatch => {
  dispatch(startLoading());

  const menu = (await Menu.doc(id).get()).data();

  dispatch({ type: FETCH_ONE_MENU, payload: menu });
  dispatch(stopLoading());
};

export const removeMealFromMenu = (date, mealId) => async dispatch => {
  dispatch(startLoading());
  try {
    await Menu.doc(date).update({ hiddenMeals: FieldValue.arrayUnion(mealId) });
    dispatch({ type: HIDE_MEAL, payload: mealId });
  } catch (error) {
    try {
      await Menu.doc(date).set({ hiddenMeals: [mealId] });
      await fetchOneMenu(date)(dispatch);
      dispatch({ type: HIDE_MEAL, payload: mealId });
    } catch (newError) {
      errorHandler(newError);
      dispatch(stopLoading());
    }
    dispatch(stopLoading());
  }

  dispatch(stopLoading());
};
